<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de unidad de medida y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="unidad_medidas" :items-per-page="15" :search="buscar"
        dense class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">UNIDAD DE MEDIDA</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <!-- Impresion unidad medida -->
            <v-btn fab x-small color="info" @click="crearPDF()" class="mr-1">
              <v-icon>print</v-icon>
            </v-btn>
            <!-- Fin Impresion unidad medida -->
            <v-spacer />
            <!-- Formulario busqueda de unidad medida -->
            <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" outlined dense
              label="Búsqueda" background-color="blue lighten-5" single-line hide-details></v-text-field>
            <!-- Fin Formulario Busqueda de unidad medida -->
            <v-spacer />
            <!-- Modal crear y modificar unidad medida -->
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ma-2" v-on="on" small>Nuevo</v-btn>
              </template>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-6">
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="nombre" label="Nombre"
                          :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Nombre puede tener de 3 a 20 caracteres', Rules.unespacio]"
                          maxlength="20" background-color="blue lighten-5" prepend-inner-icon="notes" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="prefijo" label="Prefijo"
                          :rules="[v => (!!v && v.length >= 1 && v.length <= 6) || 'Prefijo puede tener de 1 a 6 caracteres', Rules.unespacio]"
                          maxlength="6" background-color="blue lighten-5" prepend-inner-icon="short_text" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <!-- Mensaje error unidad medida -->
                      <v-col cols="12" v-if="mensajeUnidadMedida != ''">
                        <v-alert text dense type="error">{{ mensajeUnidadMedida }}</v-alert>
                      </v-col>
                      <!-- Mensaje error unidad medida -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear y modificar unidad medida -->

            <!-- Modal activar, desactivar -->
            <v-dialog v-model="adModal" max-width="290">
              <v-card>
                <v-card-title class="headline" v-if="adAccion == 1">¿Activar U. M.?</v-card-title>
                <v-card-title class="headline" v-if="adAccion == 2">¿Desactivar U. M. ?</v-card-title>
                <v-card-text>
                  Estás a punto de
                  <span v-if="adAccion == 1">Activar</span>
                  <span v-if="adAccion == 2">Desactivar</span>
                  la Unidad de Medida {{ adNombre }}
                </v-card-text>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="green darken-1" @click="activarDesactivarCerrar" small>Cancelar</v-btn>
                  <v-btn v-if="adAccion == 1" color="orange darken-4" @click="activar" small>Activar</v-btn>
                  <v-btn v-if="adAccion == 2" color="orange darken-4" @click="desactivar" small>Desactivar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal activar, desactivar -->
          </v-toolbar>
        </template>
        <!-- Opciones editar, eliminar -->
        <template v-slot:item.condicion="{ item }">
          <template v-if="item.condicion">
            <v-btn rounded x-small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
          </template>
          <template v-else>
            <v-btn rounded x-small color="orange darken-2" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="editItem(item)">edit</v-icon>
          </v-btn>
          <v-btn small icon>
            <v-icon color="red darken-2" @click="deleteItem(item)">delete_forever</v-icon>
          </v-btn>
        </template>
        <!-- Fin Opciones editar, eliminar -->
      </v-data-table>
      <!-- Fin Listado de unidad de medida y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";

export default {
  data() {
    return {
      unidad_medidas: [],
      dialog: false,
      headers: [
        { text: "NOMBRE", value: "nombre", sortable: false },
        { text: "PREFIJO", value: "prefijo", sortable: false },
        { text: "CONDICIÓN", value: "condicion", sortable: false },
        { text: "OPCIONES", value: "opciones", sortable: false },
      ],
      buscar: "",
      editedIndex: -1,
      id: "",
      nombre: "",
      prefijo: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajeUnidadMedida: "",

      desabilitar: false,
      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },
      /* Validaciones */
      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },
  computed: {
    ...mapState(["logo"]),

    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Unidad de Medida"
        : "Actualizar Unidad de Medida";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },
  methods: {
    listar() {
      let me = this;

      me.loading = true;
      axios
        .get("api/Unidad_Medidas/Listar")
        .then(function (response) {
          //console.log(response);
          me.unidad_medidas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        axios
          .put("api/Unidad_Medidas/Actualizar", {
            idunidad_medida: me.id,
            nombre: me.nombre,
            prefijo: me.prefijo,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeUnidadMedida = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        axios
          .post("api/Unidad_Medidas/Crear", {
            nombre: me.nombre,
            prefijo: me.prefijo,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeUnidadMedida = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la Unidad de Medida " +
        item.nombre +
        "?"
      );
      if (respta) {
        let me = this;
        axios
          .delete("api/Unidad_Medidas/Eliminar/" + item.idunidad_medida)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeUnidadMedida = "";
      }, 3000);
    },

    editItem(item) {
      this.id = item.idunidad_medida;
      this.nombre = item.nombre;
      this.prefijo = item.prefijo;
      this.editedIndex = 1;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.id = "";
      this.nombre = "";
      this.prefijo = "";
      this.mensajeUnidadMedida = "";
      this.editedIndex = -1;
    },

    desactivar() {
      let me = this;

      axios
        .put("api/Unidad_Medidas/Desactivar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.adId = item.idunidad_medida;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios
        .put("api/Unidad_Medidas/Activar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Desactivar, activar unidad medida */

    crearPDF() {
      var columns = [
        { header: "NOMBRE", dataKey: "nombre" },
        { header: "PREFIJO", dataKey: "prefijo" },
        { header: "CONDICION", dataKey: "condicion" },
      ];
      var body = [];

      this.unidad_medidas.map(function (x) {
        body.push({
          nombre: x.nombre,
          prefijo: x.prefijo,
          condicion: x.condicion == 1 ? "Activo" : "Inactivo",
        });
      });

      //console.log(body);

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "LISTADO UNIDAD DE MEDIDA",
            doc.internal.pageSize.width / 2,
            45,
            {
              align: "center",
            }
          );
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
        }
      };
      // Agregar pie de pagina: numeracion
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          nombre: { cellWidth: "250" },
          prefijo: { cellWidth: "150" },
          condicion: { cellWidth: "115", halign: "center" },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Unidad de Medida.pdf");
    },
  },
};
</script>