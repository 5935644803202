<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de baja y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="detalles" item-key="idmovimiento" show-expand
        :items-per-page="15" :search="buscar" class="elevation-3" dense>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">ANULACIONES</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de baja -->
            <v-text-field class="mr-1" v-model.trim="buscar" outlined dense label="Búsqueda"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
              offset-y min-width="290px" :close-on-click="dates.length == 2 ? true :  false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details class="mr-1" append-icon="update"
                  @click:append="listar()"></v-text-field>
              </template>
              <v-date-picker v-model="dates"
                @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()" range>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario Busqueda de baja -->
          </v-toolbar>
        </template>
        <!-- Opciones editar, eliminar baja -->
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.fecha_comprobante="{ item }">
          <span>{{ item.fecha_comprobante | formatearFecha }}</span>
        </template>
        <template v-slot:item.comp_referencia="{ item }">
          <span v-for="item,i in JSON.parse(item.ref_comprobante)" :key="i" text>{{ item.serie_comprobante}}-{{
          item.num_comprobante}}</span>
        </template>
        <template v-slot:item.identificador="{ item }">
          <span>{{ item.serie_comprobante +"-"+ item.num_comprobante }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Aceptado'">
            <strong class="green--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Rechazado'">
            <span class="red--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Enviado'">
            <span class="orange--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por enviar'">
            <span class="brown--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'En proceso'">
            <span class="pink--text">{{ item.estado }}</span>
          </template>
          <template v-else>
            <span>{{ item.estado }}</span>
          </template>
        </template>
        <template v-slot:item.descargas="{ item }">
          <template v-if="item.estado == 'Enviado' || item.estado == 'Aceptado'">
            <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
          </template>
          <template v-if="item.estado == 'Aceptado'">
            <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">
          <template v-if="item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'En proceso'">
            <v-btn x-small color="orange" class="white--text" @click="consultarTicket(item)" :loading="consultar"
              :disabled="consultar">Enviar Baja</v-btn>
          </template>
          <template v-if="item.estado == 'Por enviar' || item.estado == 'Creado'">
            <v-btn x-small color="brown" class="white--text" @click="reenviarBaja(item)" :loading="consultar"
              :disabled="consultar">Reenviar</v-btn>
          </template>

          <template
            v-if="item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'Creado' || item.estado == 'Por enviar' || item.estado == 'En proceso'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon color="red" @click="deleteItem(item)">delete</v-icon>
                </v-btn>
              </template>
              <span>Anular</span>
            </v-tooltip>
          </template>
        </template>
        <!-- Fin Opciones editar, eliminar baja -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong v-if="item.msj_cdr.length>0">SUNAT:</strong>
            {{ item.msj_cdr }}
          </td>
        </template>
      </v-data-table>
      <!-- Fin Listado de baja y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      detalles: [],
      dialog: false,

      headers: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "FEC. EMISIÓN", value: "fecha", sortable: false },
        { text: "FEC. REF.", value: "fecha_comprobante", sortable: false },
        { text: "COMPROB. REF.", value: "comp_referencia", sortable: false },
        { text: "IDENTIFICADOR", value: "identificador", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "TICKET", value: "nro_ticket", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      buscar: "",
      fecha_emision_formateada: "",
      fecha_emision: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      consultar: false,
      loading: false,

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.fechaseleccionada(this.fecha_emision);
    this.listar();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Movimientos/ListarBaja", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.detalles = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_emision_formateada = ddmmyy;
    },

    reenviarBaja(item) {
      let me = this;
      me.consultar = true;

      if (item.idtipo_comprobante == "81") {
        axios
          .put(
            "api/Movimientos/ReenviarResumenDiarioSunat/" + item.idmovimiento
          )
          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.SnackbarShow("error", error.response.data); // Error de Pc
          });
      } else if (item.idtipo_comprobante == "82") {
        axios
          .put(
            "api/Movimientos/ReenviarComunicacionBajaSunat/" + item.idmovimiento
          )
          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.SnackbarShow("error", error.response.data); // Error de Pc
          });
      }
    },

    consultarTicket(item) {
      let me = this;
      me.consultar = true;

      axios
        .put("api/Movimientos/ConsultarTicket/" + item.idmovimiento)
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.limpiar();
          me.SnackbarShow("error", error.response.data); // Error de Pc
        });
    },

    descargarXml(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarXmlResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            me.datos.ruc +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    descargarCdr(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarCdrResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "R-" +
            me.datos.ruc +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    /* Eliminar baja */
    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas anular el resumen diario " +
        item.num_comprobante +
        "?"
      );
      if (respta) {
        let me = this;
        axios
          .put("api/Movimientos/AnularRdiarioCbaja/" + item.idmovimiento)
          .then((response) => {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            this.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.SnackbarShow("error", error.response.data);
          });
      }
    },
    /* Fin Eliminar baja */

    limpiar() {
      this.consultar = false;

      this.fecha_emision = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_emision); // Cargamos fecha actual a fecha_comprobante y fecha_comprobate_formateada
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>
