<template>
  <v-layout align-start>
    <v-flex>
      <!-- Listado de Movimientos -->
      <v-data-table :headers="headers" :items="movimientos" :items-per-page="15" dense class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">MOVIMIENTOS</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <!-- Impresion movimientos -->
            <v-btn fab x-small dark color="info" @click="crearPDF()" class="mr-1">
              <v-icon>print</v-icon>
            </v-btn>
            <!-- Fin Impresion movimientos -->
            <v-spacer />
            <!-- Formulario busqueda de movimientos -->
            <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscar_usuario" v-model="idusuario"
              :disabled="!esAdministrador" :items="usuarios" label="Usuario"
              :rules="[v => !!v || 'Nombres es requerido']" clearable hide-details class="mr-1" @change="listar">
            </v-autocomplete>
            <v-select outlined dense v-model="moneda" :items="tipo_servicios" label="Moneda"
              background-color="blue lighten-5" @change="listar" class="mr-1" hide-details></v-select>
            <v-spacer />
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="fecha_formateada" readonly v-on="on"
                  background-color="blue lighten-5" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="fecha" @input="menu = false" @change="fechaseleccionada(fecha), listar()">
              </v-date-picker>
            </v-menu>
            <!-- Fin Formulario busqueda de movimientos -->
            <v-spacer />
          </v-toolbar>
        </template>
        <template v-slot:item.tipo_comprobante="{ item }">
          <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
        </template>
        <template v-slot:item.moneda="{ item }">
          <template v-if="item.moneda == 'S'">
            <span>S/</span>
          </template>
          <template v-else>
            <span>$</span>
          </template>
        </template>

        <template v-slot:item.ingreso="{ item }">
          <div class="text-right">
            <span>{{ item.ingreso | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.egreso="{ item }">
          <div class="text-right">
            <span>{{ item.egreso | formatearNumero }}</span>
          </div>
        </template>

        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template slot="body.append">
          <tr>
            <td :colspan="5" class="text-right font-weight-bold">TOTAL:</td>
            <td class="text-right font-weight-bold">{{ totals.ingreso | formatearNumero }}
            </td>
            <td class="text-right font-weight-bold">{{ totals.egreso | formatearNumero }}
            </td>
          </tr>
        </template>
        <template slot="body.append">
          <tr>
            <td :colspan="5" class="text-right font-weight-bold">SALDO INICIAL:</td>
            <td class="text-right font-weight-bold">{{ (moneda == 'S' ? saldoInicialMN : saldoFinalME) |
                formatearNumero
            }}</td>
          </tr>
        </template>
        <template slot="body.append">
          <tr>
            <td :colspan="5" class="text-right font-weight-bold">SALDO FINAL:</td>
            <td class="text-right font-weight-bold">{{ (moneda == 'S' ? saldoInicialMN : saldoFinalME)
                +
                totals.ingreso -
                totals.egreso |
                formatearNumero
            }}</td>
          </tr>
        </template>
      </v-data-table>
      <!--Fin Listado de Movimientos -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      movimientos: [],
      dialog: false,
      headers: [
        { text: "CdMv", value: "idmovimiento", sortable: false  },
        { text: "GLOSA", value: "glosa", sortable: false  },
        { text: "MOVIMIENTO", value: "movimiento", sortable: false  },
        //{ text: "COMPROBANTE", value: "tipo_comprobante", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "M", value: "moneda", sortable: false },
        { text: "INGRESOS", align: "center", value: "ingreso", sortable: false  },
        { text: "EGRESOS", align: "center", value: "egreso", sortable: false  },
        { text: "PAGO", value: "tipo_pago", sortable: false },
      ],

      detalles: [],

      buscar_usuario: "",
      idusuario: "",
      usuarios: [],

      id: "",
      texto: "",

      saldoInicialMN: "",
      saldoInicialME: "",
      ingresoDiaMN: "",
      egresoDiaMN: "",
      ingresoDiaME: "",
      egresoDiaME: "",
      saldoFinalMN: "",
      saldoFinalME: "",

      moneda: "S",
      tipo_servicios: [
        { text: "Soles", value: "S" },
        { text: "Dolares", value: "D" },
      ],

      fecha_formateada: "",
      fecha: moment().format("YYYY-MM-DD"),
      modal: false,
      menu: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador"]),

    totals() {
      const totals = this.movimientos.reduce((acc, d) => {
        acc.ingreso += d.ingreso
        acc.egreso += d.egreso
        return acc
      }, {
        ingreso: 0,
        egreso: 0,
      })
      return totals
    },
  },

  watch: {},

  close() { },

  created() {
    this.idusuario = parseInt(this.usuario.idusuario);
    this.listar();
    this.fechaseleccionada(this.fecha);
    this.selectUsuario();
  },

  methods: {
    listar() {
      let me = this;
      me.idusuario == null ? me.idusuario = parseInt(this.usuario.idusuario) : me.idusuario

      axios.get("api/Movimientos/ListarMovimientoFiltro/" +
        parseInt(me.usuario.idsucursal) +
        "/" +
        me.idusuario +
        "/" +
        me.moneda +
        "/" +
        me.fecha)
        .then(function (response) {
          me.movimientos = response.data;
          me.saldoAnterior();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    saldoAnterior() {
      let me = this;
      var saldosAnteriorArray = [];
      // Limpiamos variables
      me.saldoInicialMN = 0;
      me.saldoInicialME = 0;
      me.ingresoDiaMN = 0;
      me.egresoDiaMN = 0;
      me.ingresoDiaME = 0;
      me.egresoDiaME = 0;

      axios.get("api/Saldos_Caja/MostrarSaldoDiaAnterior", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: me.idusuario,
          fecha: moment(me.fecha)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
        },
      })
        .then(function (response) {
          saldosAnteriorArray = response.data;
          me.saldoInicialMN = saldosAnteriorArray.saldo_mn;
          me.saldoInicialME = saldosAnteriorArray.saldo_me;
        })
        .catch(function (error) {
          //console.log(error.response.data);
        });
    },

    selectUsuario() {
      let me = this;

      var usuariosArray = [];

      axios.get("api/Usuarios/SelectUsuarioFiltro", {
        params: {
          buscar: me.buscar_usuario == "" ? "-" : me.buscar_usuario,
        },
      })
        .then(function (response) {
          usuariosArray = response.data;
          usuariosArray.map(function (x) {
            me.usuarios.push({
              text: x.nombre,
              value: x.idusuario,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_formateada = ddmmyy;
    },

    crearPDF() {
      var columns = [
        { header: "CdMv", dataKey: "idmovimiento" },
        { header: "GLOSA", dataKey: "glosa" },
        { header: "MOVIMIENTO", dataKey: "movimiento" },
        { header: "COMPROBANTE", dataKey: "tipo_comprobante" },
        { header: "PAGO", dataKey: "tipo_pago" },
        { header: "INGRESOS", dataKey: "ingreso" },
        { header: "EGRESOS", dataKey: "egreso" },
      ];
      var body = [];
      var foot = [];

      var totalingreso = 0.0;
      var totalegreso = 0.0;

      for (var i = 0; i < this.movimientos.length; i++) {
        totalingreso = totalingreso + parseFloat(this.movimientos[i].ingreso);
        totalegreso = totalegreso + parseFloat(this.movimientos[i].egreso);
        body.push({
          idmovimiento: this.movimientos[i].idmovimiento,
          glosa: this.movimientos[i].glosa,
          movimiento: this.movimientos[i].movimiento,
          tipo_comprobante:
            this.movimientos[i].serie_comprobante +
            "-" +
            this.movimientos[i].num_comprobante,
          tipo_pago: this.movimientos[i].tipo_pago,
          ingreso: numeral(this.movimientos[i].ingreso).format("###,##0.00"),
          egreso: numeral(this.movimientos[i].egreso).format("###,##0.00"),
        });

        if (i === this.movimientos.length - 1) {
          foot.push([
            {
              content: "TOTAL:",
              colSpan: 5,
            },
            numeral(totalingreso).format("###,##0.00"),
            numeral(totalegreso).format("###,##0.00"),
          ], [
            { content: "SALDO INICIAL CAJA", colSpan: 5, },
            numeral(this.moneda == 'S' ? this.saldoInicialMN : this.saldoFinalME).format("###,##0.00"),
          ], [
            { content: "SALDO FINAL CAJA", colSpan: 5, },
            numeral((this.moneda == 'S' ? this.saldoInicialMN : this.saldoFinalME) + totalingreso - totalegreso).format("###,##0.00"),
          ]);
        }

      }
      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(
            +this.moneda == ""
              ? "MOVIMIENTOS"
              : "MOVIMIENTO DIARIO: " + this.fecha_formateada,
            doc.internal.pageSize.width / 2,
            45,
            {
              align: "center",
            }
          );
          doc.setFontSize(8);
          doc.text("SUCURSAL: " + this.datos.sucursal, 40, 72);
          doc.text("USUARIO: " + this.buscar_usuario, 40, 86);
          doc.text(
            this.moneda == ""
              ? ""
              : this.moneda == "S"
                ? "MONEDA: SOLES"
                : "MONEDA: DOLARES",
            260,
            72
          );
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
        }
      };
      // Agregar pie de pagina: numeracion
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 8,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          idmovimiento: { cellWidth: 32, halign: "right" },
          glosa: { cellWidth: 120.28 },
          movimiento: { cellWidth: 108 },
          tipo_comprobante: { cellWidth: 80 },
          tipo_pago: { cellWidth: 60 },
          ingreso: { cellWidth: 50, halign: "right" },
          egreso: { cellWidth: 65, halign: "right" },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Movimientos.pdf");
    },
  },
};
</script>
