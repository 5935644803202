<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="cabeceraContabilidad" :items="resumenes" :items-per-page="15" dense
        class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">CONTABILIDAD RESÚMEN</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-spacer />
              <v-autocomplete outlined dense v-model="idsucursal" :items="selectSucursales"
                :search-input.sync="buscar_sucursal" label="Sucursales" :rules="[v => !!v || 'Requerido']"
                background-color="blue lighten-5" @change="resumenes = []" class="mr-1" hide-details></v-autocomplete>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                    background-color="blue lighten-5" hide-details class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true" range>
                </v-date-picker>
              </v-menu>
              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                <v-icon>description</v-icon>
              </v-btn>
              <!-- Fin Formulario Busqueda -->
            </v-toolbar>
          </v-form>
        </template>

        <!-- Ingresos -->
        <template v-slot:item.numero="{ item }">
          <div class="text-right">
            <span>{{ item.serie_comprobante }}-{{ item.num_comprobante }}</span>
          </div>
        </template>
        <template v-slot:item.subtotal_gravado="{ item }">
          <div class="text-right">
            <span>{{ item.subtotal_gravado | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.igv_gravado="{ item }">
          <div class="text-right">
            <span>{{ item.igv_gravado | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.importe_total="{ item }">
          <div class="text-right">
            <span>{{ item.importe_total | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.costo="{ item }">
          <div class="text-right">
            <span>{{ item.costo | formatearNumero }}</span>
          </div>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      resumenes: [],
      dialog: false,
      cabeceraContabilidad: [
        //{ text: "CdSc", value: "idsucursal" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "SUBTOTAL", value: "subtotal_gravado", sortable: false },
        { text: "IMPUESTO", value: "igv_gravado", sortable: false },
        { text: "TOTAL", value: "importe_total", sortable: false },
        { text: "COSTO", value: "costo", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "RUC", value: "ruc", sortable: false },
        { text: "NOMBRE", value: "nombre", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
      ],

      idsucursal: "",
      selectSucursales: [],

      buscar_sucursal: "",
      buscar_movimiento: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true, // desabilitado buscar persona
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Movimientos/ReporteContabilidadResumen", {
          params: {
            idsucursal: me.idsucursal,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.resumenes = response.data;
          me.desabilitar = false;
          me.loading = false;
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },

    select() {
      let su = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      this.resumenes.map(function (x) {
        rows.push({
          //CdSc: x.idsucursal,
          Comprobante: x.comprobante,
          Serie: x.serie_comprobante,
          Número: x.num_comprobante,
          Subtotal: x.subtotal_gravado,
          Impuesto: x.igv_gravado,
          Total: x.importe_total,
          Moneda: x.moneda,
          Costo: x.costo,
          Fecha: x.fecha,
          Hora: x.hora,
          RUC: x.ruc,
          Nombre: x.nombre,
          Estado: x.estado,
        });
      });

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Ventas");

      XLSX.writeFile(
        wb,
        "Ventas " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

