<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de Compras -->
      <v-data-table :loading="loading" :headers="cabeceraListarCompra" :items="compras" :items-per-page="15"
        item-key="idmovimiento" show-expand class="elevation-3" dense>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">COMPRAS</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de compra -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar()"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <!-- Formulario busqueda de compra -->
            <v-spacer />
            <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
              min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                @change="listar()" range></v-date-picker>
            </v-menu>
            <v-spacer />

            <!-- Modal crear conograma para ingreso cuentas por pagar -->
            <v-dialog v-model="verCronograma" max-width="800px" persistent>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Crear Cronograma</v-toolbar-title>
                  <v-spacer />
                  Importe: {{ moneda == "S" ? "S/" : "US$" }} {{ importe_total | formatearNumero }}
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-2">
                      <v-col cols="12" class="mb-4">
                        <v-chip color="primary" class="mr-1" label outlined>
                          <strong>Proveedor: {{ proveedor }}</strong>
                        </v-chip>
                        <v-chip color="primary" label outlined>
                          <strong>Fecha: {{ fecha | formatearFecha }}</strong>
                        </v-chip>
                      </v-col>
                      <v-col cols="6" md="1">
                        <v-text-field outlined dense label="Plazo" v-model="plazo" @keyup.up="plazo++"
                          @keyup.down="plazo--" background-color="blue lighten-5" v-mask-number maxlength="2"
                          class="mr-1"></v-text-field>
                      </v-col>
                      <v-col cols="6" md="3">
                        <v-text-field outlined dense type="text" label="importe" v-model="calcularCuota"
                          :background-color="moneda == 'S' ? 'blue lighten-5' : 'green lighten-5'"
                          :prefix="moneda == 'S' ? 'S/' : '$'" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" class="mb-4">
                        <v-card-actions class="py-0 justify-end">
                          <v-btn color="error" @click="ocultarCronograma()" small>
                            <v-icon left>cancel</v-icon>Cancelar
                          </v-btn>
                          <v-btn color="primary" @click="guardarCronograma()" :loading="desabilitar"
                            :disabled="desabilitar || !isValid" small>
                            <v-icon left>save</v-icon>Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-date-picker scrollable v-model="fecha_cronograma" min="2019-01" :events="eventosCxP"
                          :event-color="obtenerEventoColor"
                          @change="fechaSeleccionada(fecha_cronograma), agregarDetalleCronograma()"></v-date-picker>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <!-- Agremamos tabla detalles de cronograma -->
                        <v-data-table :headers="cabeceraCronograma" :items="cronograma" hide-default-footer
                          class="elevation-3" dense>
                          <template v-slot:item.borrar="{ item }">
                            <v-icon color="red darken-2" class="mr-2"
                              @click="eliminarDetalleConograma(cronograma, item)" small>delete</v-icon>
                          </template>
                          <template v-slot:item.fecha_vencimiento="{ item }">
                            <v-text-field dense type="text" v-model="item.fecha_vencimiento" readonly hide-details>
                            </v-text-field>
                          </template>
                          <template v-slot:item.cuota="{ item }">
                            <v-text-field dense type="text" v-model="item.cuota"
                              :rules="[v => (!!v && v > 0) || 'Requerido', importe_total == calcularCronograma || 'Deben ser iguales']"
                              maxlength="7" v-mask-decimal.us="2" hide-details></v-text-field>
                          </template>
                        </v-data-table>
                        <!-- Fin tabla detalles de cronograma -->
                        <div class="text-end">
                          <v-chip class="ma-2" color="primary" label outlined>
                            <strong>Total {{ moneda == "S" ? "S/" : "US$" }}:{{ calcularCronograma | formatearNumero
                            }}</strong>
                          </v-chip>
                        </div>
                      </v-col>
                      <!-- Mensaje error categoría -->
                      <v-col cols="12" v-if="mensajeDetalleCuentas != ''">
                        <v-alert text dense type="error">{{ mensajeDetalleCuentas }}</v-alert>
                      </v-col>
                      <!-- Mensaje error categoría -->
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear conograma ingreso cuentas por pagar -->

            <!-- Modal ver detalle cuentas por pagar -->
            <v-dialog v-model="verDetalleCuenta" max-width="400px">
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Cronograma de pagos</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" class="mt-4">
                      <!-- Agremamos tabla detalles de cronograma -->
                      <v-data-table :headers="cabeceraDetalleCuenta" :items="detalleCuentas" hide-default-footer
                        class="elevation-3" dense>
                        <template v-slot:item.fecha_vencimiento="{ item }">
                          <span class="font-weight-bold">{{ item.fecha_vencimiento | formatearFecha }}</span>
                        </template>
                        <template v-slot:item.importe="{ item }">
                          <span class="font-weight-bold">{{ moneda == "S" ? "S/" : "US$" }} {{
                            item.importe |
                              formatearNumero
                          }}</span>
                        </template>
                      </v-data-table>

                      <!-- Fin tabla detalles de cronograma -->
                      <div class="text-end">
                        <v-chip class="mt-2" color="primary" label outlined>
                          <strong>Total {{ moneda == "S" ? "S/" : "US$" }}:{{ totalDetalleCuenta | formatearNumero
                          }}</strong>
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Fin Modal ver detalle cuentas por pagar -->

            <!-- Modal anular compra -->
            <v-dialog v-model="adModal" max-width="310">
              <v-card>
                <v-card-title class="headline" v-if="adAccion == 1">¿Anular Compra?</v-card-title>
                <v-card-text>
                  Estás a punto de
                  <span v-if="adAccion == 1">Anular</span>
                  la Compra Nº {{ adId }}
                </v-card-text>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="green darken-1" @click="AnularCerrar" small>Cancelar</v-btn>
                  <v-btn v-if="adAccion == 1" color="orange darken-4" @click="anular" small>Anular</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular compra -->
          </v-toolbar>
        </template>
        <!-- Opciones imprimir, anular movimiento compra y agregar y ver cuentas por pagar -->
        <template v-slot:item.numero="{ item }">
          <span>{{ (item.serie_comprobante + "-" + item.num_comprobante) }}</span>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.importe_total="{ item }">
          <div class="text-right">
            <span>{{ item.moneda == "S" ? "S/" : "$" }} {{ item.importe_total | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado != 'Anulado'">
            <span>{{ item.estado }}</span>
          </template>
          <template v-else>
            <span class="red--text">{{ item.estado }}</span>
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">

          <template v-if="item.tipo_pago == 'Crédito' && item.estado == 'Cronograma'">
            <v-btn small icon>
              <v-icon color="blue darken-2" @click="mostrarDetalleCuentas(item)">date_range</v-icon>
            </v-btn>
          </template>
          <template v-if="item.tipo_pago == 'Crédito' && item.estado == 'Creado'">
            <v-btn small icon>
              <v-icon color="orange darken-2" @click="crearCronograma(item)">credit_card</v-icon>
            </v-btn>
          </template>
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <template v-if="item.estado != 'Anulado' && item.idusuario == usuario.idusuario">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="anularMostrar(1, item)">delete</v-icon>
            </v-btn>
          </template>

        </template>
        <!-- Fin Opciones imprimir, anular movimiento compra y agregar y ver cuentas por pagar -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Registrado por:</strong>
            {{ item.usuario }}
          </td>
        </template>
      </v-data-table>
      <!-- Fin Listado de Compras -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      compras: [],
      eventosCxP: [],
      cabeceraListarCompra: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "PAGO", value: "tipo_pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      detalles: [],
      detalleCuentas: [],
      buscar: "",
      idmovimiento: "",

      sucursal: "",
      proveedor: "",
      fecha: "",
      fecha_comprobante: "",
      num_documento: "",
      direccion: "",
      telefono: "",
      email: "",
      importe: 0,
      importe_total: 0,
      idmovimiento: "",
      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",
      moneda: "",
      igv: "",

      comprobanteModal: 0,
      adModal: 0,
      adAccion: 0,
      adId: "",
      adFecha: "",

      mensajeDetalleCuentas: "",

      desabilitar: false,
      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },

      verCronograma: 0,
      verDetalleCuenta: 0,

      cronograma: [],
      detalle_cuentas: [],

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      plazo: "1",
      fecha_cronograma_formateada: "",
      fecha_cronograma: moment().format("YYYY-MM-DD"),
      fecha_actual: moment().format("YYYY-MM-DD"),
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      cabeceraCronograma: [
        { text: "OPC", value: "borrar", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "cuota", sortable: false },
      ],
      cabeceraDetalleCuenta: [
        { text: "#", align: "center", value: "numero", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
      ],

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]), // Llamamos a state para usuario

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Calcular La cuota para cronograma de deuda
    calcularCuota: function () {
      var resultado = 0.0;
      resultado = (
        parseFloat(this.importe_total) /
        parseFloat(this.plazo == "" ? 1 : this.plazo == "0" ? 1 : this.plazo)
      ).toFixed(2);
      return resultado;
    },
    // Calcula el total del detalle del cronograma
    calcularCronograma: function () {
      var resultado = 0.0;
      for (var i = 0; i < this.cronograma.length; i++) {
        resultado = (
          parseFloat(resultado) + parseFloat(this.cronograma[i].cuota)
        ).toFixed(2);
      }
      return resultado;
    },
    // Sumar detalle de cuenta
    totalDetalleCuenta: function () {
      var resultado = 0.0;
      for (var i = 0; i < this.detalleCuentas.length; i++) {
        resultado = (
          parseFloat(resultado) + parseFloat(this.detalleCuentas[i].importe)
        ).toFixed(2);
      }
      return resultado;
    },
  },

  watch: {
    verCronograma(val) {
      val || this.ocultarCronograma();
    },
    verDetalleCuenta(val) {
      val || this.ocultarDetalleCuenta();
    },
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
  },

  created() {
    this.listar();
    this.fechaSeleccionada(this.fecha_cronograma);
    this.cargarEventoCuentaxPagar();
  },

  methods: {
    // Para descargar pdf de blob
    savePdf() {
      var blob = this.blobPdf;
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      var fileName = "Compra " + this.comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    listar() {
      let me = this;
      me.loading = true;

      var deCompra = this.$route.params.pedCompra; // Recibimos pedCompra=true de pedidos proveedor para mostrar snackbar

      if (me.buscar == "") {
        axios
          .get("api/Movimientos/ListarCompra", {
            params: {
              idsucursal: parseInt(me.usuario.idsucursal),
              idusuario: parseInt(me.usuario.idusuario),
              buscar: me.buscar == "" ? "-" : me.buscar,
              fechaInicio: me.dates[0],
              fechaFin:
                me.dates[1] == undefined
                  ? me.dates[0] + " 23:59:59"
                  : me.dates[1] + " 23:59:59",
            },
          })
          .then(function (response) {
            if (deCompra == true) {
              me.alerta.snackbar = true;
              me.alerta.color = "success";
              me.alerta.text = "La compra se creo correctamente.";
              me.$route.params.pedCompra = false;
            }
            me.compras = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Movimientos/ListarCompraTotal", {
            params: {
              idsucursal: parseInt(me.usuario.idsucursal),
              buscar: me.buscar == "" ? "-" : me.buscar,
              fechaInicio: me.dates[0],
              fechaFin:
                me.dates[1] == undefined
                  ? me.dates[0] + " 23:59:59"
                  : me.dates[1] + " 23:59:59",
            },
          })
          .then(function (response) {
            if (deCompra == true) {
              me.alerta.snackbar = true;
              me.alerta.color = "success";
              me.alerta.text = "La compra se creo correctamente.";
              me.$route.params.pedCompra = false;
            }
            me.compras = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    obtenerEventoColor(event) {
      return event < this.fecha_actual
        ? "error"
        : event == this.fecha_actual
          ? "warning"
          : "success";
    },

    cargarEventoCuentaxPagar() {
      let me = this;
      var detalleCxPArray = [];

      axios
        .get(
          "api/Detalle_Cuentas/ListarDetalleCxP/" +
          parseInt(me.usuario.idsucursal)
        )

        .then(function (response) {
          detalleCxPArray = response.data;
          detalleCxPArray.map(function (x) {
            me.eventosCxP.push(x.fecha_vencimiento.substr(0, 10));
          });
          //console.log(me.eventosCxP);
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    /* MOSTRAR COMPROBANTE PARA IMPRESIÓN */
    mostrarComprobante(item) {
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.sucursal = item.sucursal;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.proveedor = item.proveedor;
      this.num_documento = item.num_documento;
      this.direccion = item.direccion;
      this.telefono = item.telefono;
      this.email = item.email;
      this.fecha = item.fecha;
      this.fecha_comprobante = item.fecha_comprobante;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;
      this.motivo = item.motivo;

      this.descto_global = item.descto_global;
      this.descto_item_total = item.descto_item_total;
      this.seguro = item.seguro;
      this.subtotal_gravado = item.subtotal_gravado;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;

      this.listarDetallePedidos(item.idpedido);
      this.comprobanteModal = 1;
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesCompras/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              codigo_repuesto: x.codigo_repuesto,
              unidad_medida: x.unidad_medida,
              descripcion:
                x.codigo_repuesto +
                " " +
                x.categoria +
                " " +
                x.marca_repuesto +
                " " +
                x.descripcion,
              stock_ingreso: x.stock_ingreso,
              valor_unit_compra: x.valor_unit_compra,
              precio_unit_compra: x.precio_unit_compra,
              descto_item: x.descto_item,
            });
          });
          me.crearPDF();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    /* FIN MOSTRAR COMPROBANTE PARA IMPRESIÓN */

    /* GUARDAR CRONOGRAMA CUENTAS POR PAGAR */
    guardarCronograma() {
      let me = this;
      me.detalle_cuentas = [];
      // Validamos si el detalle de pedido esta vacio
      if (me.cronograma.length <= 0) {
        me.mensajeDetalleCuentas =
          "Ingrese al menos una fecha de vencimiento e importe al detalle.";
        me.limpiarAlertaError();
        return me.mensajeDetalleCuentas;
      } // Fin Validamos si el detalle de pedido esta vacio

      me.desabilitar = true;

      // Convetir array cronograma a decimal y formatear fecha
      for (var i = 0; i < me.cronograma.length; i++) {
        me.detalle_cuentas.push({
          fecha_vencimiento: me.fechaConvertida(
            me.cronograma[i].fecha_vencimiento
          ),
          importe: parseFloat(me.cronograma[i].cuota),
          pago_importe: me.cronograma[i].pago_importe,
        });
      } // Fin Convetir array cronograma a decimal y formatear fecha

      // Ordenamos array detalle_cuentas por fecha
      me.detalle_cuentas.sort(function (a, b) {
        if (a.fecha_vencimiento > b.fecha_vencimiento) {
          return 1;
        }
        if (a.fecha_vencimiento < b.fecha_vencimiento) {
          return -1;
        }
        return 0;
      });

      axios
        .post("api/Detalle_Cuentas/Crear", {
          idmovimiento: me.idmovimiento,
          detalle_cuentas: me.detalle_cuentas,
        })
        .then(function (response) {
          me.desabilitar = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.ocultarCronograma();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.detalle_cuentas = [];
          me.mensajeDetalleCuentas = error.response.data;
          me.limpiarAlertaError();
        });
    },

    // Convertimos fecha DD/MM/AAAA a AAAA-MM-DD para cronograma para guaradar en BD
    fechaConvertida(dia) {
      let arrayFecha = dia.split(["/"]);
      let yymmdd = arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      return yymmdd;
    },

    mostrarDetalleCuentas(item) {
      this.idmovimiento = item.idmovimiento;
      this.moneda = item.moneda;
      this.listarDetalleCuentas(item.idmovimiento);
      this.verDetalleCuenta = 1;
    },

    listarDetalleCuentas(id) {
      let me = this;
      axios
        .get("api/Detalle_Cuentas/ListarDetalles/" + id)
        .then(function (response) {
          me.detalleCuentas = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    // Ocultar modal verDetalleCuenta
    ocultarDetalleCuenta() {
      this.verDetalleCuenta = 0;
      this.detalleCuentas = [];
      this.limpiar();
    },
    /* FIN GUARDAR CRONOGRAMA CUENTAS POR PAGAR */

    /* LIMPIAR MENSAJE EN CRONOGRAMA CUENTAS POR PAGAR */
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeDetalleCuentas = "";
      }, 3000);
    },
    /* LIMPIAR MENSAJE EN CRONOGRAMA CUENTAS POR PAGAR */

    /* CONFIGURACION PARA CRONOGRAMA CUENTAS POR PAGAR */
    // Convertimos fecha AAAA-MM-DD a DD/MM/AAAA
    fechaSeleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_cronograma_formateada = ddmmyy;
    },

    crearCronograma(item) {
      this.idmovimiento = item.idmovimiento;
      this.proveedor = item.proveedor;
      this.fecha = item.fecha;
      this.importe_total = item.importe_total.toFixed(2);
      this.moneda = item.moneda;
      this.verCronograma = 1;
    },

    agregarDetalleCronograma() {
      this.cronograma.push({
        fecha_vencimiento: this.fecha_cronograma_formateada,
        cuota: this.calcularCuota,
        pago_importe: 0,
      });
    },

    eliminarDetalleConograma(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    ocultarCronograma() {
      this.verCronograma = 0;
      this.importe = 1;
      this.cronograma = [];
      this.detalle_cuentas = [];
      this.plazo = 1;
      this.limpiar();
    },
    /* FIN CONFIGURACION PARA CRONOGRAMA CUENTAS POR PAGAR */

    limpiar() {
      this.idmovimiento = "";
      this.moneda = "";
      this.proveedor = "";
      this.num_documento = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.detalles = [];

      this.descto_global = 0;
      this.descto_item_total = 0;
      this.seguro = 0;
      this.subtotal_gravado = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.srcPdf = "";
      this.blobPdf = "";
    },

    /* Modal Anular, cancelar compra */
    anular() {
      let me = this;

      axios
        .put(
          "api/Movimientos/AnularCompra/" +
          me.adId +
          "/" +
          me.adFecha +
          "/" +
          me.usuario.idsucursal +
          "/" +
          me.usuario.idusuario
        )
        .then(function (response) {
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },

    anularMostrar(accion, item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
      if (accion == 1) {
        this.adAccion = 1;
      } else {
        this.adModal = 0;
      }
    },

    AnularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular, cancelar compra */

    crearPDF() {
      var columns = [
        {
          header: "CANT.",
          dataKey: "stock_ingreso",
        },
        {
          header: "UNID.",
          dataKey: "unidad_medida",
        },
        {
          header: "DESCRIPCIÓN",
          dataKey: "descripcion",
        },
        {
          header: "VALOR UNIT.",
          dataKey: "valor_unit_compra",
        },
        {
          header: "PREC. UNIT.",
          dataKey: "precio_unit_compra",
        },
        {
          header: "DSCTO",
          dataKey: "descto_item",
        },
        {
          header: "VALOR VTA",
          dataKey: "subtotal",
        },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          stock_ingreso: this.detalles[i].stock_ingreso,
          unidad_medida: this.detalles[i].unidad_medida,
          descripcion: this.detalles[i].descripcion,
          valor_unit_compra: numeral(this.detalles[i].valor_unit_compra).format(
            "###,##0.0000"
          ),
          precio_unit_compra: numeral(this.detalles[i].precio_unit_compra).format(
            "###,##0.0000"
          ),
          descto_item: numeral(this.detalles[i].descto_item).format(
            "###,##0.0000"
          ),

          subtotal: numeral(
            this.redondear(
              this.detalles[i].valor_unit_compra *
              this.detalles[i].stock_ingreso -
              this.detalles[i].descto_item
            )
          ).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "Descuento Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 6,
              },
              numeral(this.descto_global + this.descto_item_total).format(
                "###,##0.00"
              ),
            ],
            [
              {
                content: "Seguro " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 6,
              },
              numeral(this.seguro).format("###,##0.00"),
            ],
            [
              {
                content: "Gravada " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 6,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IGV " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 6,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "Importe Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 6,
                //styles: { halign: "right" },
              },
              numeral(this.importe_total).format("###,##0.00"),
            ]
          );
        }
      };

      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Proveedor: " + this.proveedor, 40, 75);
          doc.text("Ruc N°: " + this.num_documento, 40, 90);
          if (this.motivo != "") { doc.text("Motivo: " + this.motivo, 250, 90); }
          doc.text("Sucursal: " + this.datos.sucursal, 450, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            360,
            90
          );
          doc.text(
            "Fecha Comprob.: " +
            moment(this.fecha_comprobante).format("DD/MM/YYYY"),
            450,
            90
          );

          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            this.comprobante,
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          stock_ingreso: { cellWidth: 31, halign: "center" },
          unidad_medida: { cellWidth: 45, halign: "center" },
          descripcion: { cellWidth: "auto" },
          valor_unit_compra: { cellWidth: 54, halign: "right" },
          precio_unit_compra: { cellWidth: 54, halign: "right" },
          descto_item: { cellWidth: 54, halign: "right" },
          subtotal: { cellWidth: 54, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: this.comprobante,
      });
      addHeaders(doc);
      addFooters(doc);
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>