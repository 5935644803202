import Vue from 'vue'
import Router from 'vue-router'
import decode from 'jwt-decode'

import Home from './views/Home.vue'
import About from './views/About.vue'
import Empresa from './components/Empresa.vue'
import Sucursal from './components/Sucursal.vue'
import Categoria from './components/Categoria.vue'
import Repuesto from './components/Repuesto.vue'
import Cambio_Precio from './components/Cambio_Precio.vue'
import Rol from './components/Rol.vue'
import Usuario from './components/Usuario.vue'
import Marca_Repuesto from './components/Marca_Repuesto.vue'
import Marca_Vehiculo from './components/Marca_Vehiculo.vue'
import Unidad_Medida from './components/Unidad_Medida.vue'
import Forma from './components/Forma.vue'
import Giro from './components/Giro.vue'
import Material from './components/Material.vue'
import Compra from './components/Compra.vue'
import Cuenta_x_Pagar from './components/Cuenta_x_Pagar.vue'
import Traslado from './components/Traslado.vue'
import Ajuste_Stock from './components/Ajuste_Stock.vue'
import Venta from './components/Venta.vue'
import Guia_Remision from './components/Guia_Remision.vue'
import Resumen_Diario from './components/Resumen_Diario.vue'
import Baja from './components/Baja.vue'
import Cuenta_x_Cobrar from './components/Cuenta_x_Cobrar.vue'
import Persona from './components/Persona.vue'
import Caja from './components/Caja.vue'
import Cierre_Diario from './components/Cierre_Diario.vue'
import Pedido_Cliente from './components/Pedido_Cliente.vue'
import Pedido_Proveedor from './components/Pedido_Proveedor.vue'
import Movimiento_Diario from './components/Movimiento_Diario.vue'
import Compra_Venta from './components/Compra_Venta.vue'
import Venta_Usuario from './components/Venta_Usuario.vue'
import Movimiento_Tipo from './components/Movimiento_Tipo.vue'
import Compra_Detallada from './components/Compra_Detallada.vue'
import Venta_Detallada from './components/Venta_Detallada.vue'
import Stock_Repuesto from './components/Stock_Repuesto.vue'
import Detalle_Cuenta from './components/Detalle_Cuenta.vue'
import Estadistica_Mensual from './components/Estadistica_Mensual.vue'
import Contabilidad_Resumen from './components/Contabilidad_Resumen.vue'
import Login from './components/Login.vue'


Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //#region Pagina
    {
      path: '*',
      redirect: '/login',
    },
    {
      path: '/',
      redirect: '/login',
    },
    //#endregion Pagina

    //#region Inicio
    {
      path: '/home',
      name: 'home',
      component: Home,
      // Array y cada elemento va tener acceso al que tenga true
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Inicio

    //#region Ventas
    {
      path: '/ventas',
      name: 'ventas',
      component: Venta,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },

    {
      path: '/pedidoclientes',
      name: 'pedido_clientes',
      component: Pedido_Cliente,
      meta: {
        administradorAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/cuentasxcobrar',
      name: 'cuentas_x_cobrar',
      component: Cuenta_x_Cobrar,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/resumendiario',
      name: 'resumen_diario',
      component: Resumen_Diario,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/baja',
      name: 'baja',
      component: Baja,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Fin Ventas

    //#region Compras
    {
      path: '/compras',
      name: 'compras',
      component: Compra,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/pedidoproveedores',
      name: 'pedido_proveedores',
      component: Pedido_Proveedor,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/cuentasxpagar',
      name: 'cuentas_x_pagar',
      component: Cuenta_x_Pagar,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Compras

    //#region Personas
    {
      path: '/personas',
      name: 'personas',
      component: Persona,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Personas

    //#region Repuestos
    {
      path: '/repuestos',
      name: 'repuestos',
      component: Repuesto,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/cambioprecios',
      name: 'cambioprecios',
      component: Cambio_Precio,
      meta: {
        administradorAuth: true,
      }
    },
    {
      path: '/categorias',
      name: 'categorias',
      component: Categoria,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/marcarepuestos',
      name: 'marca_repuestos',
      component: Marca_Repuesto,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/marcavehiculos',
      name: 'marca_vehiculos',
      component: Marca_Vehiculo,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/tipos', // Se cambio de forma a tipo solo en el nombre
      name: 'tipos',
      component: Forma,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/giros',
      name: 'giros',
      component: Giro,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/materiales',
      name: 'materiales',
      component: Material,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Repuestos

    //#region Inventario
    {
      path: '/traslado',
      name: 'traslado',
      component: Traslado,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
      }
    },
    {
      path: '/ajustesstock',
      name: 'ajustesstock',
      component: Ajuste_Stock,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
      }
    },
    {
      path: '/stockrepuestos',
      name: 'stock_repuestos',
      component: Stock_Repuesto,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true
      }
    },
    //#endregion Inventario

    //#region Guia Remision
    {
      path: '/guiasremision',
      name: 'guias_remision',
      component: Guia_Remision,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Guia Remision

    //#region Caja
    {
      path: '/caja',
      name: 'caja',
      component: Caja,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },

    {
      path: '/cierrediario',
      name: 'cierre_diario',
      component: Cierre_Diario,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Caja

    //#region Mantenimiento
    {
      path: '/empresas',
      name: 'empresas',
      component: Empresa,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/sucursales',
      name: 'sucursales',
      component: Sucursal,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Usuario,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/roles',
      name: 'roles',
      component: Rol,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/unidadmedidas',
      name: 'unidad_medidas',
      component: Unidad_Medida,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true
      }
    },
    //#endregion Mantenimiento

    //#region Reportes
    {
      path: '/movimientodiario',
      name: 'movimiento_diario',
      component: Movimiento_Diario,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/ventausuarios',
      name: 'venta_usuarios',
      component: Venta_Usuario,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/comprasventas',
      name: 'compras_ventas',
      component: Compra_Venta,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/comprasdetalladas',
      name: 'compras_detalladas',
      component: Compra_Detallada,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
      }
    },
    {
      path: '/ventasdetalladas',
      name: 'ventas_detalladas',
      component: Venta_Detallada,
      meta: {
        administradorAuth: true,
      }
    },
    {
      path: '/detallecuentas',
      name: 'detalle_cuentas',
      component: Detalle_Cuenta,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    {
      path: '/movimientostipo',
      name: 'movimientos_tipo',
      component: Movimiento_Tipo,
      meta: {
        administradorAuth: true
      }
    },
    //#endregion Reportes

    //#region Resumenes 
    {
      path: '/estadisticamensual',
      name: 'estadistica_mensual',
      component: Estadistica_Mensual,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/contabilidadresumen',
      name: 'contabilidadresumen',
      component: Contabilidad_Resumen,
      meta: {
        administradorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Resumenes

    //#region Acerca
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        administradorAuth: true,
        almaceneroAuth: true,
        vendedorAuth: true,
        cajaAuth: true
      }
    },
    //#endregion Acerca









    {
      path: '/login',
      name: 'login',
      component: Login,
    }
  ]
})

router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("token")
  let usuario = token == null ? "" : decode(token)

  let ruta = false;
  let expr = usuario.rol;
  switch (expr) {
    case 'Administrador':
      ruta = to.matched.some(record => record.meta.administradorAuth)
      break;
    case 'Almacenero':
      ruta = to.matched.some(record => record.meta.almaceneroAuth)
      break;
    case 'Vendedor':
      ruta = to.matched.some(record => record.meta.vendedorAuth)
      break;
    case 'Caja':
      ruta = to.matched.some(record => record.meta.cajaAuth)
      break;
  }

  if (ruta && !token) {
    next('login')
  } else if (!ruta && token) {
    next('home')
  } else {
    next();
  }

})

export default router
