<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row no-gutters class="mt-4">
                <v-col cols="6" sm="3">
                  <v-select outlined dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli"
                    label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                    @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    background-color="blue lighten-5" v-mask-number></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)">
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                    v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase class="pr-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                    v-uppercase></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>cancel</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>save</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Listado de movimiento caja y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="movimientos" :items-per-page="15" dense
        class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">CAJA</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de movimiento caja -->
            <v-text-field class="mr-1" v-model.trim="buscar" outlined dense label="Búsqueda" @keyup="listar()"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
              offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates"
                @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()" range>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de movimiento caja -->

            <!-- Modal crear movimiento caja -->
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ma-2" v-on="on" small>Nuevo</v-btn>
              </template>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Nuevo Movimiento Caja</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-4">
                      <v-col cols="12" sm="4">
                        <v-select outlined dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                          :rules="[v => !!v || 'La moneda es requerido']" background-color="blue lighten-5"
                          class="mr-1"></v-select>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-select outlined dense v-model="idtipo_movimiento" :items="tipo_movimientos"
                          label="Tipo Movimiento" :rules="[v => !!v || 'El movimiento es requerido']"
                          background-color="blue lighten-5" class="mr-1"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field outlined dense v-model="fecha_comprobante_formateada"
                              label="Fecha comprobante" readonly v-on="on" background-color="blue lighten-5"
                              prepend-inner-icon="event"></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                            @change="fechaseleccionada(fecha_comprobante)"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model.trim="importe_total" label="Importe"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 1 && v.length <= 8) || 'Importe debe tener más de 1 y menos de 8 caracteres']"
                          :prefix="idtipo_servicio == '' ? '' : idtipo_servicio == 1 ? 'S/' : '$'" maxlength="8"
                          v-mask-decimal.us="2"
                          :background-color="idtipo_servicio == '' ? 'blue lighten-5' : idtipo_servicio == 1 ? 'blue lighten-5' : 'green lighten-5'"
                          class="mr-1"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-text-field outlined dense v-model.trim="descripcion" label="Descripción"
                          :rules="[v => (v.length >= 10 && v.length <= 140) || 'La descripción puede tener de 10 a 140 caracteres', Rules.unespacio]"
                          maxlength="140" background-color="blue lighten-5" prepend-inner-icon="notes" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-autocomplete outlined dense @keyup="selectPersona()" :search-input.sync="buscar_proveedor"
                          v-model="idpersona" :items="personas" label="Razón Social"
                          :rules="[v => !!v || 'La razón social es requerido']" background-color="blue lighten-5"
                          clearable class="mr-2" append-outer-icon="person_add" @click:append-outer="mostrarPersona">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model.trim="cuenta" label="Cuenta Contable"
                          :rules="[v => v.length <= 10 || 'La cuenta no debe tener mas de 10 caracteres.']"
                          maxlength="10" background-color="blue lighten-5" v-mask-number></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-select outlined dense v-model="comprobantes" :items="tipo_comprobantes"
                          label="Tipo Documento" :rules="[v => !!v || 'El comprobantes es requerido']"
                          @change="comprobanteSeleccionado(comprobantes)" background-color="blue lighten-5"
                          class="mr-1"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model.trim="serie_comprobante"
                          :rules="[v => v.length == 4 || 'Requerido', Rules.sinespacio]" label="Serie Doc."
                          maxlength="4" background-color="blue lighten-5" :disabled="disabled" v-uppercase class="mr-1">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field outlined dense v-model.trim="num_comprobante" label="Numero Doc"
                          :rules="[v => !!v || 'Numero requerido']" maxlength="8"
                          @blur.prevent="llenarCeroNumComprobante(num_comprobante)" background-color="blue lighten-5"
                          :disabled="disabled" v-mask-number></v-text-field>
                      </v-col>
                      <!-- Mensaje error movimiento caja -->
                      <v-col cols="12" v-if="mensajeCaja != ''">
                        <v-alert text dense type="error">{{ mensajeCaja }}</v-alert>
                      </v-col>
                      <!-- Mensaje error movimiento caja -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-3 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear movimiento caja -->

            <!-- Modal anular Caja -->
            <v-dialog v-model="adModal" max-width="330">
              <v-card>
                <v-card-title class="headline" v-if="adAccion == 1">¿Anular Movimiento Caja?</v-card-title>
                <v-card-text>
                  Estás a punto de
                  <span v-if="adAccion == 1">Anular</span>
                  el movimiento de Caja Nº {{ adId }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="green darken-1" @click="AnularCerrar" small>Cancelar</v-btn>
                  <v-btn v-if="adAccion == 1" color="orange darken-4" @click="anular" small>Anular</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular Caja -->
          </v-toolbar>
        </template>

        <!-- Opciones editar, eliminar movimiento caja -->
        <template v-slot:item.comprobante="{ item }">
          <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
        </template>
        <template v-slot:item.ingreso="{ item }">
          <div class="text-right">
            <span>{{ item.ingreso | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.egreso="{ item }">
          <div class="text-right">
            <span>{{ item.egreso | formatearNumero }}</span>
          </div>
        </template>

        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>

        <template v-slot:item.moneda="{ item }">
          <template v-if="item.moneda == 'S'">
            <span>Soles</span>
          </template>
          <template v-else>
            <span>Dolares</span>
          </template>
        </template>

        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado != 'Anulado'">
            <span>{{ item.estado }}</span>
          </template>
          <template v-else>
            <span class="red--text">{{ item.estado }}</span>
          </template>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <template v-if="item.estado != 'Anulado'">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="anularMostrar(1, item)">delete</v-icon>
            </v-btn>
          </template>

        </template>
        <!-- Fin Opciones editar, eliminar movimiento caja -->
      </v-data-table>
      <!-- Fin Listado de movimiento caja y opciones de titulo, busqueda, nuevo, etc -->

      <!-- Mostrar impresion Modal Comprobante -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar impresion Modal Comprobante -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      movimientos: [],
      dialog: false,
      headers: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "TIPO MOVIMIENTO", value: "movimiento", sortable: false },
        { text: "COMPROBANTE", value: "comprobante", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "MONEDA", value: "moneda", sortable: false },
        { text: "INGRESOS", value: "ingreso", sortable: false },
        { text: "EGRESOS", value: "egreso", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],
      buscar: "",
      buscar_proveedor: "",
      buscar_ubigeo: "",

      idmovimiento: "",
      importe_total: "",
      idtipo_movimiento: "",
      tipo_movimientos: [],
      idtipo_servicio: "",
      tipo_servicios: [],
      descripcion: "",

      idtipo_comprobante: "",
      comprobante: "",
      comprobantes: "",

      tipo_comprobantes: [],
      serie_comprobante: "",
      descripcion_caja: "",
      idpersona: "",
      personas: [],
      cuenta: "",
      num_comprobante: "",
      ruc_comprobante: "",
      denom_comprobante: "",
      moneda: "",
      sucursal: "",
      movimiento: "",
      pago: 0,

      adModal: 0,
      adAccion: 0,
      adFecha: "",
      adId: "",
      fecha: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,

      fecha_comprobante_formateada: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      comprobanteModal: 0,
      mensajeCaja: "",

      desabilitar: false,
      isValid: true,
      disabled: true, // Serie y numero comprobantes desabilitados

      stringPdf: "",
      blobPdf: "",

      alerta: { snackbar: false, color: "", text: "" },

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]), // Llamamos a state para usuario

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.fechaseleccionada(this.fecha_comprobante);
    this.listar();
    this.select();
  },

  methods: {
    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName =
        "Caja-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    /* Listar movimiento caja */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Movimientos/ListarCajaFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          //console.log(response);
          me.movimientos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Listar movimiento caja */

    selectPersona(id) {
      let me = this;

      var personasArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_proveedor == ""
                  ? "-"
                  : me.buscar_proveedor == null
                    ? "-"
                    : me.buscar_proveedor,
              tipo_doc: "-",
            },
          })
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    // Seleccionar ubigeo por busqueda
    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    /* Seleccionar tipos */
    select() {
      let ts = this;
      var tipo_serviciosArray = [];
      axios
        .get("api/Tipo_Servicios/SelectCaja")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            ts.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let tm = this;
      var tipo_movimientosArray = [];
      axios
        .get("api/Tipo_Movimientos/Select")
        .then(function (response) {
          tipo_movimientosArray = response.data;
          tipo_movimientosArray.map(function (x) {
            tm.tipo_movimientos.push({
              text: x.nombre,
              value: x.idtipo_movimiento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let td = this;
      var tipo_comprobantesArray = [];
      axios
        .get("api/Tipo_Comprobantes/SelectCaja")
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            td.tipo_comprobantes.push({
              text: x.descripcion,
              value: x.idtipo_comprobante + "-" + x.descripcion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let me = this;
      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Seleccionar tipos */

    /* Guardar movimiento caja */
    guardar() {
      let me = this;
      me.desabilitar = true;

      axios
        .post("api/Movimientos/CrearCaja", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          idtipo_movimiento: me.idtipo_movimiento,
          idtipo_servicio: me.idtipo_servicio,
          idtipo_comprobante: me.idtipo_comprobante,
          serie_comprobante: me.serie_comprobante,
          num_comprobante: me.num_comprobante,
          importe_total: parseFloat(me.importe_total),
          // Detalle Caja
          descripcion: me.descripcion,
          fecha_comprobante: me.fecha_comprobante,
          idpersona: me.idpersona,
          cuenta: me.cuenta,
        })
        .then(function (response) {
          me.desabilitar = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.close();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.mensajeCaja = error.response.data;
          me.limpiarAlertaError();
        });
    },
    /* Fin Guardar movimiento caja */

    /* Limpiar mensaje movimiento caja */
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeCaja = "";
      }, 3000);
    },
    /* Fin Limpiar mensaje movimiento caja */

    /* Mostrar movimiento caja item */
    mostrarComprobante(item) {
      // item espera el parametro
      // variable = valor
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.sucursal = item.sucursal;
      this.movimiento = item.movimiento;
      this.pago = item.pago;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.descripcion_caja = item.descripcion_caja;
      this.ruc_comprobante = item.ruc_comprobante;
      this.denom_comprobante = item.denom_comprobante;
      this.fecha = item.fecha;
      this.moneda = item.moneda;
      this.crearPDF();
      this.comprobanteModal = 1;
    },
    /* Fin Mostrar movimiento caja item */

    llenarCeroNumComprobante(num_comprobante) {
      this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
    },

    // Seleccionar comprobantes, activa y desactiva serie y numero de cromprobante automaticamente

    comprobanteSeleccionado(comprobantes) {
      let arrayComprobante = comprobantes.split(["-"]);
      this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
      this.comprobante = arrayComprobante[1]; // nombre comprobante
      if (this.idtipo_comprobante == "00") {
        // Si es Recibo interno
        this.disabled = true;
        this.serie_comprobante = "0000";
        this.num_comprobante = "00000000";
      } else {
        // Si es factura, boleta, guia
        this.disabled = false;
        this.serie_comprobante = "";
        this.num_comprobante = "";
      }
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },
    /* Fin Opciones de llenar ceros y seleccionar fecha seleccionada caja */

    /* Cerrar ventana movimiento caja */
    close() {
      this.dialog = false;
      this.limpiar();
    },

    /* Fin Cerrar ventana movimiento caja */

    /* Cerrar ventana mostrar comprobantes */
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    /* Fin Cerrar ventana mostrar comprobantes */

    /* Limpiar movimiento caja item */
    limpiar() {
      this.idmovimiento = "";
      this.idsucursal = "";
      this.idusuario = "";
      this.idtipo_movimiento = "";
      this.idtipo_servicio = "";
      this.idtipo_comprobante = "";
      this.comprobante = "";
      this.comprobantes = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.moneda = "";
      this.importe_total = "";
      this.pago = 0;

      this.mensajeCaja = "";

      // Detalle Caja
      this.descripcion = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_comprobante); // Cargamos fecha actual a fecha_comprobante y fecha_comprobate_formateada

      this.idpersona = "";
      this.cuenta = "";
      this.ruc_comprobante = "";
      this.denom_comprobante = "";

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";
    },
    /* Fin Limpiar movimiento caja item */

    /* Anular mostrar, cerrarn anular */
    anularMostrar(accion, item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
      if (accion == 1) {
        this.adAccion = 1;
      } else {
        this.adModal = 0;
      }
    },

    AnularCerrar() {
      this.adModal = 0;
    },

    anular() {
      let me = this;

      axios
        .put(
          "api/Movimientos/AnularCaja/" +
          me.adId +
          "/" +
          me.adFecha +
          "/" +
          me.usuario.idsucursal +
          "/" +
          me.usuario.idusuario
        )
        .then(function (response) {
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },
    /* Fin Anular mostrar, cerrarn anular */

    /* PERSONA */
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = "Error en el Nº DNI.";
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text =
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion;
            } else {
              me.nombre = "";
              me.direccion = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "erro";
            me.alerta.text = "Error en el Nº RUC.";
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      //Código para guardar
      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Proveedor",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectPersona(me.idpersona);
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data.mensaje;

          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
        });
    },

    // Mostrar ventana persona
    mostrarPersona() {
      this.verPersona = true;
    },
    // Cerrar ventana persona
    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },
    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    /* FIN PERSONA */

    crearPDF() {
      var columns = [
        {
          header: "DESCRIPCIÓN",
          dataKey: "descripcion",
        },
        {
          header: "IMPORTE",
          dataKey: "pago",
        },
      ];
      var body = [
        {
          descripcion: this.movimiento + " - " + this.descripcion_caja,
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];
      var foot = [
        {
          descripcion: "Total " + (this.moneda == "S" ? "S/" : "US$"),
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Razón Social: " + this.denom_comprobante, 40, 75);
          doc.text("Ruc N°: " + this.ruc_comprobante, 40, 90);
          doc.text("Sucursal: " + this.datos.sucursal, 420, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            420,
            90
          );
          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            this.comprobante,
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          descripcion: { cellWidth: "auto" },
          pago: { cellWidth: 50, halign: "right" },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },
};
</script>