<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row no-gutters class="mt-4">
                <v-col cols="6" sm="3">
                  <v-select outlined dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli"
                    label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                    @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    background-color="blue lighten-5" v-mask-number></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)">
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                    v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase class="pr-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                    v-uppercase></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>cancel</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>save</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Buscar y seleccionar Repuesto -->
      <v-dialog v-model="verRepuestos" max-width="1500px">
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Repuestos</v-toolbar-title>
            <v-spacer />
            <!-- Mensaje error repuesto -->
            <v-chip v-if="mensajePedido != ''" color="red lighten-4" class="ml-0 mr-2 black--text" label>{{
              mensajePedido
            }}</v-chip>
            <!-- Mensaje error repuesto -->
            <v-btn color="white" icon dark @click="ocultarRepuestos()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row no-gutters class="mt-2 mb-2">
              <v-spacer />
              <v-col cols="12" sm="8">
                <v-text-field outlined dense v-model.trim="busqueda_repuesto" label="Buscar" append-icon="search"
                  @keyup="listarRepuesto()" class="my-1" background-color="blue lighten-5" clearable hide-details>
                </v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-data-table :headers="cabeceraRepuestos" :items="repuestos" class="elevation-3" item-key="idrepuesto"
              show-expand dense>
              <template v-slot:item.seleccionar="{ item }">
                <v-icon class="mr-2" @click="agregarDetalle(item)" color="blue darken-3">add_circle</v-icon>
              </template>
              <template v-slot:item.imagen="{ item }">
                <template>
                  <v-avatar tile size="30">
                    <img :src="'data:image/png;base64,' + item.imagen" />
                  </v-avatar>
                </template>
              </template>
              <template v-slot:item.precio_unit_comprad="{ item }">
                <div class="text-right">
                  <span class="font-weight-bold">{{ item.precio_unit_comprad | formatearNumero }}</span>
                </div>
              </template>
              <template v-slot:item.precio_unit_compras="{ item }">
                <div class="text-right">
                  <span class="font-weight-bold">{{ item.precio_unit_compras | formatearNumero }}</span>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>MODELO:</strong>
                  {{ item.compatibilidad }}
                  <strong>PROCEDENCIA:</strong>
                  {{ item.procedencia }}
                  <strong>DESCRIPCIÓN:</strong>
                  {{ item.descripcion }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Buscar y seleccionar Repuesto -->

      <!-- Listado de Pedidos Proveedor -->
      <v-data-table :loading="loading" :headers="cabeceraListarPedido" :items="pedidos" :items-per-page="15"
        class="elevation-3" dense v-if="verNuevaCompra == 0 && verNuevoPedido == 0">
        <template v-slot:top>
          <!-- Titulo y busqueda pedido proveedor -->
          <v-toolbar flat color="white" v-if="verNuevaCompra == 0 && verNuevoPedido == 0">
            <v-toolbar-title class="hidden-sm-and-down">PEDIDO A PROVEEDORES</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de pedido -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar_pedido" background-color="blue lighten-5"
              label="Búsqueda" @keyup="listar()" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
              min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                @change="listar()" range></v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de pedido -->
            <v-btn @click="mostrarNuevoPedido" color="primary" class="ma-2" small>Nuevo</v-btn>
          </v-toolbar>
          <!-- Fin Titulo y busqueda pedido proveedor -->
        </template>
        <!-- Opciones editar, eliminar pedido proveedor y realizar compra -->

        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Pendiente'">
            <span class="orange--text">Pendiente</span>
          </template>
          <template v-else>
            <span class="green--text">{{ item.estado }}</span>
          </template>
        </template>

        <template v-slot:item.importe_total="{ item }">
          <div class="text-right">
            <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }} {{ item.importe_total | formatearNumero }}</span>
          </div>
        </template>

        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>

        <template v-slot:item.opciones="{ item }">

          <template v-if="esAdministrador || esCaja">
            <template v-if="item.estado == 'Recibido'">
              <v-btn small icon>
                <v-icon color="green darken-2" @click="verDetallesCompras(item)">shopping_cart</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-if="item.estado == 'Pendiente'">
            <v-btn small icon>
              <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
          </template>
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="verDetallesPedidos(item)">edit</v-icon>
          </v-btn>
          <template v-if="item.estado == 'Pendiente'">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="deleteItemPedido(item)">delete_forever</v-icon>
            </v-btn>
          </template>

        </template>
        <!-- Opciones Fin editar, eliminar pedido proveedor y realizar compra -->
      </v-data-table>
      <!--Fin Listado de Pedidos Proveedor -->

      <!-- Ventana crear y modificar pedido proveedor  -->
      <v-card v-if="verNuevoPedido == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevoPedido">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="6" md="2" sm="3">
                <v-select outlined dense v-model="tipo_estado" :items="estados" label="Estado"
                  :rules="[v => !!v || 'Estado requerido']" background-color="blue lighten-5" class="mr-1"></v-select>
              </v-col>
              <v-col cols="6" md="2" sm="3">
                <v-select outlined dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                  :rules="[v => !!v || 'Servicio requerido']" background-color="blue lighten-5" class="mr-1"></v-select>
              </v-col>
              <v-col cols="10" md="5" sm="5">
                <v-autocomplete outlined dense @keyup="selectProveedor()" :search-input.sync="buscar_proveedor"
                  v-model="idpersona" :items="proveedores" label="Proveedor"
                  :rules="[v => !!v || 'Proveeedor requerido']" background-color="blue lighten-5" clearable class="mr-2"
                  append-outer-icon="person_add" @click:append-outer="mostrarPersona"></v-autocomplete>
              </v-col>
              <v-col cols="2" md="2" sm="1" class="hidden-sm-and-down">
                <v-text-field outlined dense @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
                  label="Código barra" background-color="blue lighten-5" maxlength="30"></v-text-field>
              </v-col>
              <v-col cols="2" sm="1">
                <div class="text-center">
                  <v-btn @click="mostrarRepuestos()" x-small fab dark color="blue darken-3" class="ma-1">
                    <v-icon dark>list</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesPedidos" :items="detalles" disable-pagination
                  hide-default-footer dense class="elevation-3">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="red darken-2" class="mr-2" @click="eliminarDetallePedido(detalles, item)">
                      delete_forever</v-icon>
                  </template>
                  <template v-slot:item.stock_ingreso="{ item }">
                    <v-text-field dense type="text" @keyup.up="item.stock_ingreso++" @keyup.down="item.stock_ingreso--"
                      v-model.number="item.stock_ingreso" :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="4"
                      v-mask-number class="center-input" hide-details></v-text-field>
                  </template>
                  <template v-slot:item.valor_unit_compra="{ item }">
                    <v-text-field dense type="text"
                      :background-color="idtipo_servicio == 3 ? 'blue lighten-5' : 'green lighten-5'"
                      v-model="item.valor_unit_compra"
                      :rules="[v => !!v || 'Requerido', item.valor_unit_compra > 0 || 'Error']" maxlength="9"
                      v-mask-decimal.us="4" hide-details></v-text-field>
                  </template>
                  <template v-slot:item.descto_item="{ item }">
                    <v-text-field dense type="text"
                      :background-color="idtipo_servicio == 3 ? 'blue lighten-5' : 'green lighten-5'"
                      v-model="item.descto_item" maxlength="9" v-mask-decimal.us="4" hide-details></v-text-field>
                  </template>

                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span>{{ redondear(parseInt(item.stock_ingreso == '' ? 0 : item.stock_ingreso) *
                      parseFloat(item.valor_unit_compra == '' ? 0 : item.valor_unit_compra) -
                      parseFloat(item.descto_item == '' ? 0 : item.descto_item))
                      | formatearNumero }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <v-col cols="10" align="end">
                      <strong>Descuento Global (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense v-model="descto_global" maxlength="7" v-mask-decimal.us="2"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="descto_item_total = redondear(parseFloat(calcularDescuentoItem)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Seguro {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense v-model="seguro" maxlength="7" v-mask-decimal.us="2" class="right-input"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Gravada {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="subtotal_gravado = redondear(parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global) + parseFloat(seguro == '' ? 0 : seguro)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>IGV {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="igv_gravado = redondear((parseFloat(subtotal_gravado) * igv)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Importe Total {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="importe_total = redondear(parseFloat(subtotal_gravado) + parseFloat(igv_gravado)).toFixed(2)"
                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <!-- Mensaje error pedido -->
              <v-col v-if="mensajePedido != ''">
                <v-alert text dense type="error">{{ mensajePedido }}</v-alert>
              </v-col>
              <!-- Mensaje error pedido -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 justify-center">
          <v-btn color="error" @click="ocultarNuevo()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarPedido()" :loading="desabilitar" :disabled="desabilitar || !isValid"
            small>
            <v-icon left>save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear y modificar pedido proveedor  -->

      <!-- Ventana crear nueva compra -->
      <v-card v-if="verNuevaCompra == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>Nueva Compra</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevaCompra">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" sm="2">
                <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field outlined dense v-model="fecha_comprobante_formateada" label="Fecha Comprobante"
                      :rules="[v => !!v || 'Fecha Requerido']" readonly v-on="on" background-color="blue lighten-5"
                      prepend-inner-icon="event" class="mr-1"></v-text-field>
                  </template>
                  <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                    @change="fechaseleccionada(fecha_comprobante)"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select outlined dense v-model="comprobantes" :items="tipo_comprobantes" label="Tipo Comprobante"
                  :rules="[v => !!v || 'Comprobante requerido']" @change="comprobanteSeleccionado(comprobantes)"
                  background-color="blue lighten-5" class="mr-1"></v-select>
              </v-col>
              <v-col cols="3" sm="1">
                <v-text-field outlined dense v-model="serie_comprobante" label="Serie"
                  :rules="[v => v.length == 4 || 'Requerido', Rules.sinespacio]" background-color="blue lighten-5"
                  :disabled="disabled" maxlength="4" class="mr-1" v-uppercase></v-text-field>
              </v-col>
              <v-col cols="8" sm="5">
                <v-text-field outlined dense v-model.trim="num_comprobante" label="Núm. Comprobante"
                  :rules="[v => !!v || 'Requerido', Rules.unespacio]"
                  @blur.prevent="llenarCeroNumComprobante(num_comprobante)" maxlength="107"
                  background-color="blue lighten-5" :disabled="disabled" v-numspace></v-text-field>
              </v-col>
              <v-col cols="1" sm="1">
                <div class="text-center">
                  <v-btn @click="buscarComprobante()" x-small fab dark :disabled="disabled" color="blue darken-3">
                    <v-icon>content_paste_search</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12" sm="7">
                <v-select outlined dense v-model="idpersona" :items="proveedores" label="Proveedor"
                  background-color="blue lighten-5" disabled class="mr-1"></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select outlined dense v-model="tipo_pago" :items="pagos" label="Tipo Pago"
                  :rules="[v => !!v || 'Pago requerido']" background-color="blue lighten-5" class="mr-1"></v-select>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesCompra" :items="detalles" disable-pagination hide-default-footer
                  dense class="elevation-1">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.stock_ingreso="{ item }">
                    <span class="font-weight-bold">{{ item.stock_ingreso }}</span>
                  </template>
                  <template v-slot:item.valor_unit_compra="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{ item.valor_unit_compra }}</span>
                    </div>
                  </template>
                  <template v-slot:item.descto_item="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{ item.descto_item }}</span>
                    </div>
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{ redondear(parseInt(item.stock_ingreso == '' ? 0 :
                      item.stock_ingreso)
                      *
                      parseFloat(item.valor_unit_compra == '' ? 0 : item.valor_unit_compra) -
                      parseFloat(item.descto_item == '' ? 0 : item.descto_item))
                      | formatearNumero }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <v-col cols="10" align="end">
                      <strong>Descuento Global (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled v-model="descto_global" maxlength="7" v-mask-decimal.us="2"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="descto_item_total = redondear(parseFloat(calcularDescuentoItem)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Seguro {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled v-model="seguro" maxlength="7" v-mask-decimal.us="2"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Gravada {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="subtotal_gravado = redondear(parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global) + parseFloat(seguro == '' ? 0 : seguro)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>IGV {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="igv_gravado = redondear((parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global) + parseFloat(seguro == '' ? 0 : seguro)) * igv).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Importe Total {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="importe_total = redondear(parseFloat(subtotal_gravado) + parseFloat(igv_gravado)).toFixed(2)"
                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <!-- Mensaje error compra -->
              <v-col v-if="mensajePedido != ''">
                <v-alert text dense type="error">{{ mensajePedido }}</v-alert>
              </v-col>
              <!-- Mensaje error compra -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="error" @click="ocultarNuevo()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarCompra()" :loading="desabilitar" :disabled="desabilitar || !isValid"
            small>
            <v-icon left>save</v-icon>Crear Compra
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear nueva compra -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Pedido Proveedor Nº {{ idpedido }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";

import { mapGetters, mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],
      detalles: [],
      detalle_compras: [],

      cabeceraListarPedido: [
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "TIPO", value: "tipo_pedido", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraDetallesCompra: [
        { text: "#", value: "num", sortable: false },
        { text: "CANT", align: "center", value: "stock_ingreso", sortable: false },
        { text: "UM", align: "center", value: "unidad_medida", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "VALOR UNIT.", align: "center", value: "valor_unit_compra", sortable: false },
        { text: "DESCTO", align: "center", value: "descto_item", sortable: false },
        { text: "TOTAL", align: "center", value: "subtotal", sortable: false },
      ],

      cabeceraDetallesPedidos: [
        { text: "#", value: "num", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "CANT", value: "stock_ingreso", sortable: false },
        { text: "VALOR/UNIT", value: "valor_unit_compra", sortable: false },
        { text: "DESCTO", value: "descto_item", sortable: false },
        { text: "TOTAL", align: "center", value: "subtotal", sortable: false },
      ],

      cabeceraRepuestos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "U.M.", value: "unidad_medida", sortable: false },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "GIRO", value: "giro", sortable: false },
        { text: "INT", value: "interior", sortable: false },
        { text: "EXT", value: "exterior", sortable: false },
        { text: "ALT", value: "altura", sortable: false },
        { text: "P/U/C $", value: "precio_unit_comprad", sortable: false },
        { text: "P/U/C S/", value: "precio_unit_compras", sortable: false },
      ],

      buscar_pedido: "",
      buscar_proveedor: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      busqueda_repuesto: "",
      repuestos: [],

      idpedido: "",
      idpersona: "",
      proveedores: [],
      idtipo_servicio: 4, // Asignamos el valor de 4 Compra ME
      tipo_servicios: [],

      igv: 0,
      tc: 0,

      descto_global: 0,
      descto_item_total: 0,
      seguro: 0,
      subtotal_gravado: 0,
      igv_gravado: 0,
      importe_total: 0,

      tipo_estado: "Pendiente", // Asignamos el valor de estado Pendiente
      estados: ["Pendiente", "Recibido"],

      tipo_pago: "",
      pagos: ["Contado", "Crédito", "Ajuste"],
      idtipo_comprobante: "",
      comprobante: "",
      tipo_comprobantes: [],
      comprobantes: "", // Solo para visualizar en el select
      serie_comprobante: "",
      num_comprobante: "",

      verNuevoPedido: 0,
      verNuevaCompra: 0,
      verRepuestos: 0,

      sucursal: "",
      proveedor: "",
      fecha: "",
      num_documento: "",
      direccion: "",
      telefono: "",
      email: "",

      moneda: "",

      fecha_comprobante_formateada: "",
      fecha_comprobante: "",
      menuFecha: false,

      comprobanteModal: 0,
      mensajePedido: "", // Mensaje de error en pedido

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos
      disabled: true, // Serie y numero comprobante desabilitados

      alerta: {
        snackbar: false,
        color: "",
        text: "",
      },

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      editedIndex: -1,

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Titulo de pedidos
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Pedido Proveedor"
        : "Actualizar Pedido Proveedor";
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          me.redondear4(
            parseInt(
              me.detalles[i].stock_ingreso == ""
                ? 0.0
                : me.detalles[i].stock_ingreso
            ) *
            parseFloat(
              me.detalles[i].valor_unit_compra == ""
                ? 0.0
                : me.detalles[i].valor_unit_compra
            ) -
            parseFloat(
              me.detalles[i].descto_item == ""
                ? 0.0
                : me.detalles[i].descto_item
            )
          );
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          me.redondear4(
            parseFloat(
              me.detalles[i].descto_item == ""
                ? 0.0
                : me.detalles[i].descto_item
            )
          );
      }
      return resultado;
    },

    ...mapGetters(["esAdministrador", "esCaja"]),
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
    this.select();
  },

  methods: {
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Pedido Proveedor Nro" + this.idpedido;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondear = Number(entero + "e-" + 2);
      return redondear;
    },
    redondear4: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 4));
      let redondear = Number(entero + "e-" + 4);
      return redondear;
    },

    /* LISTAR PEDIDOS */
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Pedidos/ListarFiltroPedidoCompras", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar_pedido == "" ? "-" : me.buscar_pedido,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* FIN LISTAR PEDIDOS */

    /* SELECCIONAR PROVEEDOR, TIPO SERVICIO Y DOCUMENTO */
    selectProveedor(id) {
      let me = this;

      var proveedoresArray = [];

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_proveedor == ""
                  ? "-"
                  : me.buscar_proveedor == null
                    ? "-"
                    : me.buscar_proveedor,
              tipo_doc: "-",
            },
          })
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " - " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;
      var tipo_serviciosArray = [];
      axios
        .get("api/Tipo_Servicios/SelectPedido")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            me.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_comprobantesArray = [];
      axios
        .get("api/Tipo_Comprobantes/SelectCompra")
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            me.tipo_comprobantes.push({
              text: x.descripcion,
              value: x.idtipo_comprobante + "-" + x.descripcion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* FIN SELECCIONAR PROVEEDOR, TIPO SERVICIO Y DOCUMENTO */

    /* PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE */
    guardarPedido() {
      let me = this;
      me.detalle_compras = []; // Limpiamos
      // Validamos
      if (me.detalles.length <= 0) {
        me.mensajePedido = "Ingrese al menos un repuesto al detalle.";
        me.limpiarAlertaError();
        return me.mensajePedido;
      }
      me.convertirDetalleCompra();
      me.desabilitar = true;

      if (me.editedIndex > -1) {
        axios
          .put("api/Pedidos/ActualizarPedidoProveedor", {
            idpedido: me.idpedido,
            idsucursal: parseInt(me.usuario.idsucursal),
            idpersona: me.idpersona,
            idusuario: parseInt(me.usuario.idusuario),
            idtipo_servicio: me.idtipo_servicio,
            igv: me.igv,
            descto_global: parseFloat(
              me.descto_global == "" ? 0 : me.descto_global
            ),
            descto_item_total: parseFloat(
              me.descto_item_total == "" ? 0 : me.descto_item_total
            ),
            seguro: parseFloat(me.seguro == "" ? 0 : me.seguro),
            subtotal_gravado: parseFloat(me.subtotal_gravado),
            igv_gravado: parseFloat(me.igv_gravado),
            importe_total: parseFloat(me.importe_total),
            estado: me.tipo_estado,

            detalle_compras: me.detalle_compras,
          })
          .then(function (response) {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.mensajePedido = error.response.data;
            me.limpiarAlertaError();
            //console.log(error);
          });
      } else {
        axios
          .post("api/Pedidos/CrearPedidoProveedor", {
            idsucursal: parseInt(me.usuario.idsucursal),
            idpersona: me.idpersona,
            idusuario: parseInt(me.usuario.idusuario),
            idtipo_servicio: me.idtipo_servicio,
            igv: me.igv,
            descto_global: parseFloat(
              me.descto_global == "" ? 0 : me.descto_global
            ),
            descto_item_total: parseFloat(
              me.descto_item_total == "" ? 0 : me.descto_item_total
            ),
            seguro: parseFloat(me.seguro == "" ? 0 : me.seguro),
            subtotal_gravado: parseFloat(me.subtotal_gravado),
            igv_gravado: parseFloat(me.igv_gravado),
            importe_total: parseFloat(me.importe_total),
            estado: me.tipo_estado,

            detalle_compras: me.detalle_compras,
          })
          .then(function (response) {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.mensajePedido = error.response.data;
            me.limpiarAlertaError();
            //console.log(error);
          });
      }
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    deleteItemPedido(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar el pedido Nº " + item.idpedido + " ?"
      );
      let me = this;

      if (respta) {
        axios
          .delete("api/Pedidos/Eliminar/" + item.idpedido)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    eliminarDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
    /* FIN PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE */

    /* COMPRA GUARDAR, MOSTRAR VENTANA Y LLENAR DE CEROS SERIE Y NUMERO DE COMPROBANTE */
    guardarCompra() {
      let me = this;
      me.detalle_compras = []; // Limpiamos array para llenarlo
      // Validamos si el detalle de pedido esta vacio
      if (me.detalles.length <= 0) {
        me.mensajePedido = "Ingrese al menos un repuesto al detalle.";
        me.limpiarAlertaError();
        return me.mensajePedido;
      }
      me.convertirDetalleCompra(); // Convertir detalles a entero y decimal
      me.desabilitar = true; // Desabilitamos boton guardar

      axios
        .post("api/Movimientos/CrearCompra", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona, //FE
          idusuario: parseInt(me.usuario.idusuario),
          idpedido: me.idpedido,
          idtipo_servicio: me.idtipo_servicio,
          tipo_pago: me.tipo_pago,
          idtipo_comprobante: me.idtipo_comprobante,
          serie_comprobante: me.serie_comprobante,
          num_comprobante: me.num_comprobante,
          fecha_comprobante: me.fecha_comprobante,
          igv: me.igv,
          descto_global: parseFloat(
            me.descto_global == "" ? 0 : me.descto_global
          ),
          descto_item_total: parseFloat(
            me.descto_item_total == "" ? 0 : me.descto_item_total
          ),
          seguro: parseFloat(me.seguro == "" ? 0 : me.seguro),
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),

          detalle_compras: me.detalle_compras,
        })
        //.then(function(response) {})
        .then((data) => {
          me.ocultarNuevo();
          me.$router.push({
            name: "compras",
            params: {
              pedCompra: true,
            },
          });
        })
        .catch(function (error) {
          me.mensajePedido = error.response.data;
          me.limpiarAlertaError();
          //console.log(error);
        });
    },

    buscarComprobante() {
      let me = this;
      me.loading = true;

      if (
        me.idpersona <= 0 ||
        me.serie_comprobante == "" ||
        me.num_comprobante == ""
      ) {
        me.loading = false;
        me.alerta.snackbar = true;
        me.alerta.color = "error";
        return (me.alerta.text = "Serie y numero es equerido.");
      }

      axios
        .get(
          "api/Movimientos/BuscarComprobante/" +
          me.idpersona +
          "/" +
          me.serie_comprobante +
          "/" +
          me.num_comprobante
        )
        .then(function (response) {
          var data = [response.data];
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text =
            "El comprobante " +
            data[0].serie_comprobante +
            "-" +
            data[0].num_comprobante +
            " ya fue registrado en la fecha " +
            moment(data[0].fecha).format("DD/MM/YYYY") +
            " - " +
            data[0].nompersona +
            " Sucursal " +
            data[0].nomsucursal;
          me.loading = false;
          me.loading = false;
          //me.codigo_barra = "";
        })
        .catch(function (error) {
          me.alerta.snackbar = true;
          me.alerta.color = "info";
          me.alerta.text = "El comprobante no esta registrado.";
          me.loading = false;
          //console.log(error);
        });
    },

    convertirDetalleCompra() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_compras.push({
          idrepuesto: me.detalles[i].idrepuesto,
          stock_ingreso: parseInt(me.detalles[i].stock_ingreso),
          stock_actual: parseInt(me.detalles[i].stock_ingreso),
          valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
          precio_unit_compra:
            me.igv == 0
              ? parseFloat(me.detalles[i].valor_unit_compra)
              : me.redondear4(
                parseFloat(me.detalles[i].valor_unit_compra) * me.igv +
                parseFloat(me.detalles[i].valor_unit_compra)
              ),
          descto_item: parseFloat(
            me.detalles[i].descto_item == "" ? 0 : me.detalles[i].descto_item
          ),
        });
      }
    },

    llenarCeroNumComprobante(num_comprobante) {
      this.num_comprobante = num_comprobante.replace(/\w\S*/g, (w) =>
        w.replace(w, (c) => ("00000000" + c).slice(-8))
      );
      //this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
    },

    comprobanteSeleccionado(comprobantes) {
      let arrayComprobante = comprobantes.split(["-"]);
      this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
      this.comprobante = arrayComprobante[1]; // nombre comprobante

      if (this.idtipo_comprobante == "00") {
        // Si es Recibo interno
        this.disabled = true;
        this.igv = 0;
        this.serie_comprobante = "0000";
        this.num_comprobante = "00000000";
      } else {
        // Si es factura, boleta, guia
        this.disabled = false;
        this.igv = parseFloat(this.datos.igv);
        this.serie_comprobante = this.comprobante.substr(0, 1);
        this.num_comprobante = "";
      }
    },
    /* FIN COMPRA GUARDAR, MOSTRAR VENTANA Y LLENAR DE CEROS SERIE Y NUMERO DE COMPROBANTE */

    /* LIMPIAR MENSAJE EN PEDIDO Y BUSCAR REPUESTO */
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajePedido = "";
      }, 3000);
      this.desabilitar = false; // Habilitamos boton guardar pedido y compra si se tiene error
      this.detalle_compras = []; //Limpiamos array si se tiene error al guardar
    },
    /* LIMPIAR MENSAJE EN PEDIDO Y BUSCAR REPUESTO */

    /* MOSTRAR COMPROBANTE PARA IMPRESIÓN */
    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.proveedor = item.proveedor;
      this.num_documento = item.num_documento;
      this.fecha = item.fecha;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;

      this.descto_global = item.descto_global;
      this.descto_item_total = item.descto_item_total;
      this.seguro = item.seguro;
      this.subtotal_gravado = item.subtotal_gravado;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;

      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    /* FIN MOSTRAR COMPROBANTE PARA IMPRESIÓN */

    /* VER DETALLES DE PEDIDOS Y COMPRAS A PROVEEDORES */
    verDetallesPedidos(item) {
      this.limpiar();

      this.igv = parseFloat(this.datos.igv);
      this.tc = parseFloat(this.datos.tc);

      this.idpedido = item.idpedido;
      this.listarDetallePedidos(item.idpedido);
      this.tipo_estado = item.estado;
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpersona = item.idpersona;
      this.selectProveedor(item.idpersona);

      this.descto_global = parseFloat(item.descto_global).toFixed(2);
      this.seguro = parseFloat(item.seguro).toFixed(2);

      this.verNuevoPedido = 1;
      this.editedIndex = 1;
    },

    verDetallesCompras(item) {
      this.limpiar();

      this.igv = parseFloat(this.datos.igv);
      this.tc = parseFloat(this.datos.tc);

      this.idpedido = item.idpedido;
      this.listarDetallePedidos(item.idpedido);
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpersona = item.idpersona;
      this.selectProveedor(item.idpersona);

      this.descto_global = parseFloat(item.descto_global).toFixed(2);
      this.seguro = parseFloat(item.seguro).toFixed(2);

      this.verNuevaCompra = 1;
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesCompras/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              codigo_repuesto: x.codigo_repuesto,
              unidad_medida: x.unidad_medida,
              categoria: x.categoria,
              descripcion:
                x.codigo_repuesto +
                " " +
                x.categoria +
                " " +
                x.marca_repuesto +
                " " +
                x.descripcion.substr(0, 12),
              stock_ingreso: x.stock_ingreso,
              valor_unit_compra: x.valor_unit_compra.toFixed(4),
              precio_unit_compra: x.precio_unit_compra.toFixed(4),
              descto_item: x.descto_item.toFixed(4),
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* FIN VER DETALLES DE PEDIDOS Y COMPRAS A PROVEEDORES */

    /* BUSCAR POR CODIGO DE BARRAS, LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE COMPRA */
    buscarCodigoBarra() {
      let me = this;
      me.mensajePedido = "";

      axios
        .get("api/Repuestos/BuscarCodigoBarraCompra/" + this.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
        })
        .catch(function (error) {
          //console.log(error);
          me.mensajePedido = "No existe el repuesto";
          me.limpiarAlertaError();
        });
    },

    listarRepuesto() {
      let me = this;
      axios
        .get("api/Repuestos/ListarCompra", {
          params: {
            buscar:
              me.busqueda_repuesto == ""
                ? "-"
                : me.busqueda_repuesto == null
                  ? "-"
                  : me.busqueda_repuesto,
          },
        })
        .then(function (response) {
          me.repuestos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    agregarDetalle(data = []) {
      let me = this;
      me.mensajePedido = "";
      if (me.encuentra(data["idrepuesto"])) {
        me.mensajePedido = "El repuesto ya ha sido agregado.";
        me.limpiarAlertaError();
      } else {
        me.detalles.push({
          idrepuesto: data["idrepuesto"],
          //codigo_repuesto: data["codigo_repuesto"],
          //categoria: data["categoria"],
          //marca_repuesto: data["marca_repuesto"],
          descripcion:
            data["codigo_repuesto"] +
            " " +
            data["categoria"] +
            " " +
            data["marca_repuesto"] +
            " " +
            data["descripcion"],
          stock_ingreso: 1,
          valor_unit_compra: me
            .redondear4(
              (me.idtipo_servicio == "3"
                ? data["precio_unit_compras"]
                : me.idtipo_servicio == "4"
                  ? data["precio_unit_comprad"]
                  : 0) /
              (1 + me.igv)
            )
            .toFixed(4),
          precio_unit_compra: 0,
          descto_item: 0,
        });
      }
    },

    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].idrepuesto == id) {
          sw = 1;
        }
      }
      return sw;
    },

    mostrarRepuestos() {
      this.verRepuestos = 1;
    },

    ocultarRepuestos() {
      this.verRepuestos = 0;
      this.mensajePedido = "";
      //this.busqueda_repuesto = "";
      //this.repuestos = [];
    },
    /* FIN BUSCAR POR CODIGO DE BARRAS, LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE COMPRA */

    /* OCULTAR VENTANA PEDIDO Y COMPRA */
    ocultarNuevo() {
      this.verNuevaCompra = 0;
      this.verNuevoPedido = 0;
      this.desabilitar = false; // Habilitamos boton guardar pedido y compra
      this.listar();
      this.limpiar();
    },
    /* FIN OCULTAR VENTANA PEDIDO Y COMPRA */

    /* PERSONA */
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = "Error en el Nº DNI.";
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text =
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion;
            } else {
              me.nombre = "";
              me.direccion = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = "Error en el Nº RUC.";
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Cliente",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectProveedor(me.idpersona);
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data.mensaje;
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    /* FIN PERSONA */

    /* LIMPIAR PEDIDO Y COMPRA ITEM */
    limpiar() {
      this.idpedido = "";
      this.idpersona = "";
      this.num_documento = "";
      this.idtipo_servicio = 4; // Pedido y compra - asignamos el valor de 4 Compra ME
      this.tipo_pedido = "";

      this.descto_global = 0;
      this.descto_item_total = 0;
      this.seguro = 0;
      this.subtotal_gravado = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.tipo_estado = "Pendiente"; // Pedido - // Asignamos el valor de estado Pendiente

      this.tipo_pago = "";
      this.idtipo_comprobante = "";
      this.comprobante = "";
      this.comprobantes = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.disabled = true;
      //this.igv = 0;

      this.fecha_comprobante = ""; // Limpiamos fecha seleccionada
      this.fecha_comprobante_formateada = ""; // Limpiamos fecha seleccionada

      this.detalles = []; // Array detalle pedidos
      this.detalle_compras = []; // Array Convertido de Array detalles
      this.proveedores = []; // Array proveedor despues de buscar

      this.codigo_barra = ""; // Buscar codigo de barras

      this.mensajePedido = ""; // Mensaje de error en pedido
      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.srcPdf = "";
      this.blobPdf = "";

      this.editedIndex = -1; // Titulo de ventana pedidos
    },
    /* FIN LIMPIAR PEDIDO Y COMPRA ITEM */

    crearPDF() {
      var columns = [
        { header: "CANT.", dataKey: "stock_ingreso" },
        { header: "U.M.", dataKey: "unidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "V.UNITARIO", dataKey: "valor_unit_compra" },
        { header: "DESCTO", dataKey: "descto_item" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          stock_ingreso: this.detalles[i].stock_ingreso,
          unidad_medida: this.detalles[i].unidad_medida,
          descripcion: this.detalles[i].descripcion,
          valor_unit_compra: numeral(this.detalles[i].valor_unit_compra).format(
            "###,##0.0000"
          ),
          descto_item: numeral(this.detalles[i].descto_item).format(
            "###,##0.0000"
          ),
          subtotal: numeral(
            this.redondear(
              this.detalles[i].valor_unit_compra *
              this.detalles[i].stock_ingreso -
              this.detalles[i].descto_item
            )
          ).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "Descuento Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 5,
              },
              numeral(this.descto_global + this.descto_item_total).format(
                "###,##0.00"
              ),
            ],
            [
              {
                content: "Seguro " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 5,
              },
              numeral(this.seguro).format("###,##0.00"),
            ],
            [
              {
                content: "Gravada " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 5,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IGV " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 5,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "Importe Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 5,
              },
              numeral(this.importe_total).format("###,##0.00"),
            ]
          );
        }
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Proveedor: " + this.proveedor, 40, 75);
          doc.text("Ruc N°: " + this.num_documento, 40, 90);

          doc.text("Sucursal: " + this.sucursal, 360, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            360,
            90
          );

          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            "PEDIDO PROVEEDOR",
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            "N°" + this.idpedido,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          stock_ingreso: { cellWidth: 32, halign: "center" },
          unidad_medida: { cellWidth: 40, halign: "center" },
          descripcion: { cellWidth: "auto" },
          valor_unit_compra: { cellWidth: 54, halign: "right" },
          descto_item: { cellWidth: 40, halign: "right" },
          subtotal: { cellWidth: 54, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: "PedidoproveedorNro" + this.idpedido + ".pdf",
      });

      addHeaders(doc);
      addFooters(doc);
      //doc.save("PedidoproveedorNro" + this.id + ".pdf");
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>