<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de resumen diario y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="detalles" item-key="idmovimiento" show-expand
        :items-per-page="15" :search="buscar" class="elevation-3" dense>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">RESÚMENES DIARIOS</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de resumen diario -->
            <v-text-field class="mr-1" v-model.trim="buscar" outlined dense label="Búsqueda"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
              offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details class="mr-1" append-icon="update"
                  @click:append="listar()"></v-text-field>
              </template>
              <v-date-picker v-model="dates"
                @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()" range>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario Busqueda de resumen diario -->

            <!-- Modal crear resumen diario -->
            <v-dialog v-model="dialog" max-width="600px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ma-2" v-on="on" @click="listarResumen()" small>Nuevo</v-btn>
              </template>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Registrar Resumen</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-2 mb-2">
                      <v-spacer />
                      <v-col cols="7" sm="4">
                        <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field outlined dense readonly label="Fec. Emisión Comprob."
                              v-model="fecha_emision_formateada" v-on="on" background-color="blue lighten-5"
                              prepend-inner-icon="event" hide-details></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_emision" @input="menuFecha = false"
                            @change="fechaseleccionada(fecha_emision), detalles_resumen_dia = [], listarResumen();">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="1">
                        <v-btn x-small fab color="blue darken-3" @click="listarResumen()" class="white--text ma-1"
                          :loading="loadingdia" :disabled="loadingdia">
                          <v-icon>update</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer />
                    </v-row>
                    <v-data-table :loading="loadingdia" :headers="cabeceraResumenDiario" :items="detalles_resumen_dia"
                      class="elevation-3" dense>
                      <template v-slot:item.num="{ item }">
                        <td>{{ detalles_resumen_dia.indexOf(item) + 1 }}</td>
                      </template>
                      <template v-slot:item.borrar="{ item }">
                        <v-icon color="red darken-2" class="mr-2"
                          @click="eliminarItemDetalleResumen(detalles_resumen_dia, item)">delete_forever</v-icon>
                      </template>
                      <template v-slot:item.numero="{ item }">
                        <span>{{ item.serie_comprobante }}-{{ item.num_comprobante }}</span>
                      </template>
                      <template v-slot:item.subtotal_gravado="{ item }">
                        <div class="text-right">{{ item.subtotal_gravado | formatearNumero }}</div>
                      </template>
                      <template v-slot:item.igv_gravado="{ item }">
                        <div class="text-right">{{ item.igv_gravado | formatearNumero }}</div>
                      </template>
                      <template v-slot:item.importe_total="{ item }">
                        <div class="text-right">{{ item.importe_total | formatearNumero }}</div>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar"
                    :disabled="desabilitar || !isValid || detalles_resumen_dia == '' ? true : false" small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear resumen diario -->
          </v-toolbar>
        </template>
        <!-- Opciones editar, eliminar resumen diario -->
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.fecha_comprobante="{ item }">
          <span>{{ item.fecha_comprobante | formatearFecha }}</span>
        </template>
        <template v-slot:item.identificador="{ item }">
          <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Aceptado'">
            <strong class="green--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Rechazado'">
            <span class="red--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Enviado'">
            <span class="blue--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por enviar'">
            <span class="brown--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'En proceso'">
            <span class="pink--text">{{ item.estado }}</span>
          </template>
          <template v-else>
            <span>{{ item.estado }}</span>
          </template>
        </template>
        <template v-slot:item.descargas="{ item }">
          <template v-if="item.estado == 'Enviado' || item.estado == 'Aceptado'">
            <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
          </template>
          <template v-if="item.estado == 'Aceptado'">
            <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">
          <template v-if="item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'En proceso'">
            <v-btn x-small color="blue" class="white--text" @click="consultarTicket(item)" :loading="consultar"
              :disabled="consultar">Consultar</v-btn>
          </template>
          <template v-if="item.estado == 'Por enviar' || item.estado == 'Creado'">
            <v-btn x-small color="brown" class="white--text" @click="reenviarResumen(item)" :loading="consultar"
              :disabled="consultar">Reenviar</v-btn>
          </template>
          <template
            v-if="item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'Creado' || item.estado == 'Por enviar' || item.estado == 'En proceso'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon color="red" @click="deleteItem(item)">delete</v-icon>
                </v-btn>
              </template>
              <span>Anular</span>
            </v-tooltip>
          </template>
        </template>
        <!-- Fin Opciones editar, eliminar resumen diario -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
            {{ item.msj_cdr }}
          </td>
        </template>
      </v-data-table>
      <!-- Fin Listado de resumen diario y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      detalles: [],
      detalles_resumen_dia: [],
      dialog: false,
      headers: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "FEC. EMISIÓN", value: "fecha", sortable: false },
        { text: "FEC. REF.", value: "fecha_comprobante", sortable: false },
        { text: "IDENTIFICADOR", value: "identificador", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "TICKET", value: "nro_ticket", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraResumenDiario: [
        { text: "#", value: "num", sortable: false },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "GRAVADA", value: "subtotal_gravado", sortable: false },
        { text: "IGV", value: "igv_gravado", sortable: false },
        { text: "TOTAL", value: "importe_total", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
      ],
      buscar: "",

      fecha_emision_formateada: "",
      fecha_emision: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      desabilitar: false,
      consultar: false,
      isValid: true,
      loadingdia: false,

      loading: false,

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos"]), // Llamamos a state para sucursal

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.fechaseleccionada(this.fecha_emision);
    this.listar();
  },

  methods: {
    /* Listar detalles */
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Movimientos/ListarResumenesDiarios", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.detalles = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    /* Fin Listar detalles */

    /* Guardar resumen diario */
    guardar() {
      let me = this;
      me.desabilitar = true;

      //Código para guardar
      axios
        .post("api/Movimientos/CrearResumenDiario", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          fecha_comprobante: me.fecha_emision,
          detalles_resumen_dia: me.detalles_resumen_dia,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.ocultarNuevo();
          me.listar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data); // Error de Pc
        });
    },
    /* Fin Guardar resumen diario */

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_emision_formateada = ddmmyy;
    },

    listarResumen() {
      let me = this;
      me.loadingdia = true;
      var ResumenDiaArray = [];
      me.detalles_resumen_dia = [];

      axios
        .get("api/Movimientos/ListarResumenDiaFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            fecha: me.fecha_emision,
          },
        })
        .then(function (response) {
          ResumenDiaArray = response.data; // recorremos el array
          ResumenDiaArray.map(function (x) {
            me.detalles_resumen_dia.push({
              idmovimiento: x.idmovimiento,
              num_documento: x.num_documento,
              idtipo_comprobante: x.idtipo_comprobante,
              idtipo_documento: x.idtipo_documento,
              serie_comprobante: x.serie_comprobante,
              num_comprobante: x.num_comprobante,
              comprobante_afectado: x.ref_comprobante.substring(3, 16),
              cod_tipo_comprobante_afectado: x.ref_comprobante.substring(0, 2),
              cod_status: "1",
              subtotal_gravado: x.subtotal_gravado,
              igv_gravado: x.igv_gravado,
              importe_total: x.importe_total,
              afecta_stock: x.afecta_stock,
            });
          });
          me.loadingdia = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    reenviarResumen(item) {
      let me = this;
      me.consultar = true;

      axios
        .put("api/Movimientos/ReenviarResumenDiarioSunat/" + item.idmovimiento)

        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.limpiar();
          me.SnackbarShow("error", error.response.data); // Error de Pc
        });
    },

    consultarTicket(item) {
      let me = this;
      me.consultar = true;

      axios
        .put("api/Movimientos/ConsultarTicket/" + item.idmovimiento)
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.limpiar();
          me.SnackbarShow("error", error.response.data); // Error de Pc
        });
    },

    descargarXml(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarXmlResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            me.datos.ruc +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    descargarCdr(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarCdrResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "R-" +
            me.datos.ruc +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    ocultarNuevo() {
      this.dialog = 0;
      this.limpiar();
    },

    // Eliminar detalle pedido
    eliminarItemDetalleResumen(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    /* Eliminar resumen diario */
    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas anular el resumen diario " +
        item.num_comprobante +
        "?"
      );
      if (respta) {
        let me = this;
        axios
          .put("api/Movimientos/AnularRdiarioCbaja/" + item.idmovimiento)
          .then((response) => {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            this.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.SnackbarShow("error", error.response.data);
          });
      }
    },
    /* Fin Eliminar resumen diario */

    /* Cerrar ventana resumen diario */
    close() {
      this.dialog = false;
      this.limpiar();
    },
    /* Fin Cerrar ventana resumen diario */

    /* Limpiar resumen diario item */
    limpiar() {
      this.desabilitar = false;
      this.consultar = false;

      this.detalles_resumen_dia = [];

      this.fecha_emision = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_emision); // Cargamos fecha actual a fecha_comprobante y fecha_comprobate_formateada
    },
    /* Fin Limpiar resumen diario item */

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>
