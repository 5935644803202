<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row no-gutters class="mt-4">
                <v-col cols="6" sm="3">
                  <v-select outlined dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli"
                    label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                    @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    background-color="blue lighten-5" v-mask-number></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)">
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                    v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase class="pr-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                    v-uppercase></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>cancel</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>save</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Buscar y seleccionar repuesto -->
      <v-dialog v-model="verRepuestos" max-width="1600px">
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Repuestos</v-toolbar-title>
            <v-spacer />
            <v-btn color="white" icon dark @click="ocultarRepuestos()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6" md="3" sm="8">
                <v-text-field outlined dense v-model.trim="brDescripcion" label="Descripción"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="1" class="hidden-sm-and-down">
                <v-text-field outlined dense v-model.trim="brInterior" label="Interior"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="1" class="hidden-sm-and-down">
                <v-text-field outlined dense v-model.trim="brExterior" label="Exterior"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="cat.nombre" :items="cat.categorias" label="Categoria"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="marc.nombre" :items="marc.marca_repuestos" label="Marca"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="veh.nombre" :items="veh.marca_vehiculos" label="Vehiculo"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="1" sm="4">
                <v-select outlined dense v-model="vts.nombre" :items="vts.ventas" label="Venta"
                  background-color="blue lighten-5" hide-details class="my-3"></v-select>
              </v-col>
            </v-row>

            <!-- Mostrar imagen de repuesto -->
            <v-dialog v-model="selectedZoom" max-width="350px">
              <v-avatar tile size="350">
                <img :src="'data:image/png;base64,' + imagen_zoom" />
              </v-avatar>
            </v-dialog>
            <!-- Fin Mostrar imagen de repuesto -->

            <v-data-table :headers="esAdministrador ? cabeceraBusquedaRepuestosAdm : cabeceraBusquedaRepuestos"
              :items="repuestos" item-key="iddetalle_compra" show-expand class="elevation-3" dense>
              <template v-slot:item.seleccionar="{ item }">
                <v-icon @click="agregarDetalle(item)" color="blue darken-3">add_circle</v-icon>
              </template>

              <template v-slot:item.imagen="{ item }">
                <template>
                  <v-avatar tile size="27">
                    <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                  </v-avatar>
                </template>
              </template>

              <template v-slot:item.precio_compra="{ item }">
                <div class="text-right">
                  <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}{{
                    item.precio_unit_compra | formatearNumero
                  }}</span>
                </div>
              </template>

              <template v-slot:item.precio_cambio="{ item }">
                <div class="text-right">
                  <span>S/{{
                    item.idtipo_servicio == 3 ? (item.precio_unit_compra).toFixed(2) : ((item.precio_unit_compra)
                      * tc).toFixed(2)
                  }}</span>
                </div>
              </template>

              <template v-slot:item.precios_ventas="{ item }">
                <div class="text-right">
                  <template v-if="vts.nombre == 'precio_publico'">
                    <strong>S/{{ item.precio_publico | formatearNumero }}</strong>
                  </template>
                  <template v-if="vts.nombre == 'precio_tiendas'">
                    <strong>S/{{ item.precio_tiendas | formatearNumero }}</strong>
                  </template>
                  <template v-if="vts.nombre == 'precio_distribuidor'">
                    <strong>S/{{ item.precio_distribuidor | formatearNumero }}</strong>
                  </template>
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>UM:</strong>
                  {{ item.unidad_medida }}
                  <strong>VEHICULO:</strong>
                  {{ item.marca_vehiculo }}
                  <strong>MODELO:</strong>
                  {{ item.compatibilidad }}
                  <strong>PROCEDENCIA:</strong>
                  {{ item.procedencia }}
                  <strong>DESCRIPCIÓN:</strong>
                  {{ item.descripcion }}
                  <br />
                  <strong>PROVEEDOR:</strong>
                  {{ item.proveedor.substring(0, 30) }}
                  <strong>FEC.INGRESO:</strong>
                  {{ item.fecha | formatearFecha }}
                  <strong>Pedido:</strong>
                  {{ item.idpedido }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Buscar y seleccionar repuesto -->

      <!-- Listado de Pedido Cliente -->
      <v-data-table :loading="loading" :headers="cabeceraListarPedido" :items="pedidos" :items-per-page="15" dense
        class="elevation-3" v-if="verNuevoPedido == 0">
        <template v-slot:top>
          <!-- Titulo y busqueda pedido cliente -->
          <v-toolbar flat color="white" v-if="verNuevoPedido == 0">
            <v-toolbar-title class="hidden-sm-and-down">PEDIDO DE CLIENTES</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de pedido -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar_pedido" background-color="blue lighten-5"
              label="Búsqueda" @keyup="listar" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
              min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                @change="listar()" range></v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de pedido -->
            <v-btn @click="mostrarNuevoPedido" color="primary" class="ma-2" small>Nuevo</v-btn>
          </v-toolbar>
          <!-- Fin Titulo y busqueda pedido cliente -->
        </template>
        <!-- Opciones editar, eliminar pedido cliente y realizar venta -->
        <template v-slot:item.importe_total="{ item }">
          <div class="text-right">
            <span>S/ {{ item.importe_total | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Pendiente'">
            <span class="orange--text">Pendiente</span>
          </template>
          <template v-else>
            <span class="green--text">{{ item.estado }}</span>
          </template>
        </template>

        <template v-slot:item.opciones="{ item }">

          <template v-if="esAdministrador || esCaja">
            <v-btn small icon>
              <v-icon color="green darken-2" @click="irVentas(item)">shopping_cart</v-icon>
            </v-btn>
          </template>
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="verDetallesPedidos(item)">edit</v-icon>
          </v-btn>
          <template v-if="item.estado == 'Pendiente'">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="deleteItemPedido(item)">delete_forever</v-icon>
            </v-btn>

          </template>

        </template>
        <!-- Fin Opciones editar, eliminar pedido cliente y realizar venta -->
      </v-data-table>
      <!--Fin Listado de Pedido Cliente -->

      <!-- Ventana crear y modificar pedido cliente  -->
      <v-card v-if="verNuevoPedido == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text grid-list-sm v-if="verNuevoPedido">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" sm="8">
                <v-autocomplete outlined dense @keyup="selectCliente()" :search-input.sync="buscar_cliente"
                  v-model="idpersona" :items="clientes" label="Cliente" :rules="[v => !!v || 'El cliente es requerido']"
                  background-color="blue lighten-5" clearable class="mr-2" append-outer-icon="person_add"
                  @click:append-outer="mostrarPersona"></v-autocomplete>
              </v-col>
              <v-col cols="10" sm="3">
                <v-text-field outlined dense @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
                  label="Código barra" background-color="blue lighten-5" maxlength="30"></v-text-field>
              </v-col>
              <v-col cols="2" sm="1">
                <div class="text-center">
                  <v-btn @click="mostrarRepuestos()" x-small fab dark color="blue darken-3" class="ma-1">
                    <v-icon dark>list</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesPedidos" :items="detalles" disable-pagination
                  hide-default-footer dense class="elevation-3">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="red darken-2" class="mr-2" @click="eliminarItemDetallePedido(detalles, item)">
                      delete_forever</v-icon>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <span class="font-weight-bold">{{ item.stock_actual }}</span>
                  </template>

                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                      v-model.number="item.cantidad"
                      :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                      maxlength="6" hide-details class="center-input" v-mask-number></v-text-field>
                  </template>
                  <template v-slot:item.precio_unit_venta="{ item }">
                    <v-text-field dense type="text" background-color="blue lighten-5" v-model="item.precio_unit_venta"
                      :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="7" hide-details v-mask-decimal.us="2">
                    </v-text-field>
                  </template>
                  <!-- <template v-slot:item.descto_item="{ item }">
                    <v-text-field
                      dense
                      type="text"
                      v-model="item.descto_item"
                      maxlength="6"
                      hide-details
                      class="right-input"
                      v-mask-decimal.us="2"
                    ></v-text-field>
                  </template>-->
                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span>{{ redondear(item.cantidad * item.precio_unit_venta - item.descto_item) | formatearNumero
                      }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        v-mask-decimal.us="2"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        disabled
                        v-model="descto_item_total=redondear(parseFloat(calcularDescuentoItem)).toFixed(2)"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>-->
                    <v-col cols="10" align="end">
                      <strong>Gravada S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="subtotal_gravado = redondear(parseFloat(importe_total) / (1 + igv)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>IGV S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="igv_gravado = redondear(parseFloat(importe_total) - parseFloat(subtotal_gravado)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Importe Total S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="importe_total = redondear(parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global)).toFixed(2)"
                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="error" @click="ocultarNuevo()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarPedido()" :loading="desabilitar" :disabled="desabilitar || !isValid"
            small>
            <v-icon left>save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear y modificar pedido cliente  -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Pedido Cliente Nº {{ idpedido }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],

      detalles: [],
      detalle_pedidos: [],

      cabeceraListarPedido: [
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "TIPO", value: "tipo_pedido", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraDetallesPedidos: [
        { text: "#", value: "num", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
        { text: "CÓDIGO", value: "codigo_repuesto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "CANTIDAD", value: "cantidad", sortable: false },
        { text: "PRECIO", value: "precio_unit_venta", sortable: false },
        //{ text: "DESCTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaRepuestosAdm: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", sortable: false, align: "center" },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "TP", value: "forma", sortable: false },
        { text: "MT", value: "material", sortable: false },
        { text: "GR", value: "giro", sortable: false },
        { text: "INT", align: "center", value: "interior", sortable: false },
        { text: "EXT", align: "center", value: "exterior", sortable: false },
        { text: "ALT", align: "center", value: "altura", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "P.COMPRA", value: "precio_compra", sortable: false },
        { text: "CAMBIO", value: "precio_cambio", sortable: false },
        { text: "VENTA", value: "precios_ventas", sortable: false },
      ],

      cabeceraBusquedaRepuestos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", sortable: false, align: "center" },
        { text: "IMG", value: "imagen", sortable: false, align: "center" },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "TP", value: "forma", sortable: false },
        { text: "MT", value: "material", sortable: false },
        { text: "GR", value: "giro", sortable: false },
        { text: "INT", align: "center", value: "interior", sortable: false },
        { text: "EXT", align: "center", value: "exterior", sortable: false },
        { text: "ALT", align: "center", value: "altura", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "VENTA", value: "precios_ventas", sortable: false },
      ],
      buscar_pedido: "",
      buscar_cliente: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      repuestos: [],

      brInterior: "",
      brExterior: "",
      brDescripcion: "",
      cat: { nombre: "", categorias: [{ text: "", value: "" }] },
      marc: { nombre: "", marca_repuestos: [{ text: "", value: "" }] },
      veh: { nombre: "", marca_vehiculos: [{ text: "", value: "" }] },

      vts: {
        nombre: "precio_publico",
        ventas: [
          { text: "Publico", value: "precio_publico" },
          { text: "Tienda", value: "precio_tiendas" },
          { text: "Distribuidor", value: "precio_distribuidor" },
        ],
      },
      idpedido: "",
      idpersona: "",
      clientes: [],

      igv: 0,
      tc: 0,

      descto_global: 0,
      descto_item_total: 0,
      subtotal_gravado: 0,
      igv_gravado: 0,
      importe_total: 0,

      selectedZoom: false,
      imagen_zoom: "", // variable imagen de zoom

      verNuevoPedido: 0,
      verRepuestos: 0,

      sucursal: "",
      cliente: "",
      fecha: "",
      moneda: "",

      comprobanteModal: 0,

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
      // Persona

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      stringPdf: "",
      blobPdf: "",

      editedIndex: -1,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Titulo de pedidos
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Pedido Cliente"
        : "Actualizar Pedido Cliente";
    },

    // Calcular la suma de detalle pedido

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          me.redondear(
            parseInt(
              me.detalles[i].cantidad == "" ? 0.0 : me.detalles[i].cantidad
            ) *
            parseFloat(
              me.detalles[i].precio_unit_venta == ""
                ? 0.0
                : me.detalles[i].precio_unit_venta
            ) -
            parseFloat(
              me.detalles[i].descto_item == ""
                ? 0.0
                : me.detalles[i].descto_item
            )
          );
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          me.redondear(
            parseFloat(
              me.detalles[i].descto_item == ""
                ? 0.0
                : me.detalles[i].descto_item
            )
          );
      }
      return resultado;
    },

    ...mapGetters(["esAdministrador", "esCaja"]),
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
    this.select();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    //#region LISTAR PEDIDOS
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Pedidos/ListarFiltroPedidoVentas", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar_pedido == "" ? "-" : me.buscar_pedido,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion

    irVentas(item) {
      this.$router.push({
        name: "ventas",
        params: {
          depedido: true,
          deidpersona: item.idpersona,
          deidpedido: item.idpedido,
        },
      });
    },

    //#region SELECT
    selectCliente(id) {
      let me = this;

      var clientesArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_cliente == ""
                  ? "-"
                  : me.buscar_cliente == null
                    ? "-"
                    : me.buscar_cliente,
              tipo_doc: "-",
            },
          })
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;

      var categoriasArray = [];
      axios
        .get("api/Categorias/Select")
        .then(function (response) {
          categoriasArray = response.data;
          categoriasArray.map(function (x) {
            me.cat.categorias.push({ text: x.nombre, value: x.nombre });
          });
        })
        .catch(function (error) { });

      var marca_repuestosArray = [];
      axios
        .get("api/Marca_Repuestos/Select")
        .then(function (response) {
          marca_repuestosArray = response.data;
          marca_repuestosArray.map(function (x) {
            me.marc.marca_repuestos.push({
              text: x.nombre,
              value: x.nombre,
            });
          });
        })
        .catch(function (error) { });

      var marca_vehiculosArray = [];
      axios
        .get("api/Marca_Vehiculos/Select")
        .then(function (response) {
          marca_vehiculosArray = response.data;
          marca_vehiculosArray.map(function (x) {
            me.veh.marca_vehiculos.push({
              text: x.nombre,
              value: x.nombre,
            });
          });
        })
        .catch(function (error) { });

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion SELECT

    //#region PEDIDO
    guardarPedido() {
      let me = this;
      // Validamos 
      if (me.detalles.length <= 0) {
        return me.SnackbarShow(
          "warning",
          "Ingrese al menos un repuesto al detalle."
        );
      }

      me.desabilitar = true;
      me.detalle_pedidos = [];
      me.convertirDetalle();

      if (me.editedIndex > -1) {
        // Código para editar Pedido
        axios
          .put("api/Pedidos/ActualizarPedidoCliente", {
            idpedido: me.idpedido,
            idsucursal: parseInt(me.usuario.idsucursal),
            idpersona: me.idpersona,
            idusuario: parseInt(me.usuario.idusuario),
            subtotal_gravado: parseFloat(me.subtotal_gravado),
            igv_gravado: parseFloat(me.igv_gravado),
            importe_total: parseFloat(me.importe_total),
            igv: me.igv,
            detalle_pedidos: me.detalle_pedidos,
          })
          .then(function (response) {
            me.SnackbarShow("success", response.data);
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      } else {
        // Código para guardar Pedido
        axios
          .post("api/Pedidos/CrearPedidoCliente", {
            idsucursal: parseInt(me.usuario.idsucursal),
            idpersona: me.idpersona,
            idusuario: parseInt(me.usuario.idusuario),
            subtotal_gravado: parseFloat(me.subtotal_gravado),
            igv_gravado: parseFloat(me.igv_gravado),
            importe_total: parseFloat(me.importe_total),
            igv: me.igv,
            detalle_pedidos: me.detalle_pedidos,
          })
          .then(function (response) {
            me.SnackbarShow("success", response.data);
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      }
    },

    convertirDetalle() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_pedidos.push({
          idrepuesto: me.detalles[i].idrepuesto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          unidad_medida: me.detalles[i].unidad_medida,
          cantidad: parseInt(me.detalles[i].cantidad),
          valor_unit_venta: me.redondear(
            parseFloat(me.detalles[i].precio_unit_venta) / (1 + me.igv)
          ),
          precio_unit_venta: parseFloat(me.detalles[i].precio_unit_venta),
          descto_item: parseFloat(
            me.detalles[i].descto_item == "" ? 0 : me.detalles[i].descto_item
          ),
          valor_venta: me.redondear(
            (parseInt(me.detalles[i].cantidad) *
              parseFloat(me.detalles[i].precio_unit_venta)) /
            (1 + me.igv)
          ),
          precio_venta: me.redondear(
            parseInt(me.detalles[i].cantidad) *
            parseFloat(me.detalles[i].precio_unit_venta)
          ),
          igv: me.igv * 100, // FE 18%
          codigo_repuesto: me.detalles[i].codigo_repuesto, // FE
          descripcion: me.detalles[i].descripcion,
          idprod_sunat: me.detalles[i].idprod_sunat, // FE
        });
      }
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    // Eliminar pedido
    deleteItemPedido(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar el pedido Nº " + item.idpedido + " ?"
      );
      let me = this;

      if (respta) {
        axios
          .delete("api/Pedidos/Eliminar/" + item.idpedido)
          .then((response) => {
            me.SnackbarShow("info", response.data);
            me.listar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      }
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false; // Habilitamos boton guardar pedido y venta
      this.listar();
      this.limpiar();
    },
    //#endregion PEDIDO

    //#region IMPRESIÓN
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Pedido Cliente Nro" + this.idpedido;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      // item espera el parametro
      // variable = valor
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.cliente = item.cliente;
      this.num_documento = item.num_documento;
      this.fecha = item.fecha;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;
      this.subtotal_gravado = item.subtotal_gravado;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;
      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESIÓN

    //#region DETALLES DE PEDIDOS
    // Ver pedido con detalles para modificar
    verDetallesPedidos(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpersona = item.idpersona;
      this.selectCliente(item.idpersona);
      this.listarDetallePedidos(item.idpedido);
      this.verNuevoPedido = 1;
      this.editedIndex = 1;
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_unit_venta
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              codigo_repuesto: x.codigo_repuesto, // FE
              //categoria: x.categoria,
              descripcion:
                x.categoria +
                " " +
                x.marca_repuesto +
                " " +
                x.descripcion.substr(0, 12),
              marca_repuesto: x.marca_repuesto,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              unidad_medida: x.unidad_medida,
              cantidad: x.cantidad,
              valor_unit_venta: x.valor_unit_venta.toFixed(2),
              precio_unit_venta: x.precio_unit_venta.toFixed(2),
              descto_item: x.descto_item.toFixed(2),
              valor_venta: x.valor_venta.toFixed(2),
              precio_venta: x.precio_venta.toFixed(2),
              idprod_sunat: x.idprod_sunat,
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion DETALLES DE PEDIDOS

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios
        .get(
          "api/Pedidos/BuscarCodigoBarraVenta/" +
          parseInt(me.usuario.idsucursal) +
          "/" +
          me.codigo_barra
        )
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
        })
        .catch(function (error) {
          //console.log(error);
          me.SnackbarShow(
            "warning",
            "No hay registro de compra para el repuesto"
          );
        });
    },
    // listar busqueda de repuesto
    listarRepuestoBuscar() {
      let me = this;

      axios
        .get("api/Pedidos/ListarRepuestoVenta", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
            interior: me.brInterior == "" ? "-" : me.brInterior,
            exterior: me.brExterior == "" ? "-" : me.brExterior,
            categoria:
              me.cat.nombre == ""
                ? "-"
                : me.cat.nombre == null
                  ? "-"
                  : me.cat.nombre,
            marca:
              me.marc.nombre == ""
                ? "-"
                : me.marc.nombre == null
                  ? "-"
                  : me.marc.nombre,
            vehiculo:
              me.veh.nombre == ""
                ? "-"
                : me.veh.nombre == null
                  ? "-"
                  : me.veh.nombre,
          },
        })
        .then(function (response) {
          me.repuestos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    agregarDetalle(data = []) {
      let me = this;

      if (me.encuentra(data["iddetalle_compra"])) {
        me.SnackbarShow("warning", "El repuesto ya fue agregado.");
      } else {
        me.detalles.push({
          idrepuesto: data["idrepuesto"],
          iddetalle_compra: data["iddetalle_compra"],
          codigo_repuesto: data["codigo_repuesto"], // FE
          //categoria: data["categoria"],
          //marca_repuesto: data["marca_repuesto"],
          descripcion:
            data["categoria"] +
            " " +
            data["marca_repuesto"] +
            " " +
            data["descripcion"],
          stock_actual: data["stock_actual"],
          unidad_medida: data["unidad_medida"],
          cantidad: 1,
          valor_unit_venta: 0,
          precio_unit_venta: data[me.vts.nombre].toFixed(2),
          descto_item: (0).toFixed(2),
          valor_venta: 0,
          precio_venta: 0,
          idprod_sunat: data["idprod_sunat"],
          // Repuesto
          precio_publico: data["precio_publico"].toFixed(2),
          precio_tiendas: data["precio_tiendas"].toFixed(2),
          precio_distribuidor: data["precio_distribuidor"].toFixed(2),
        });
      }
    },

    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].iddetalle_compra == id) {
          sw = 1;
        }
      }
      return sw;
    },

    mostrarRepuestos() {
      this.verRepuestos = 1;
    },

    ocultarRepuestos() {
      this.verRepuestos = 0;
      this.imagen_zoom = "";
    },
    //#endregion PRODUCTO

    /* PERSONA */
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;
      // El token se genero con el correo jfrain@hotmail.es de https://apisperu.com
      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº DNI.");
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.SnackbarShow(
                "info",
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion
              );
            } else {
              me.nombre = "";
              me.direccion = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº RUC.");
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Cliente",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectCliente(me.idpersona);
          me.desabilitarPer = false;
          me.SnackbarShow("success", response.data.mensaje);
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.SnackbarShow("error", error.response.data);
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    /* FIN PERSONA */

    limpiar() {
      this.igv = parseFloat(this.datos.igv); // El igv siempre tiene que tener valor y formateado

      this.idpedido = "";
      this.idpersona = "";
      this.num_documento = "";
      this.tipo_pedido = "";

      this.subtotal_gravado = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.detalles = [];
      this.detalle_pedidos = [];
      this.clientes = []; // limpiamos 

      this.codigo_barra = "";

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";

      this.editedIndex = -1;
    },

    crearPDF() {
      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U.M.", dataKey: "unidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "P.UNITARIO", dataKey: "precio_unit_venta" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          unidad_medida: this.detalles[i].unidad_medida,
          descripcion:
            this.detalles[i].codigo_repuesto +
            " " +
            this.detalles[i].descripcion,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format(
            "###,##0.00"
          ),
          subtotal: numeral(
            this.detalles[i].precio_unit_venta * this.detalles[i].cantidad
          ).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "Gravada S/",
                colSpan: 4,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IGV S/",
                colSpan: 4,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "Importe Total S/",
                colSpan: 4,
              },
              numeral(this.importe_total).format("###,##0.00"),
            ]
          );
        }
      }

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos cliente
          doc.text("Cliente: " + this.cliente, 40, 75);
          doc.text("Doc. N°: " + this.num_documento, 40, 90);

          doc.text("Sucursal: " + this.sucursal, 360, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            360,
            90
          );
          // Fin Datos cliente

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            "PEDIDO CLIENTE",
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            "N°" + this.idpedido,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "striped",
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 32, halign: "center" },
          unidad_medida: { cellWidth: 40, halign: "center" },
          descripcion: { cellWidth: "auto" },
          precio_unit_venta: { cellWidth: 50, halign: "right" },
          subtotal: { cellWidth: 50, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: "PedidoclienteNro" + this.idpedido + ".pdf",
      });
      addHeaders(doc);
      addFooters(doc);
      //doc.save("PedidoclienteNro" + this.idpedido + ".pdf");
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>