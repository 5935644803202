<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de sucursal y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="sucursales" :items-per-page="15" :search="buscar"
        dense class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">SUCURSALES</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" outlined dense
              label="Búsqueda" background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ma-2" v-on="on" small>Nuevo</v-btn>
              </template>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="nombre" label="Nombre"
                          :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 30) || 'Nombre debe tener entre 3 a 30 caracteres', Rules.unespacio]"
                          maxlength="30" background-color="blue lighten-5" prepend-inner-icon="store" class="mt-6"
                          v-uppercase></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 60) || 'Dirección debe tener entre 3 a 60 caracteres', Rules.unespacio]"
                          maxlength="60" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                          v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                          background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                          :rules="[v => !!v || 'Requerido', v => v.length <= 20 || 'El Telefono no debe tener mas de 20 caracteres', Rules.unespacio]"
                          maxlength="20" background-color="blue lighten-5" prepend-inner-icon="phone" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="email" label="Email"
                          :rules="[v => /.+@.+\..+/.test(v) || 'El email debe ser válido', Rules.sinespacio]"
                          maxlength="50" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase>
                        </v-text-field>
                      </v-col>
                      <!-- Mensaje error sucursal -->
                      <v-col cols="12" v-if="mensajeSucursal != ''">
                        <v-alert text dense type="error">{{ mensajeSucursal }}</v-alert>
                      </v-col>
                      <!-- Mensaje error sucursal -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="editItem(item)">edit</v-icon>
          </v-btn>
          <v-btn small icon>
            <v-icon color="red darken-2" @click="deleteItem(item)">delete_forever</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- Fin Listado de sucursal y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      sucursales: [],
      dialog: false,
      headers: [
        { text: "CdSc", value: "idsucursal", sortable: false },
        { text: "EMPRESA", value: "empresa", sortable: false },
        { text: "SUCURSAL", value: "nombre", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "TELEFONO", value: "telefono", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "OPCIONES", value: "opciones", sortable: false },
      ],
      buscar: "",
      buscar_ubigeo: "",
      editedIndex: -1,
      idsucursal: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      nombre: "",
      direccion: "",
      telefono: "",
      email: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajeSucursal: "",

      desabilitar: false,

      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },
      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Sucursal" : "Actualizar Sucursal";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["cargarSettings"]),

    listar() {
      let me = this;

      me.loading = true;
      axios
        .get("api/Sucursales/Listar")
        .then(function (response) {
          //console.log(response);
          me.sucursales = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        axios
          .put("api/Sucursales/Actualizar", {
            idsucursal: me.idsucursal,
            idubigeo: me.idubigeo,
            nombre: me.nombre,
            direccion: me.direccion,
            telefono: me.telefono,
            email: me.email,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.cargarSettings();
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeSucursal = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        axios
          .post("api/Sucursales/Crear", {
            idubigeo: me.idubigeo,
            nombre: me.nombre,
            direccion: me.direccion,
            telefono: me.telefono,
            email: me.email,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.cargarSettings();
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajeSucursal = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la Sucursal " + item.nombre + "?"
      );
      if (respta) {
        let me = this;
        axios
          .delete("api/Sucursales/Eliminar/" + item.idsucursal)
          .then((response) => {
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            this.listar();
          })
          .catch(function (error) {
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = error.response.data;
          });
      }
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeSucursal = "";
      }, 3000);
    },

    editItem(item) {
      this.idsucursal = item.idsucursal;
      this.idubigeo = item.idubigeo;
      this.selectUbigeo(item.idubigeo);
      this.nombre = item.nombre;
      this.direccion = item.direccion;
      this.telefono = item.telefono;
      this.email = item.email;
      this.editedIndex = 1;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idsucursal = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";
      this.mensajeSucursal = "";
      this.editedIndex = -1;
    },
  },
};
</script>