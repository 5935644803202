<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="cabeceraComprasVentas" :items="movimientoPersonas" :items-per-page="15"
        dense class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">COMPRAS/VENTAS</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda de persona -->
              <v-spacer />
              <v-autocomplete outlined dense @keyup="selectPersona()" :search-input.sync="buscar_persona"
                v-model="idpersona" :items="personas" label="Persona" :rules="[v => !!v || 'Persona es requerido']"
                background-color="blue lighten-5" clearable hide-details class="mr-1"></v-autocomplete>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true :  false">
                <template v-slot:activator="{ on }">
                  <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                    background-color="blue lighten-5" hide-details class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length==2 ? menuFecha=false : menuFecha=true" range>
                </v-date-picker>
              </v-menu>
              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="info" @click="crearPDF()" :disabled="desabilitar || !isValid">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Formulario Busqueda de persona -->
            </v-toolbar>
          </v-form>
        </template>

        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.moneda="{ item }">
          <span>{{ item.moneda=='S'?'S/':'$' }}</span>
        </template>
        <!-- Ventas -->
        <template v-slot:item.venta="{ item }">
          <div class="text-right">
            <span>{{ item.venta | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.cuentacobrada="{ item }">
          <div class="text-right">
            <span>{{ item.cuentacobrada | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.efectivo="{ item }">
          <div class="text-right">
            <span>{{ item.efectivo | formatearNumero }}</span>
          </div>
        </template>
        <!-- Compras -->
        <template v-slot:item.compra="{ item }">
          <div class="text-right">
            <span>{{ item.compra | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.cuentapagada="{ item }">
          <div class="text-right">
            <span>{{ item.cuentapagada | formatearNumero }}</span>
          </div>
        </template>
      </v-data-table>
      <!-- Listado de personas y opciones de titulo, busqueda, nuevo, etc -->
      <!-- Fin Listado de personas y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";
import moment from "moment";
import numeral from "numeral";

export default {
  data() {
    return {
      movimientoPersonas: [],
      dialog: false,
      cabeceraComprasVentas: [
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        //{ text: "CdMv", value: "idmovimiento" },
        { text: "Comprobante", value: "comprobante", sortable: false  },
        { text: "Fecha", value: "fecha", sortable: false  },
        { text: "Detalle", value: "movimiento", sortable: false  },
        { text: "", value: "moneda", sortable: false  },
        { text: "Compra", value: "compra", sortable: false  },
        { text: "C/P", value: "cuentapagada", sortable: false  },
        { text: "Venta", value: "venta", sortable: false  },
        { text: "C/C", value: "cuentacobrada", sortable: false  },
        { text: "T.Pago", value: "tipo_pago", sortable: false  },
        { text: "Efectivo", value: "efectivo", sortable: false  },
        { text: "NumOper", value: "num_operacion", sortable: false  },
      ],
      buscar_persona: "",
      idpersona: "",
      personas: [],

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    //this.listar();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Movimientos/ReporteMovimientoCompraVenta", {
          params: {
            idpersona: parseInt(me.idpersona),
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.movimientoPersonas = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.movimientoPersonas);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },

    selectPersona() {
      let me = this;

      var personasArray = [];

      axios
        .get("api/Personas/ListarPersonasReport", {
          params: {
            buscar: me.buscar_persona == "" ? "-" : me.buscar_persona,
          },
        })
        .then(function (response) {
          personasArray = response.data;
          personasArray.map(function (x) {
            me.personas.push({
              text: x.num_documento + " " + x.nombre,
              value: x.idpersona,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "CdSc", dataKey: "idsucursal" },
        { header: "CdUs", dataKey: "idusuario" },
        { header: "CdMv", dataKey: "idmovimiento" },
        { header: "Comprobante", dataKey: "comprobante" },
        { header: "Fecha", dataKey: "fecha" },
        { header: "Detalle", dataKey: "movimiento" },
        { header: "", dataKey: "moneda" },
        { header: "Compra", dataKey: "compra" },
        { header: "C.Pagada", dataKey: "cuentapagada" },
        { header: "Venta", dataKey: "venta" },
        { header: "C.Cobrada", dataKey: "cuentacobrada" },
        { header: "T.Pago", dataKey: "tipo_pago" },
        { header: "Efectivo", dataKey: "efectivo" },
        { header: "NumOper", dataKey: "num_operacion" },
      ];
      var body = [];
      var foot = [];

      var totalcompra = 0.0;
      var totalcuentapagada = 0.0;
      var totalventa = 0.0;
      var totalcuentacobrada = 0.0;

      for (var i = 0; i < this.movimientoPersonas.length; i++) {
        totalcompra =
          totalcompra + parseFloat(this.movimientoPersonas[i].compra);
        totalcuentapagada =
          totalcuentapagada +
          parseFloat(this.movimientoPersonas[i].cuentapagada);

        totalventa = totalventa + parseFloat(this.movimientoPersonas[i].venta);
        totalcuentacobrada =
          totalcuentacobrada +
          parseFloat(this.movimientoPersonas[i].cuentacobrada);
        body.push({
          idsucursal: this.movimientoPersonas[i].idsucursal,
          idusuario: this.movimientoPersonas[i].idusuario,
          idmovimiento: this.movimientoPersonas[i].idmovimiento,
          comprobante: this.movimientoPersonas[i].comprobante,
          fecha: moment(this.movimientoPersonas[i].fecha).format("DD/MM/YYYY"),
          movimiento: this.movimientoPersonas[i].movimiento,
          moneda: this.movimientoPersonas[i].moneda == "S" ? "S/" : "$",
          compra: numeral(this.movimientoPersonas[i].compra).format(
            "###,##0.00"
          ),
          cuentapagada: numeral(this.movimientoPersonas[i].cuentapagada).format(
            "###,##0.00"
          ),
          venta: numeral(this.movimientoPersonas[i].venta).format("###,##0.00"),
          cuentacobrada: numeral(
            this.movimientoPersonas[i].cuentacobrada
          ).format("###,##0.00"),
          tipo_pago: this.movimientoPersonas[i].tipo_pago,
          efectivo: numeral(this.movimientoPersonas[i].efectivo).format(
            "###,##0.00"
          ),
          num_operacion: this.movimientoPersonas[i].num_operacion,
        });

        if (i === this.movimientoPersonas.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 7,
            },
            numeral(totalcompra).format("###,##0.00"),
            numeral(totalcuentapagada).format("###,##0.00"),
            numeral(totalventa).format("###,##0.00"),
            numeral(totalcuentacobrada).format("###,##0.00"),
          ]);
        }
      }
      //console.log(body);

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("MOVIMIENTOS PERSONA", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.setFontSize(8);
          doc.text("NOMBRE: " + this.buscar_persona.substr(0, 69), 40, 72);
          doc.text(
            "DESDE: " + moment(this.dates[0]).format("DD/MM/YYYY"),
            40,
            86
          );
          doc.text(
            "HASTA: " + moment(this.dates[1]).format("DD/MM/YYYY"),
            240,
            86
          );
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            580,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("l", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          idsucursal: { cellWidth: 30, halign: "right" },
          idusuario: { cellWidth: 30, halign: "right" },
          idmovimiento: { cellWidth: 30, halign: "right" },
          comprobante: { cellWidth: 60, halign: "center" },
          fecha: { cellWidth: 45, halign: "center" },
          movimiento: { cellWidth: "auto" },
          moneda: { cellWidth: 17, halign: "center" },
          compra: { cellWidth: 53, halign: "right" },
          cuentapagada: { cellWidth: 53, halign: "right" },
          venta: { cellWidth: 53, halign: "right" },
          cuentacobrada: { cellWidth: 53, halign: "right" },
          tipo_pago: { cellWidth: 37 },
          efectivo: { cellWidth: 53, halign: "right" },
          num_operacion: { cellWidth: 53, halign: "center" },
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("MovimientosPersona.pdf");
    },
  },
};
</script>

