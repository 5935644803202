<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de personas y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="personas" :items-per-page="15" dense
        class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">CLIENTES/PROVEEDORES</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <!-- Impresion personas -->
            <v-btn fab x-small dark color="info" @click="crearPDF()" class="mr-1">
              <v-icon>print</v-icon>
            </v-btn>
            <!-- Fin Impresion personas -->
            <v-spacer />
            <!-- Formulario busqueda de persona -->
            <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" outlined dense
              label="Búsqueda" @keyup="listar()" background-color="blue lighten-5" single-line hide-details>
            </v-text-field>
            <!-- Fin Formulario busqueda de persona -->
            <v-spacer />
            <!-- Modal crear y modificar persona -->
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" class="ma-2" v-on="on" small>Nuevo</v-btn>
              </template>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>{{ formTitle }} {{ tipo_persona }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-4">
                      <v-col cols="6" sm="3">
                        <v-select outlined dense v-model="tipo_persona" :items="tipo_personas" label="Tipo Persona"
                          :rules="[v => !!v || 'Tipo persona requerido']" background-color="blue lighten-5"
                          class="mr-1"></v-select>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-select outlined dense v-model="idtipo_documento" :items="tipo_documentos"
                          label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                          @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                      </v-col>
                      <v-col cols="10" sm="5">
                        <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                          :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                          :maxlength="idtipo_documento == 1 ? 8 : 11"
                          :disabled="idtipo_documento == 1 ? false : idtipo_documento == 6 ? false : true"
                          background-color="blue lighten-5" v-mask-number></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="1">
                        <div class="text-center">
                          <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                            :disabled="(idtipo_documento == 1 && num_documento.length == 8 ? false : idtipo_documento == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                            @click="extraerDocumento(num_documento)">
                            <v-icon dark>search</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="nombre"
                          :label="idtipo_documento == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                          :rules="[v => !!v || 'Nombres requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombres debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                          maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                          v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                          background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                          :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                          maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="email" label="Email"
                          :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                          maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase
                          class="mr-1"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                          :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                          maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                          v-uppercase></v-text-field>
                      </v-col>

                      <!-- Mensaje error persona -->
                      <v-col cols="12" v-if="mensajePersona != ''">
                        <v-alert text dense type="error">{{ mensajePersona }}</v-alert>
                      </v-col>
                      <!-- Mensaje error persona -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear y modificar persona -->
          </v-toolbar>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="editItem(item)">edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- Fin Listado de personas y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";

export default {
  data() {
    return {
      personas: [],
      dialog: false,
      headers: [
        //{ text: "CdPer", value: "idpersona", sortable: false },
        { text: "TIPO", value: "tipo_persona", sortable: false },
        { text: "NOMBRES", value: "nombre", sortable: false },
        //{ text: "DOC", value: "idtipo_documento" },
        { text: "DOCUMENTO", value: "num_documento", sortable: false },
        //{ text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "TELÉFONO", value: "telefono", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "OPC.", value: "opciones", sortable: false },
      ],
      buscar: "",
      buscar_ubigeo: "",

      editedIndex: -1,
      idpersona: "",
      tipo_persona: "",
      tipo_personas: ["Cliente", "Proveedor", "Transportista", "Conductor"],
      nombre: "",
      idtipo_documento: "",
      tipo_documentos: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajePersona: "",

      desabilitar: false,
      desabilitarBuscar: false,

      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["logo"]),

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Actualizar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.select();
  },
  methods: {
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Personas/ListarPersonasFiltro", {
          params: {
            buscar: me.buscar == "" ? "-" : me.buscar,
          },
        })
        .then(function (response) {
          //console.log(response);
          me.personas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put("api/Personas/Actualizar", {
            idpersona: me.idpersona,
            tipo_persona: me.tipo_persona,
            nombre: me.nombre,
            idtipo_documento: me.idtipo_documento,
            num_documento: me.num_documento,
            idubigeo: me.idubigeo,
            direccion: me.direccion,
            telefono: me.telefono,
            email: me.email,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajePersona = error.response.data;
            me.limpiarAlertaError();
          });
      } else {
        //Código para guardar
        axios
          .post("api/Personas/Crear", {
            tipo_persona: me.tipo_persona,
            nombre: me.nombre,
            idtipo_documento: me.idtipo_documento,
            num_documento: me.num_documento,
            idubigeo: me.idubigeo,
            direccion: me.direccion,
            telefono: me.telefono,
            email: me.email,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "success";
            me.alerta.text = response.data.mensaje;
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.mensajePersona = error.response.data;
            me.limpiarAlertaError();
          });
      }
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajePersona = "";
      }, 3000);
    },

    editItem(item) {
      this.idpersona = item.idpersona;
      this.tipo_persona = item.tipo_persona;
      this.nombre = item.nombre;
      this.idtipo_documento = item.idtipo_documento;
      this.num_documento = item.num_documento;
      this.idubigeo = item.idubigeo;
      this.selectUbigeo(item.idubigeo);
      this.direccion = item.direccion;
      this.telefono = item.telefono;
      this.email = item.email;
      this.editedIndex = 1;
      this.dialog = true;
    },

    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.nombres +
                " " +
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno;
            } else {
              me.nombre = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.mensajePersona = "Error en el Nº DNI.";
            me.limpiarAlertaError();
          });
      } else if (me.idtipo_documento == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text =
                "Contribuyente: Estado " +
                data.estado +
                " y Condición " +
                data.condicion;
            } else {
              me.nombre = "";
              me.direccion = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.mensajePersona = "Error en el Nº RUC.";
            me.limpiarAlertaError();
          });
      }
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idpersona = "";
      this.tipo_persona = "";
      this.nombre = "";
      this.idtipo_documento = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.direccion = "";
      this.telefono = "";
      this.email = "";

      this.mensajePersona = "";
      this.editedIndex = -1;
    },

    crearPDF() {
      var columns = [
        { header: "CdPv", dataKey: "idpersona" },
        { header: "PERSONA", dataKey: "tipo_persona" },
        { header: "NOMBRE", dataKey: "nombre" },
        { header: "TIPO", dataKey: "idtipo_documento" },
        { header: "DOCUMENTO", dataKey: "num_documento" },
        { header: "DIRECCIÓN", dataKey: "direccion" },
        { header: "TELEFONO", dataKey: "telefono" },
        { header: "EMAIL", dataKey: "email" },
      ];
      var body = [];

      this.personas.map(function (x) {
        body.push({
          idpersona: x.idpersona,
          tipo_persona: x.tipo_persona,
          nombre: x.nombre,
          idtipo_documento: x.idtipo_documento,
          num_documento: x.num_documento,
          direccion: x.direccion,
          telefono: x.telefono,
          email: x.email,
        });
      });
      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO DE PERSONAS", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      // Configurando hoja
      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("l", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          idpersona: { cellWidth: 30 },
          tipo_persona: { cellWidth: 50 },
          nombre: { cellWidth: 150 },
          idtipo_documento: { cellWidth: 30 },
          num_documento: { cellWidth: 65 },
          direccion: { cellWidth: "auto" },
          telefono: { cellWidth: 80 },
          email: { cellWidth: 100 },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Personas.pdf");
    },
  },
};
</script>