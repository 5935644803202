<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" v-if="estaActivo" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list nav dense>
        <template v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
          <v-list-item :to="{ name: 'home' }">
            <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
        </template>
        <!-- Menu Ventas -->
        <template v-if="esAdministrador || esVendedor || esCaja">
          <v-list-group no-action color="green">
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="green">shopping_cart</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'ventas' }" color="green">
              <v-list-item-content>
                <v-list-item-title>Listado Ventas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="green">article</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'pedido_clientes' }">
              <v-list-item-content>
                <v-list-item-title>Pedido Clientes</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>assignment</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cuentas_x_cobrar' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Cuentas x Cobrar</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-group sub-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Resúmenes y Anulaciones</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item :to="{ name: 'resumen_diario' }" v-if="esAdministrador || esCaja">
                <v-list-item-content>
                  <v-list-item-title>Resúmenes</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>fact_check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'baja' }" v-if="esAdministrador || esCaja" color="error">
                <v-list-item-content>
                  <v-list-item-title>Anulaciones</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="error">dangerous</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </template>
        <!-- Fin Menu Ventas -->

        <!-- Menu Compras -->
        <template v-if="esAdministrador || esAlmacenero || esCaja">
          <v-list-group no-action color="brown">
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="brown">local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Compras</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'compras' }" v-if="esAdministrador || esCaja" color="brown">
              <v-list-item-content>
                <v-list-item-title>Listado Compras</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="brown">article</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'pedido_proveedores' }" v-if="esAdministrador || esAlmacenero || esCaja">
              <v-list-item-content>
                <v-list-item-title>Pedido Proveedores</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>assignment</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cuentas_x_pagar' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Cuentas x Pagar</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Menu Compras -->

        <!-- Menu Personas -->
        <template v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
          <v-list-item :to="{ name: 'personas' }">
            <v-list-item-icon>
              <v-icon color="teal darken-3">home_repair_service</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Clientes/Proveedores</v-list-item-title>
          </v-list-item>
        </template>
        <!-- Fin Menu Personas -->

        <!-- Menu Repuestos -->
        <template v-if="esAdministrador || esAlmacenero || esCaja">
          <v-list-group no-action color="red darken-2">
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="red darken-2">home_work</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Repuestos</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'repuestos' }" color="red darken-2">
              <v-list-item-content>
                <v-list-item-title>Listado Repuestos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>apps</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'categorias' }">
              <v-list-item-content>
                <v-list-item-title>Categorías</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>toc</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cambioprecios' }">
              <v-list-item-content>
                <v-list-item-title>Cambio de Precios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>price_change</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-group sub-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Características</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item :to="{ name: 'marca_repuestos' }">
                <v-list-item-content>
                  <v-list-item-title>Marca Repuesto</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>bookmarks</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'marca_vehiculos' }">
                <v-list-item-content>
                  <v-list-item-title>Marca Vehículo</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>commute</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'tipos' }">
                <v-list-item-content>
                  <v-list-item-title>Tipo</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>all_out</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'giros' }">
                <v-list-item-content>
                  <v-list-item-title>Giro</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>autorenew</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'materiales' }">
                <v-list-item-content>
                  <v-list-item-title>Material</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>blur_on</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </template>
        <!-- Fin Menu Repuestos -->

        <!-- Menu Inventario -->
        <template v-if="esAdministrador || esAlmacenero">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="yellow darken-1">inventory</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Inventario</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'traslado' }">
              <v-list-item-content>
                <v-list-item-title>Traslados</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>sync_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'ajustesstock' }">
              <v-list-item-content>
                <v-list-item-title>Ajustes Stock</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'stock_repuestos' }">
              <v-list-item-content>
                <v-list-item-title>Stock Repuestos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Menu Inventario -->

        <!-- Menu Guías de Remisión -->
        <template v-if="esAdministrador || esCaja">
          <v-list-item :to="{ name: 'guias_remision' }">
            <v-list-item-icon>
              <v-icon color="pink">receipt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Guías de Remisión</v-list-item-title>
          </v-list-item>
        </template>
        <!-- Fin Menu Guías de Remisión -->

        <!-- Menu Caja -->
        <template v-if="esAdministrador || esCaja">
          <v-list-group no-action color="purple darken-2">
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="purple darken-2">money</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Caja</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'caja' }" color="purple darken-2">
              <v-list-item-content>
                <v-list-item-title>Listado Caja</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="purple darken-2">attach_money</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cierre_diario' }">
              <v-list-item-content>
                <v-list-item-title>Cierre Diario</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>power_off</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Menu Caja -->

        <!-- Menu Mantenimiento -->
        <template v-if="esAdministrador">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="orange darken-2">settings</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Mantenimiento</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'empresas' }">
              <v-list-item-content>
                <v-list-item-title>Empresa</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>home_work</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'sucursales' }">
              <v-list-item-content>
                <v-list-item-title>Sucursales</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'usuarios' }">
              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>supervised_user_circle</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'roles' }">
              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>verified_user</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'unidad_medidas' }">
              <v-list-item-content>
                <v-list-item-title>Unidad Medida</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>compare_arrows</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Menu Mantenimiento -->

        <!-- Menu Reportes -->
        <template v-if="estaActivo">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="blue">pie_chart</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Reportes</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="esAdministrador || esCaja">
              <v-list-item :to="{ name: 'movimiento_diario' }">
                <v-list-item-content>
                  <v-list-item-title>Movimiento Diario</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
            </template>
            <v-list-item :to="{ name: 'venta_usuarios' }" v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
              <v-list-item-content>
                <v-list-item-title>Ventas Usuario</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'compras_ventas' }" v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
              <v-list-item-content>
                <v-list-item-title>Mov. x Persona</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider />
            <template v-if="esAdministrador || esAlmacenero">
              <v-list-item :to="{ name: 'compras_detalladas' }">
                <v-list-item-content>
                  <v-list-item-title>Compras Detalladas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <template v-if="esAdministrador">
              <v-list-item :to="{ name: 'ventas_detalladas' }">
                <v-list-item-content>
                  <v-list-item-title>Ventas Detalladas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <template v-if="esAdministrador || esCaja">
              <v-list-item :to="{ name: 'detalle_cuentas' }">
                <v-list-item-content>
                  <v-list-item-title>Detalle de Cuentas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
            </template>
            <template v-if="esAdministrador">
              <v-list-item :to="{ name: 'movimientos_tipo' }">
                <v-list-item-content>
                  <v-list-item-title>Tipo Movimiento</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <!-- Fin Menu Reportes -->

        <!-- Menu Resumenes -->
        <template v-if="esAdministrador || esCaja">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon color="cyan darken-2">chrome_reader_mode</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Resúmenes</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'estadistica_mensual' }" v-if="esAdministrador">
              <v-list-item-content>
                <v-list-item-title>Estadística Mensual</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'contabilidadresumen' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Menu Resumenes -->

        <!-- Menu Acerca -->
        <template v-if="estaActivo">
          <v-list-item :to="{ name: 'about' }">
            <v-list-item-icon>
              <v-icon color="light-blue accent-2">info</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Acerca de</v-list-item-title>
          </v-list-item>
        </template>
        <!-- Fin Menu Acerca -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark dense
      :src="require('./assets/banner.png')" v-if="estaActivo" flat>
      <v-toolbar-title style="width: 300px" class="ml-0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <router-link to="/">
          <v-avatar tile size="30px" class="mx-1">
            <v-img :src="require('./assets/logoempresa.png')" alt="Vuetify" width="20" height="20" />
          </v-avatar>
        </router-link>
        <span class="hidden-sm-and-down">SisBIG</span>
      </v-toolbar-title>

      <!-- Accesos directos compras, ventas y caja -->
      <template v-if="esAdministrador || esCaja || esVendedor">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mx-1">
              <v-icon @click="irPedidoCliente" v-if="esVendedor">shopping_cart</v-icon>
              <v-icon @click="irVenta" v-else-if="esAdministrador || esCaja">shopping_cart</v-icon>
            </v-btn>
          </template>
          <span>Nueva Venta</span>
        </v-tooltip>
      </template>
      <template v-if="esAdministrador || esCaja || esAlmacenero">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon @click="irPedidoProveedor">local_shipping</v-icon>
            </v-btn>
          </template>
          <span>Pedido Proveedor</span>
        </v-tooltip>
      </template>
      <template v-if="esAdministrador || esCaja">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon @click="irCaja">money</v-icon>
            </v-btn>
          </template>
          <span>Caja</span>
        </v-tooltip>
      </template>
      <v-spacer />
      <strong class="hidden-sm-and-down">T/C {{ parseFloat(datos.tc).toFixed(2) }} - IGV {{ parseFloat(datos.igv) * 100 +
        "%" }}</strong>
      <v-spacer />
      <!-- Fin Accesos directos compras, ventas y caja -->
      <div class="flex-grow-1"></div>

      <!-- Menu para notificaciones -->
      <v-menu bottom left v-model="menuNotificacion" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="esAdministrador || esCaja">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mr-2" @click="cargarNotificaciones()">
            <v-badge :content="notificaciones.length" :value="notificaciones.length" color="green" overlap>
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon large color="blue darken-3">payments</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>CUENTAS POR PAGAR Y COBRAR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(notificaciones, i) in notificaciones" :key="i">
            <v-list-item-icon>
              <v-icon :color="notificaciones.fecha_vencimiento < fecha_actual ? 'error' : 'warning'"
                @click="verNotificacion(notificaciones.idtipo_movimiento), (menuNotificacion = false)">{{
                  notificaciones.idtipo_movimiento == 1 ? "remove_circle" : "add_circle"
                }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ notificaciones.persona.substr(0, 22) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ notificaciones.moneda == "S" ? "S/" : "$" }} {{
                notificaciones.importe |
                formatearNumero
              }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Fin Menu para notificaciones -->

      <!-- Menu para cerrar sesion -->
      <v-menu bottom left v-model="menuSesion" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="cargarSettings">
            <v-avatar size="35px" item>
              <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="40px" item>
                  <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ usuario.rol }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.correo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ datos.sucursal }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon>send</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text">SUNAT {{ datos.cpe }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>card_membership</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text">VCTO {{ datos.vcto_certificado }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn small block color="error" @click="salir(), (menuSesion = false)">Cerrar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- Fin Menu para cerrar sesion -->
    </v-app-bar>

    <!-- Contenido -->
    <v-main>
      <v-container fluid fill-height>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <!-- Fin Contenido -->
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex"; // Importamos para llamar a los elementos salir, autologin, usuario, etc

export default {
  name: "App",
  data() {
    return {
      headers: [
        { text: "NOMBRES", value: "persona" },
        { text: "IMPORTE", value: "importe" },
      ],
      drawer: null,
      menuSesion: false,
      menuNotificacion: false,
      fecha_actual: moment().format("YYYY-MM-DD"),
    };
  },

  computed: {
    ...mapGetters([
      "estaActivo",
      "esAdministrador",
      "esAlmacenero",
      "esVendedor",
      "esCaja",
    ]), // Llamamos a getters

    ...mapState(["usuario", "datos", "avatar", "notificaciones"]), // Llamamos a state para usuario, avatar y notificaciones
  },

  created() {
    this.autoLogin();
  },

  methods: {
    ...mapActions([
      "salir",
      "autoLogin",
      "cargarNotificaciones",
      "cargarSettings",
    ]),

    irPedidoProveedor() {
      this.$router.push({ name: "pedido_proveedores" }).catch(() => { });
    },
    irPedidoCliente() {
      this.$router.push({ name: "pedido_clientes" }).catch(() => { });
    },
    irCaja() {
      this.$router.push({ name: "caja" }).catch(() => { });
    },
    irVenta() {
      this.$router
        .push({
          name: "ventas",
          params: {
            adventa: true,
          },
        })
        .catch(() => { });
    },

    verNotificacion(idtipo_movimiento) {
      let me = this;
      if (idtipo_movimiento == 1) {
        me.$router
          .push({
            name: "cuentas_x_pagar",
            params: {
              cuentaxpagar: true,
            },
          })
          .catch(() => { });
      } else {
        me.$router
          .push({
            name: "cuentas_x_cobrar",
            params: {
              cuentaxcobrar: true,
            },
          })
          .catch(() => { });
      }
    },
  },
};
</script>
