<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de Guias -->
      <v-data-table :loading="loading" :headers="cabeceraListarGuias" :items="guiasRemision" :items-per-page="15"
        item-key="idmovimiento" show-expand class="elevation-3" v-if="verNuevaGuiaRemision == 0" dense>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">GUÍAS DE REMISIÓN</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de venta -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar()"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
              min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                @change="listar()" range></v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de venta -->
            <v-btn @click="nuevaGuiaRemision()" color="primary" class="ma-2" small>Nuevo</v-btn>
            <!-- Falta desarrollar nueva guia el problema es con guias que no tienen VENTAS en los detalles de compras - ver como solucionar -->
          </v-toolbar>
        </template>
        <!-- Opciones para la lista de guias -->
        <template v-slot:item.numero="{ item }">
          <template v-if="item.idtipo_comprobante != '00'">
            <strong>{{ item.serie_comprobante + "-" + item.num_comprobante }}</strong>
          </template>
          <template v-else>
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>
        </template>
        <template v-slot:item.cliente="{ item }">
          <span>{{ (item.cliente).substr(0, 20) }}</span>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.fecha_comprobante="{ item }">
          <span>{{ item.fecha_comprobante | formatearFecha }}</span>
        </template>

        <!-- Estado de los comprobantes -->
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Aceptado'">
            <strong class="green--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Enviado'">
            <span class="blue--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por enviar'">
            <span class="brown--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por anular'">
            <strong class="orange--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Rechazado'">
            <strong class="red--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Anulado'">
            <strong class="red--text">{{ item.estado }}</strong>
          </template>
          <template v-else>
            <span>{{ item.estado }}</span>
          </template>
        </template>
        <!-- Fin Estado de los comprobantes -->
        <!-- Descarga de XML y CDR -->
        <template v-slot:item.descargas="{ item }">
          <template>
            <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
          </template>
          <template v-if="item.estado == 'Aceptado' | item.estado == 'Anulado'">
            <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
          </template>
        </template>
        <!-- Fin Descarga de XML y CDR -->
        <!-- Opciones de impresion, anular y cpe-->
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <!-- Menu para mas opciones de FE -->
          <template v-if="item.estado == 'Creado'">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small icon>
                  <v-icon v-on="on" color="blue darken-2">more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <!-- Reenviar Sunat -->
                <v-list-item link>
                  <v-list-item-content>
                    <v-list-item-title class="blue--text" @click="reenviarSunat(item)">Enviar a la SUNAT
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Reenviar Sunat -->
              </v-list>
            </v-menu>
          </template>
          <!-- Fin Menu para mas opciones de FE -->
        </template>
        <!-- Fin Opciones de impresion, anular y cpe-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Registrado por:</strong>
            {{ item.usuario }}
            <br />
            <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
            {{ item.msj_cdr }}
          </td>
        </template>
      </v-data-table>
      <!--Fin Listado de Guias -->

      <!-- Ventana crear nueva guia de remisión -->
      <v-card v-if="verNuevaGuiaRemision == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>Nueva Guia de Remisión</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevaGuiaRemision">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" md="2" sm="3">
                <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field outlined dense v-model="fecha_comprobante_formateada" label="Fecha traslado" readonly
                      v-on="on" background-color="blue lighten-5" prepend-inner-icon="event" class="mr-1">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                    @change="fechaseleccionada(fecha_comprobante)"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="7" sm="6">
                <v-autocomplete outlined dense @keyup="selectCliente()" @change="changeCliente"
                  :search-input.sync="buscar_cliente" v-model="idpersona" :items="clientes" label="Cliente"
                  :rules="[v => !!v || 'El cliente es requerido']" background-color="blue lighten-5" clearable
                  class="mr-2" :disabled="deVenta == true" append-outer-icon="person_add"
                  @click:append-outer="mostrarPersona"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-select outlined dense v-model="modo_traslado" :items="modo_traslados" label="Modo traslado"
                  :rules="[v => !!v || 'El modo de traslado es requerido']" background-color="blue lighten-5"
                  @change="modo_traslado == '02' ? idtransportista = '' : idconductor = '', placa = ''" class="mr-1">
                </v-select>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-select outlined dense v-model="idmotivo_traslado" :items="motivo_traslados"
                  label="Motivo de Traslado" :rules="[v => !!v || 'Motivo requerido']" background-color="blue lighten-5"
                  @change="idmotivo_traslado == '08' ? nro_contenedor : nro_contenedor = '', descripcionMotivo(idmotivo_traslado)"
                  class="mr-1"></v-select>
              </v-col>
              <v-col cols="12" md="8" sm="8">
                <v-text-field outlined dense v-model.trim="motivo" label="Descripción de motivo de traslado"
                  :rules="[v => (v.length >= 5 && v.length <= 55) || 'El motivo puede tener de 5 a 55 caracteres', Rules.unespacio]"
                  maxlength="55" background-color="blue lighten-5" v-uppercase></v-text-field>
              </v-col>
              <v-col cols="4" md="2" sm="2">
                <v-text-field outlined dense v-model="peso_total" label="Peso total (KG)"
                  :rules="[v => (!!v && v > 0) || 'Peso total es requerido']" maxlength="6"
                  background-color="blue lighten-5" class="mr-1" v-mask-decimal.us="2"></v-text-field>
              </v-col>
              <v-col cols="4" md="2" sm="2">
                <v-text-field outlined dense v-model="nro_paquetes" label="Nro paquetes"
                  :rules="[v => (!!v && v > 0) || 'Nro paquetes es requerido']" maxlength="6"
                  background-color="blue lighten-5" class="mr-1 center-input" v-mask-number></v-text-field>
              </v-col>
              <v-col cols="12" md="8" sm="8">
                <v-text-field outlined dense v-model.trim="observacion" label="Observación"
                  :rules="[v => (v.length <= 100) || 'La observacion puede tener hasta 100 caracteres', Rules.unespacio]"
                  maxlength="100" background-color="blue lighten-5" v-uppercase></v-text-field>
              </v-col>

              <v-col cols="12" md="5" sm="4">
                <v-autocomplete outlined dense @keyup="selectUbigeoPartida()" :search-input.sync="buscar_ubigeo_partida"
                  v-model="idubigeo_partida" :items="ubigeos_partida" label="Ubigeo partida"
                  :rules="[v => !!v || 'Ubigeo requerido']" background-color="blue lighten-5" clearable class="mr-1">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="7" sm="8">
                <v-text-field outlined dense v-model.trim="direccion_partida" label="Dirección partida"
                  :rules="[v => (v.length >= 5 && v.length <= 140) || 'La dirección puede tener de 5 a 140 caracteres', Rules.unespacio]"
                  maxlength="140" background-color="blue lighten-5" v-uppercase></v-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="4">
                <v-autocomplete outlined dense @keyup="selectUbigeoLlegada()" :search-input.sync="buscar_ubigeo_llegada"
                  v-model="idubigeo_llegada" :items="ubigeos_llegada" label="Ubigeo llegada"
                  :rules="[v => !!v || 'Ubigeo requerido']" background-color="blue lighten-5" clearable class="mr-1">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="7" sm="8">
                <v-text-field outlined dense v-model.trim="direccion_llegada" label="Dirección llegada"
                  :rules="[v => (v.length >= 5 && v.length <= 140) || 'La dirección puede tener de 5 a 140 caracteres', Rules.unespacio]"
                  maxlength="140" background-color="blue lighten-5" v-uppercase></v-text-field>
              </v-col>
              <v-col cols="10" md="6" sm="6" v-if="modo_traslado == '01'">
                <v-autocomplete outlined dense @keyup="selectTransportista()" :search-input.sync="buscar_transportista"
                  v-model="idtransportista" :items="transportistas" label="Transportista"
                  :rules="[v => !!v || 'El cliente es requerido']" background-color="blue lighten-5" clearable
                  class="mr-1"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6" v-if="modo_traslado == '02'">
                <v-autocomplete outlined dense @keyup="selectConductor()" :search-input.sync="buscar_conductor"
                  v-model="idconductor" :items="conductores" label="Conductor"
                  :rules="[v => !!v || 'El cliente es requerido']" background-color="blue lighten-5" clearable
                  class="mr-1"></v-autocomplete>
              </v-col>
              <v-col cols="6" md="2" sm="2" v-if="modo_traslado == '02'">
                <v-text-field outlined dense v-model.trim="placa" label="Placa"
                  :rules="[v => !!v || 'La Placa es requerido']" maxlength="10" background-color="blue lighten-5"
                  v-uppercase class="mr-1"></v-text-field>
              </v-col>
              <v-col cols="6" md="2" sm="2" v-if="idmotivo_traslado == '08'">
                <v-text-field outlined dense v-model.trim="nro_contenedor" label="Nro Contenedor"
                  :rules="[v => !!v || 'El contenedor es requerido']" maxlength="20" background-color="blue lighten-5"
                  v-uppercase></v-text-field>
              </v-col>

              <v-col cols="2" md="1" sm="1" v-if="!deVenta">
                <div class="text-center">
                  <v-btn @click="mostrarRepuestos()" x-small fab dark color="blue darken-3" class="ma-1">
                    <v-icon dark>list</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesGR" :items="detalles" disable-pagination hide-default-footer
                  dense class="elevation-1">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="red darken-2" class="mr-2" @click="eliminarItemDetallePedidoGR(detalles, item)">
                      delete_forever</v-icon>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <span class="font-weight-bold">{{ item.stock_actual }}</span>
                  </template>

                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field dense type="text" v-model="item.cantidad"
                      :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="6" hide-details class="center-input"
                      :disabled="deVenta == true" v-mask-number></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="error" @click="ocultarComprobanteGR()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarGuiaRemision()" :loading="desabilitar"
            :disabled="desabilitar || !isValid" small>
            <v-icon left>save</v-icon>Crear G/R
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear nueva guia de remisión -->

      <!-- Modal enviar email Guia -->
      <v-dialog v-model="adModalEmailCliente" max-width="500" persistent>
        <v-card>
          <v-card-title class="text-h6">
            {{ adComprobante }}
            <v-spacer />
            <v-btn icon @click="emailCerrar" small>
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="justify-center">{{ cliente }}</v-card-subtitle>
          <v-card-text>
            <v-form v-model="isValid">
              <v-text-field outlined dense class="text-xs-center" v-model.trim="email" label="Email"
                :rules="[v => /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                background-color="blue lighten-5" maxlength="60" v-lowercase hide-details></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 justify-center">
            <v-btn color="green" class="white--text" :loading="desabilitar" :disabled="desabilitar || !isValid"
              @click="enviarEmail()" small>
              <v-icon left>send</v-icon>Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal enviar email Guia -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row no-gutters class="mt-4">
                <v-col cols="6" sm="3">
                  <v-select outlined dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli"
                    label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                    @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    background-color="blue lighten-5" v-mask-number></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)">
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                    v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase class="pr-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                    v-uppercase></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>cancel</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>save</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Buscar y seleccionar Repuesto -->
      <v-dialog v-model="verRepuestos" max-width="1500px">
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Repuestos</v-toolbar-title>
            <v-spacer />
            <v-btn color="white" icon dark @click="ocultarRepuestos()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row no-gutters class="mt-2 mb-2">
              <v-spacer />
              <v-col cols="12" sm="8">
                <v-text-field outlined dense v-model.trim="busqueda_repuesto" label="Buscar" append-icon="search"
                  @keyup="listarRepuesto()" class="my-1" background-color="blue lighten-5" clearable hide-details>
                </v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-data-table :headers="cabeceraRepuestos" :items="repuestos" class="elevation-3" item-key="idrepuesto"
              show-expand dense>
              <template v-slot:item.seleccionar="{ item }">
                <v-icon class="mr-2" @click="agregarDetalle(item)" color="blue darken-3">add_circle</v-icon>
              </template>
              <template v-slot:item.imagen="{ item }">
                <template>
                  <v-avatar tile size="30">
                    <img :src="'data:image/png;base64,' + item.imagen" />
                  </v-avatar>
                </template>
              </template>
              <template v-slot:item.precio_unit_comprad="{ item }">
                <div class="text-right">
                  <span class="font-weight-bold">{{ item.precio_unit_comprad | formatearNumero }}</span>
                </div>
              </template>
              <template v-slot:item.precio_unit_compras="{ item }">
                <div class="text-right">
                  <span class="font-weight-bold">{{ item.precio_unit_compras | formatearNumero }}</span>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>MODELO:</strong>
                  {{ item.compatibilidad }}
                  <strong>PROCEDENCIA:</strong>
                  {{ item.procedencia }}
                  <strong>DESCRIPCIÓN:</strong>
                  {{ item.descripcion }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Buscar y seleccionar Repuesto -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="500px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="emailMostrar()" v-if="idtipo_comprobante != '00'">
              <v-icon>attach_email</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobanteImp()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import pdf from "vue-pdf";
import { mapState } from "vuex";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      guiasRemision: [],
      cabeceraListarGuias: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "FECHA ENVÍO", value: "fecha_comprobante", sortable: false },
        { text: "COMPROB. REF.", value: "num_operacion", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPC", value: "opciones", sortable: false },
      ],

      cabeceraDetallesGR: [
        { text: "#", value: "num", sortable: false },
        { text: "UNIDAD", value: "unidad_medida", sortable: false },
        { text: "CÓDIGO", value: "codigo_repuesto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "CANTIDAD", value: "cantidad", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
      ],

      cabeceraRepuestos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "U.M.", value: "unidad_medida", sortable: false },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "GIRO", value: "giro", sortable: false },
        { text: "INT", value: "interior", sortable: false },
        { text: "EXT", value: "exterior", sortable: false },
        { text: "ALT", value: "altura", sortable: false },
        { text: "P/U/C $", value: "precio_unit_comprad", sortable: false },
        { text: "P/U/C S/", value: "precio_unit_compras", sortable: false },
      ],

      detalles: [],
      buscar: "",
      buscar_ubigeo: "",
      buscar_cliente: "",
      buscar_ubigeo_partida: "",
      buscar_ubigeo_llegada: "",
      buscar_transportista: "",
      buscar_conductor: "",

      busqueda_repuesto: "",
      repuestos: [],
      verRepuestos: 0, // Para mostrar dialog buscar repuesto

      sucursal: "",
      cliente: "",
      fecha: "",

      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",

      idmotivo_traslado: "",
      motivo_traslados: [],

      placa: "", // placa del vehiculo transporte privado
      ref_comprobante: "",
      motivo: "",

      idmovimiento: 0,
      idubigeo_partida: "",
      ubigeos_partida: [],
      direccion_partida: "",
      idubigeo_llegada: "",
      ubigeos_llegada: [],
      direccion_llegada: "",
      modo_traslado: "01",
      modo_traslados: [
        { text: "Transporte público", value: "01" },
        { text: "Transporte privado", value: "02" },
      ],

      peso_total: "",
      nro_paquetes: "",
      idtransportista: "",
      transportistas: [],
      idconductor: "",
      conductores: [],

      nro_contenedor: "",
      observacion: "",

      deVenta: "", // de Ventas = true
      numeroref: "",

      idtipo_documento: "",
      idpersona: "",
      clientes: [],

      idtipo_comprobante: "",

      comprobanteModal: 0,
      verNuevaGuiaRemision: 0, // Para mostrar ventana guia de remisión

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos
      disabled: true, // Serie y numero comprobante desabilitados

      adModalEmailCliente: 0,
      adComprobante: 0,

      stringPdf: "",
      blobPdf: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona

      fecha_comprobante_formateada: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      doc_ref: "", // Numero de comprobante FE referencia
      cod_doc_ref: "", // Codigo de tipo documento FE referencia

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobanteImp();
    },
  },

  created() {
    this.idubigeo_partida = this.datos.ubigsucursal;
    this.fechaseleccionada(this.fecha_comprobante);
    this.listar();
    this.select();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    //#region TODO SELECT
    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeoPartida(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_partida.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo_partida == ""
                  ? "-"
                  : me.buscar_ubigeo_partida == null
                    ? "-"
                    : me.buscar_ubigeo_partida,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_partida.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeoLlegada(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_llegada.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo_llegada == ""
                  ? "-"
                  : me.buscar_ubigeo_llegada == null
                    ? "-"
                    : me.buscar_ubigeo_llegada,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_llegada.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    changeCliente(valor) {
      if (valor != null) {
        const selectId = this.clientes.find(
          (elemento) => elemento.value === valor
        );
        this.idtipo_documento = selectId.tipo_documento;
        this.idubigeo_llegada = selectId.ubigeo;
        this.selectUbigeoLlegada(this.idubigeo_llegada);
        this.direccion_llegada = selectId.direccion_llegada;
        //console.log(this.idtipo_documento) // mostrar que tipo documento de cliente 1 ó 6
      }
    },

    selectCliente(id) {
      let me = this;

      var clientesArray = []; // Limpiamos 

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
                ubigeo: x.idubigeo,
                direccion_llegada: x.direccion,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id);
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_cliente == ""
                  ? "-"
                  : me.buscar_cliente == null
                    ? "-"
                    : me.buscar_cliente,
              tipo_doc:
                me.idtipo_comprobante == ""
                  ? "-"
                  : me.idtipo_comprobante == null
                    ? "-"
                    : me.idtipo_comprobante == "01"
                      ? "RUC"
                      : "-",
            },
          })
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
                ubigeo: x.idubigeo,
                direccion_llegada: x.direccion,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id); // En observacion
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectTransportista(id) {
      let me = this;

      var transportistaArray = []; // Limpiamos

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            transportistaArray = response.data;
            transportistaArray.map(function (x) {
              me.transportistas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_transportista == ""
                  ? "-"
                  : me.buscar_transportista == null
                    ? "-"
                    : me.buscar_transportista,
              tipo_doc:
                me.idtipo_comprobante == ""
                  ? "-"
                  : me.idtipo_comprobante == null
                    ? "-"
                    : me.idtipo_comprobante == "01"
                      ? "RUC"
                      : "-",
            },
          })
          .then(function (response) {
            transportistaArray = response.data;
            transportistaArray.map(function (x) {
              me.transportistas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectConductor(id) {
      let me = this;

      var conductorArray = []; // Limpiamos

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            conductorArray = response.data;
            conductorArray.map(function (x) {
              me.conductores.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_conductor == ""
                  ? "-"
                  : me.buscar_conductor == null
                    ? "-"
                    : me.buscar_conductor,
              tipo_doc:
                me.idtipo_comprobante == ""
                  ? "-"
                  : me.idtipo_comprobante == null
                    ? "-"
                    : me.idtipo_comprobante == "01"
                      ? "RUC"
                      : "-",
            },
          })
          .then(function (response) {
            conductorArray = response.data;
            conductorArray.map(function (x) {
              me.conductores.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    select() {
      let me = this;

      var motivo_trasladosArray = [];
      axios
        .get("api/Motivo_Traslados/Select")
        .then(function (response) {
          motivo_trasladosArray = response.data;
          motivo_trasladosArray.map(function (x) {
            me.motivo_traslados.push({
              text: x.descripcion,
              value: x.idmotivo_traslado,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion TODO SELECT

    //#region LISTAR, GUARDAR, REENVIAR, DESCARGAR GUIA REMISION

    listar() {
      let me = this;
      me.loading = true;

      me.deVenta = this.$route.params.venta; // Recibimos venta=true
      me.numeroref = this.$route.params.numero;
      let idmovimientoventa = this.$route.params.idmovimientoventa;
      let idpersonaventa = this.$route.params.idpersonaventa;
      let idpedidoventa = this.$route.params.idpedidoventa;
      let idmotivotraslado = this.$route.params.idmotivotraslado;

      if (me.deVenta === true) {
        me.idmotivo_traslado = idmotivotraslado;
        me.idmovimiento = idmovimientoventa;
        me.idpersona = idpersonaventa;
        me.selectCliente(idpersonaventa);
        me.selectUbigeoPartida(me.idubigeo_partida);
        me.direccion_partida = me.datos.dirsucursal;

        me.listarDetallePedidos(idpedidoventa);
        me.verNuevaGuiaRemision = 1;
        me.$route.params.venta = false;
      }

      axios
        .get("api/Movimientos/ListarGuiaRemisionFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.guiasRemision = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    descripcionMotivo(valor) {
      if (this.motivo_traslados.length > 0) {
        const selectId = this.motivo_traslados.find(
          (elemento) => elemento.value === valor
        );
        this.motivo = this.removeAccents(selectId.text.toUpperCase());
      }
    },

    reenviarSunat(item) {
      let me = this;

      axios
        .put("api/Movimientos/ReenviarGuiaRemisionSunat/" + item.idmovimiento)

        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    guardarGuiaRemision() {
      let me = this;

      // Validamos
      if (me.detalles.length <= 0) {
        return me.SnackbarShow(
          "warning",
          "Ingrese al menos un repuesto al detalle."
        );
      }
      if (me.detalles.length > 30) {
        return me.SnackbarShow(
          "warning",
          "El detalle solo acepta hasta 30 items."
        );
      }

      me.desabilitar = true; // Desabilitamos boton guardar

      axios
        .post("api/Movimientos/CrearGuiaRemision", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          num_operacion: me.numeroref == null ? "" : me.numeroref,
          fecha_comprobante: me.fecha_comprobante,
          idmotivo_traslado: me.idmotivo_traslado,
          idreferencia: me.idmovimiento,
          placa: me.placa == "" ? "0" : me.placa,
          motivo: me.motivo,
          detalles_guia_resumen: me.detalles,
          // Detalle Guia
          idubigeo_partida: me.idubigeo_partida,
          direccion_partida: me.direccion_partida,
          idubigeo_llegada: me.idubigeo_llegada,
          direccion_llegada: me.direccion_llegada,
          modo_traslado: me.modo_traslado,
          peso_total: me.peso_total,
          nro_paquetes: me.nro_paquetes,
          idpersona: me.idpersona,
          idtransportista: me.idtransportista == "" ? 0 : me.idtransportista,
          idconductor: me.idconductor == "" ? 0 : me.idconductor,
          nro_contenedor: me.nro_contenedor,
          observacion: me.observacion,
        })
        .then(function (response) {
          me.ocultarComprobanteGR();
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta);
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    nuevaGuiaRemision() {
      this.verNuevaGuiaRemision = 1;
      this.selectUbigeoPartida(this.idubigeo_partida);
      this.direccion_partida = this.datos.dirsucursal;
    },

    ocultarComprobanteGR() {
      this.verNuevaGuiaRemision = 0;
      this.desabilitar = false; // Habilitamos boton guardar GR
      this.limpiar();
    },

    descargarXml(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarXml/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            me.datos.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    descargarCdr(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarCdr/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "R-" +
            me.datos.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              unidad_medida: x.unidad_medida,
              codigo_repuesto: x.codigo_repuesto,
              descripcion:
                x.categoria + " " + x.marca_repuesto + " " + x.descripcion,
              cantidad: x.cantidad.toString(),
            });
          });
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    eliminarItemDetallePedidoGR(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
    //#endregion LISTAR, GUARDAR, REENVIAR, DESCARGAR GUIA REMISION

    //#region MOSTRAR COMPROBANTE IMPRESION
    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.idusuario = item.idusuario;
      this.sucursal = item.sucursal;
      this.idtipo_comprobante = item.idtipo_comprobante;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;

      this.motivo_traslado = item.motivo_traslado;

      this.peso_total = item.peso_total;
      this.nro_paquetes = item.nro_paquetes;

      this.placa = item.placa;
      this.ref_comprobante = item.ref_comprobante;

      // Cliente
      this.idtipo_documento = item.idtipo_documento;
      this.cliente = item.cliente;
      this.ruc_cliente = item.ruc_cliente;
      this.direccion_cliente = item.direccion_cliente;

      this.fecha = item.fecha;
      this.fecha_comprobante = item.fecha_comprobante;

      this.ubigeo_partida = item.ubigeo_partida;
      this.direccion_partida = item.direccion_partida;
      this.ubigeo_llegada = item.ubigeo_llegada;
      this.direccion_llegada = item.direccion_llegada;
      this.traslado = item.modo_traslado;
      this.transportista = item.transportista;
      this.ruc_transportista = item.ruc_transportista;
      this.dni_conductor = item.dni_conductor;
      this.num_operacion = item.num_operacion;
      this.observacion = item.observacion;

      this.crearPDF();
      this.comprobanteModal = 1;
    },

    ocultarComprobanteImp() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion MOSTRAR COMPROBANTE IMPRESION

    //#region BUSCAR LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE GUIA
    listarRepuesto() {
      let me = this;
      axios
        .get("api/Repuestos/ListarCompra", {
          params: {
            buscar:
              me.busqueda_repuesto == ""
                ? "-"
                : me.busqueda_repuesto == null
                  ? "-"
                  : me.busqueda_repuesto,
          },
        })
        .then(function (response) {
          me.repuestos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    agregarDetalle(data = []) {
      let me = this;

      if (me.encuentra(data["idrepuesto"])) {
        me.SnackbarShow("warning", "El repuesto ya fue agregado.");
      } else {
        me.detalles.push({
          idrepuesto: data["idrepuesto"],
          unidad_medida: data["unidad_medida"],
          codigo_repuesto: data["codigo_repuesto"],
          descripcion:
            data["categoria"] +
            " " +
            data["marca_repuesto"] +
            " " +
            data["descripcion"],
          cantidad: "1",
        });
      }
    },

    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].idrepuesto == id) {
          sw = 1;
        }
      }
      return sw;
    },

    mostrarRepuestos() {
      this.verRepuestos = 1;
    },

    ocultarRepuestos() {
      this.verRepuestos = 0;
      //this.busqueda_repuesto = "";
      //this.repuestos = [];
    },
    //#endregion FIN BUSCAR LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE GUIA

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;
      // El token se genero con el correo jfrain@hotmail.es de https://apisperu.com
      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº DNI.");
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.SnackbarShow(
                "info",
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion
              );
            } else {
              me.nombre = "";
              me.direccion = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº RUC.");
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      //Código para guardar
      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Cliente",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectCliente(me.idpersona);
          me.desabilitarPer = false;
          me.SnackbarShow("success", response.data.mensaje);
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.SnackbarShow("error", error.response.data);
        });
    },

    // Mostrar ventana persona
    mostrarPersona() {
      this.verPersona = true;
    },
    // Cerrar ventana persona
    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    //#region ENVIAR EMAIL
    enviarEmail() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton enviar

      axios
        .put("api/Movimientos/EnviarEmail", {
          idmovimiento: me.idmovimiento,
          cliente: me.cliente,
          email: me.email,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.emailCerrar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    emailMostrar() {
      this.adModalEmailCliente = 1;
      this.adComprobante = this.serie_comprobante + "-" + this.num_comprobante;
    },

    emailCerrar() {
      this.adModalEmailCliente = 0;
      this.desabilitar = false;
    },
    //#endregion ENVIAR EMAIL

    limpiar() {
      this.idmovimiento = 0;
      this.idtipo_comprobante = "";

      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.placa = "";
      this.modo_traslado = "01"; // por defecto lo ponemos Transporte publico='01'
      this.idmotivo_traslado = "";
      this.motivo = "";
      this.doc_ref = "";
      this.cod_doc_ref = "";
      this.idtipo_documento = "";
      this.clientes = [];

      this.detalles = [];
      this.idpedido = "";
      // Cliente
      this.cliente = "";
      this.num_documento = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";
      this.fecha = "";

      this.ref_comprobante = "";

      this.deVenta = "";
      this.numeroref = "";

      this.peso_total = "";
      this.nro_paquetes = "";
      this.observacion = "";
      this.idubigeo_partida = this.datos.ubigsucursal;
      this.direccion_partida = this.datos.dirsucursal;
      this.idubigeo_llegada = "";
      this.direccion_llegada = "";
      this.idpersona = "";
      this.idtransportista = "";
      this.idconductor = "";
      this.placa = "";
      this.nro_contenedor = "";

      this.desabilitar = false;

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_comprobante); // Cargamos fecha actual a fecha_comprobante y fecha_comprobate_formateada
    },
    // Fin Limpiar guia remision item modal impresion

    crearPDF() {
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;
      var subir = "";

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
        subir = false;
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase();
      }

      var columns = [
        { header: "ITEM", dataKey: "item" },
        { header: "CÓDIGO", dataKey: "codigo_repuesto" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "UNID.", dataKey: "unidad_medida" },
        { header: "CANT.", dataKey: "cantidad" },
      ];

      var body = [];

      for (var i = 0; i < JSON.parse(this.ref_comprobante).length; i++) {
        body.push({
          item: i + 1,
          codigo_repuesto: JSON.parse(this.ref_comprobante)[i].codigo_repuesto,
          descripcion: JSON.parse(this.ref_comprobante)[i].descripcion,
          unidad_medida: JSON.parse(this.ref_comprobante)[i].unidad_medida,
          cantidad: JSON.parse(this.ref_comprobante)[i].cantidad,
        });
      }

      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 36, 18);
      // Datos empresa

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 50, right: 72, top: 17 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 9,
          textColor: 0,
          halign: "center",
        },
        body: [
          {
            columna0:
              this.datos.dirsucursal +
              " " +
              this.datos.dptosucursal +
              " - " +
              this.datos.provsucursal +
              " - " +
              this.datos.dttosucursal,
          },
          { columna0: "Email: " + this.datos.mailsucursal },
          { columna0: "Atención Cliente: " + this.datos.telsucursal },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
      });
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 18, 1, 1);
      doc.text("RUC " + this.datos.ruc, 168, 22, "center");
      doc.text(nomcomprob, 168, 27, "center");
      doc.text(
        this.serie_comprobante + "-" + this.num_comprobante,
        168,
        32,
        "center"
      );

      // DESTINATARIO
      doc.autoTable({
        theme: "grid",
        margin: { left: 15, right: 15, top: 43 },
        styles: {
          // overflow: "ellipsize",
          // cellWidth: "wrap",
          lineColor: [227, 228, 229],
        },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          fillColor: [227, 228, 229],
          textColor: 0,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        body: [
          { columna0: "Razón Social: " + this.cliente },
          { columna0: "RUC: " + this.ruc_cliente },
          { columna0: "Dirección: " + this.direccion_cliente },
          { columna0: "Vendedor: " + this.idusuario },
        ],
        columns: [{ header: "DESTINATARIO", dataKey: "columna0" }],
      });

      // ENVIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 3,
        theme: "grid",
        margin: { left: 15, right: 15 },
        styles: {
          //overflow: "ellipsize",
          //cellWidth: "wrap",
          lineColor: [227, 228, 229],
        },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          fillColor: [227, 228, 229],
          textColor: 0,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        columnStyles: {
          columna0: { cellWidth: 90 },
          columna1: { cellWidth: 90 },
        },
        body: [
          {
            columna0:
              "Fecha Emisión: " + moment(this.fecha).format("DD/MM/YYYY"),
            columna1:
              "Fecha Inicio de Traslado: " +
              moment(this.fecha_comprobante).format("DD/MM/YYYY"),
          },
          {
            columna0: "Motivo Traslado: " + this.motivo_traslado,
            columna1:
              "Modalidad de Transporte: " +
              (this.traslado == "01"
                ? "Transporte público"
                : this.traslado == "02"
                  ? "Transporte privado"
                  : ""),
          },
          {
            columna0: "Peso Bruto Total(KG): " + this.peso_total.toFixed(2),
            columna1: "Número de Bultos: " + this.nro_paquetes,
          },
          {
            columna0:
              "P. Partida: " +
              this.ubigeo_partida +
              " - " +
              this.direccion_partida,
            columna1:
              "P. Llegada: " +
              this.ubigeo_llegada +
              " - " +
              this.direccion_llegada,
          },
        ],
        columns: [
          { header: "ENVIO", dataKey: "columna0" },
          { header: "", dataKey: "columna1" },
        ],
      });

      // TRANSPORTE PUBLICO
      if (this.traslado == "01") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 3,
          theme: "grid",
          margin: { left: 15, right: 15 },
          styles: {
            overflow: "ellipsize",
            cellWidth: "wrap",
            lineColor: [227, 228, 229],
          },
          headStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal" | "bold",
            fontSize: 8,
            fillColor: [227, 228, 229],
            textColor: 0,
          },
          bodyStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fontSize: 8,
            textColor: 0,
          },
          columnStyles: {
            columna0: { cellWidth: 140 },
            columna1: { cellWidth: 40 },
          },
          body: [
            {
              columna0: "Nombre / Razón social: " + this.transportista,
              columna1: "RUC: " + this.ruc_transportista,
            },
          ],
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
        });
      }
      // TRANSPORTE PRIVADO
      else if (this.traslado == "02") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 3,
          theme: "grid",
          margin: { left: 15, right: 15 },
          styles: {
            overflow: "ellipsize",
            cellWidth: "wrap",
            lineColor: [227, 228, 229],
          },
          headStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal" | "bold",
            fontSize: 8,
            fillColor: [227, 228, 229],
            textColor: 0,
          },
          bodyStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fontSize: 8,
            textColor: 0,
          },
          columnStyles: {
            columna0: { cellWidth: 90 },
            columna1: { cellWidth: 90 },
          },
          body: [
            {
              columna0: "Numero de placa del vehiculo: " + this.placa,
              columna1: "Conductor: " + this.dni_conductor,
            },
          ],
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
        });
      }

      // DETALLE GUIA REMISION
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 3,
        theme: "grid",
        columns,
        body,
        margin: { left: 15, right: 15 },
        styles: {
          overflow: "ellipsize",
          cellWidth: "wrap",
          lineColor: [227, 228, 229],
        },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          halign: "center",
          fillColor: [227, 228, 229],
          textColor: 0,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        columnStyles: {
          item: { cellWidth: 10, halign: "center" },
          codigo_repuesto: { cellWidth: 30, halign: "center" },
          descripcion: { cellWidth: "auto" },
          unidad_medida: { cellWidth: 12, halign: "center" },
          cantidad: { cellWidth: 15, halign: "center" },
        },
      });

      // OBSERVACIONES
      if (this.observacion.length > 0) {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 3,
          theme: "grid",
          margin: { left: 15, right: 15 },
          styles: {
            overflow: "ellipsize",
            cellWidth: "wrap",
            lineColor: [227, 228, 229],
          },
          headStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal" | "bold",
            fontSize: 8,
            fillColor: [227, 228, 229],
            textColor: 0,
          },
          bodyStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fontSize: 8,
            textColor: 0,
          },
          body: [
            {
              columna0: this.observacion,
            },
          ],
          columns: [{ header: "OBSERVACIONES", dataKey: "columna0" }],
        });
      }

      // REFERENCIA FACTURA ELECTRONICA
      if (this.num_operacion.length > 0 && this.num_operacion.substring(0, 1) != "R") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 3,
          theme: "grid",
          margin: { left: 15, right: 15 },
          styles: {
            overflow: "ellipsize",
            cellWidth: "wrap",
            lineColor: [227, 228, 229],
          },
          headStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal" | "bold",
            fontSize: 8,
            fillColor: [227, 228, 229],
            textColor: 0,
          },
          bodyStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fontSize: 8,
            textColor: 0,
          },
          body: [
            {
              columna0: this.num_operacion,
            },
          ],
          columns: [{ header: "FACTURA ELECTRONICA", dataKey: "columna0" }],
        });
      }

      let finalY = doc.lastAutoTable.finalY;
      doc.setFont("helvetica", "normal");
      doc.text(
        "Consulte su comprobante en SUNAT",
        centro,
        finalY + 5,
        "center"
      );

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      this.subirPdf(); // Subimos pdf blob a nuetra api
      /* Crear PDF guia remision */
    },

    subirPdf() {
      let me = this;

      // Código para editar usuario
      let formData = new FormData();
      formData.append("file", me.blobPdf);
      formData.append(
        "nomcomprobante",
        this.datos.ruc +
        "-" +
        this.idtipo_comprobante +
        "-" +
        this.serie_comprobante +
        "-" +
        this.num_comprobante
      );
      axios
        .post("api/Movimientos/SubirFile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>