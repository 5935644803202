var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"alig-start":""}},[_c('v-flex',[_c('v-snackbar',{attrs:{"color":_vm.alerta.color,"transition":"slide-y-reverse-transition","right":""},on:{"click":function($event){_vm.alerta.snackbar = false}},model:{value:(_vm.alerta.snackbar),callback:function ($$v) {_vm.$set(_vm.alerta, "snackbar", $$v)},expression:"alerta.snackbar"}},[_vm._v(_vm._s(_vm.alerta.text))]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"loading":_vm.loading,"headers":_vm.cabeceraContabilidad,"items":_vm.resumenes,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down"},[_vm._v("CONTABILIDAD RESÚMEN")]),_c('v-divider',{staticClass:"mx-4 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-1",attrs:{"outlined":"","dense":"","items":_vm.selectSucursales,"search-input":_vm.buscar_sucursal,"label":"Sucursales","rules":[function (v) { return !!v || 'Requerido'; }],"background-color":"blue lighten-5","hide-details":""},on:{"update:searchInput":function($event){_vm.buscar_sucursal=$event},"update:search-input":function($event){_vm.buscar_sucursal=$event},"change":function($event){_vm.resumenes = []}},model:{value:(_vm.idsucursal),callback:function ($$v) {_vm.idsucursal=$$v},expression:"idsucursal"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":!_vm.menuFecha,"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-click":_vm.dates.length == 2 ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-1",attrs:{"outlined":"","dense":"","readonly":"","background-color":"blue lighten-5","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.dates.length == 2 ? _vm.menuFecha = false : _vm.menuFecha = true}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"white--text mr-1",attrs:{"x-small":"","fab":"","color":"blue darken-3","loading":_vm.desabilitar,"disabled":_vm.desabilitar || !_vm.isValid},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("search")])],1),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"success","disabled":_vm.desabilitar || !_vm.isValid},on:{"click":function($event){return _vm.crearXLSX()}}},[_c('v-icon',[_vm._v("description")])],1)],1)],1)]},proxy:true},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(item.serie_comprobante)+"-"+_vm._s(item.num_comprobante))])])]}},{key:"item.subtotal_gravado",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.subtotal_gravado)))])])]}},{key:"item.igv_gravado",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.igv_gravado)))])])]}},{key:"item.importe_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.importe_total)))])])]}},{key:"item.costo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.costo)))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }