<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="cabeceraVentaDetallada" :items="ventasDetalladas" :items-per-page="15"
        :search="buscar" dense class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">VENTAS DETALLADAS</v-toolbar-title>
              <v-divider class="mx-3 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-spacer />
              <v-autocomplete outlined dense v-model="idsucursal" :items="selectSucursales"
                :search-input.sync="buscar_sucursal" label="Sucursales" :rules="[v => !!v || 'Requerido']"
                background-color="blue lighten-5" class="mr-1" @change="ventasDetalladas = []" hide-details>
              </v-autocomplete>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                    background-color="blue lighten-5" hide-details class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true" range>
                </v-date-picker>
              </v-menu>
              <v-spacer />
              <v-text-field v-model.trim="buscar" outlined dense label="Búsqueda" background-color="blue lighten-5"
                single-line hide-details class="mr-1" :disabled="desabilitar || !isValid"></v-text-field>
              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                <v-icon>description</v-icon>
              </v-btn>

              <!-- Fin Formulario Busqueda -->
            </v-toolbar>
          </v-form>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.descripcion="{ item }">
          <div class="text-right">
            <span>{{ item.codigo_repuesto + " " + item.categoria }}</span>
          </div>
        </template>
        <template v-slot:item.precio_unit_compra="{ item }">
          <div class="text-right" v-if="item.moneda_compra == 'D'">
            <!-- Valor unitario + igv y realizamos el T/C por la cantidad vendida -->
            <span class="green--text">{{ item.precio_unit_compra.toFixed(2) | formatearNumero }}</span>
          </div>
          <div class="text-right" v-if="item.moneda_compra == 'S'">
            <!-- Valor unitario + igv y realizamos el T/C por la cantidad vendida -->
            <span class="blue--text">{{ item.precio_unit_compra.toFixed(2) | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.cantidad="{ item }">
          <div class="text-center">
            <strong class="blue--text">{{ item.cantidad }}</strong>
          </div>
        </template>
        <template v-slot:item.precio_unit_venta="{ item }">
          <div class="text-right">
            <strong class="blue--text">{{ item.precio_unit_venta | formatearNumero }}</strong>
          </div>
        </template>
        <template v-slot:item.total_venta="{ item }">
          <div class="text-right">
            <strong class="blue--text">{{ redondear(item.cantidad * item.precio_unit_venta) | formatearNumero }}</strong>
          </div>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import numeral from "numeral";
import XLSX from "xlsx";

export default {
  data() {
    return {
      ventasDetalladas: [],
      dialog: false,
      cabeceraVentaDetallada: [
        //{ text: "Fecha", value: "fecha" },
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        //{ text: "CdMv", value: "idmovimiento" },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Comprobante", value: "comprobante", sortable: false },
        { text: "Descripción", value: "descripcion", sortable: false },
        { text: "Precio Unit. Compra", value: "precio_unit_compra", sortable: false },
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Precio Unit. Venta", value: "precio_unit_venta", sortable: false },
        { text: "Total Venta", value: "total_venta", sortable: false },
      ],
      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",
      buscar: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true, // desabilitado buscar persona
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    formatFecha(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Movimientos/ReporteVentaDetallada", {
          params: {
            idsucursal: parseInt(me.idsucursal),
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.ventasDetalladas = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.ventasDetalladas);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },

    select() {
      let su = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.ventasDetalladas.length; i++) {
        rows.push({
          Fecha: moment(this.ventasDetalladas[i].fecha).format("DD/MM/YYYY"),
          CdSc: this.ventasDetalladas[i].idsucursal,
          CdUs: this.ventasDetalladas[i].idusuario,
          CdMv: this.ventasDetalladas[i].idmovimiento,
          Cliente: this.ventasDetalladas[i].cliente,
          Comprobante: this.ventasDetalladas[i].comprobante,
          "Cod. Repuesto": this.ventasDetalladas[i].codigo_repuesto,
          Categoria: this.ventasDetalladas[i].categoria,
          Moneda: this.ventasDetalladas[i].moneda == "S" ? "Soles" : "Dolares",
          "Precio Unit. Compra": this.ventasDetalladas[i].precio_unit_compra,
          Cantidad: this.ventasDetalladas[i].cantidad,
          "Precio Unit. Venta S/": this.redondear(
            this.ventasDetalladas[i].precio_unit_venta
          ),
          "Total Venta S/": this.redondear(
            this.ventasDetalladas[i].precio_unit_venta *
            this.ventasDetalladas[i].cantidad
          ),
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Ventas Detalladas");

      XLSX.writeFile(
        wb,
        "VentasDetalladas " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

