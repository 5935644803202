<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Alerta -->
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
          transition="slide-y-reverse-transition" right>
          <v-layout align-center pr-4>
            <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
              <div>{{ snackbar.text }}</div>
            </v-layout>
          </v-layout>
          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <!-- Fin Alerta -->

        <!-- Listado de cambio precios  -->
        <v-data-table :loading="loading" :headers="headers" :items="detCambios" :items-per-page="15" class="elevation-3"
          :search="buscar" dense>
          <!-- Llenamos datos en data table -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">CAMBIO DE PRECIOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda de repuesto -->
              <v-spacer />
              <v-text-field class="text-xs-center mr-1" v-model.trim="buscar" dense label="Búsqueda" single-line
                @keyup="listar" hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true :  false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <!-- Fin Formulario busqueda de repuesto -->

              <v-spacer />
              <!-- Modal crear y mofificar precios repuesto -->
              <v-dialog v-model="dialog" max-width="1300px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="ma-2" small v-on="on">Nuevo</v-btn>
                </template>
                <v-form v-model="isValid">
                  <v-data-table :loading="loadingPrecios" :headers="moneda== 'D' ? headersPreciosD : headersPreciosS"
                    :items="detPreciosCarga" item-key="idrepuesto" show-expand :search="buscarProducto" dense
                    disable-pagination hide-default-footer class="elevation-3">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title class="hidden-sm-and-down">{{ formTitle }}</v-toolbar-title>
                        <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
                        <strong v-if="editedIndex != 1">T/C {{tc.toFixed(2)}}</strong>
                        <v-spacer />
                        <!-- Formulario busqueda de productos -->
                        <v-col cols="8" md="2" sm="4">
                          <v-select dense :items="monedas" v-model="moneda" label="Moneda"
                            :rules="[v => !!v || 'Requerido']" @change="listarPrecios" required hide-details
                            :disabled="editedIndex == 1 ? true : false"></v-select>
                        </v-col>
                        <v-spacer />
                        <v-text-field class="text-xs-center hidden-sm-and-down" v-model.trim="buscarProducto"
                          append-icon="search" dense label="Búsqueda" single-line hide-details clearable></v-text-field>
                        <!-- Fin Formulario Busqueda de productos -->
                        <v-spacer />
                        <v-btn fab x-small color="primary" @click="guardar" :loading="desabilitar"
                          :disabled="desabilitar || !isValid" v-if="editedIndex != 1">
                          <v-icon>save</v-icon>
                        </v-btn>
                        <v-btn fab x-small color="error" class="ma-2" @click="close">
                          <v-icon>close</v-icon>
                        </v-btn>
                        <!-- Fin Modal activar, desactivar categoria -->
                      </v-toolbar>
                    </template>
                    <!-- Opciones de precios -->
                    <template v-slot:item.num="{ item }">
                      <td>{{detPreciosCarga.indexOf(item) + 1}}</td>
                    </template>
                    <!-- ANTERIOR -->
                    <!-- Precio compra anterior -->
                    <template v-slot:item.precio_unit_comprad_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_comprad_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled v-mask-decimal.us="4">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_compras_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_compras_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="10" hide-details disabled v-mask-decimal.us="4">
                      </v-text-field>
                    </template>
                    <!-- Fin Precio compra anterior -->
                    <template v-slot:item.precio_publico_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_publico_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled v-mask-decimal.us="2">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_tiendas_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_tiendas_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled v-mask-decimal.us="2">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_distribuidor_ant="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_distribuidor_ant"
                        :rules="[v => !!v || 'Requerido']" maxlength="9" hide-details disabled v-mask-decimal.us="2">
                      </v-text-field>
                    </template>
                    <!-- FIN ANTERIOR -->
                    <!-- NUEVO -->
                    <!-- Precio compra nuevo -->
                    <template v-slot:item.precio_unit_comprad="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_comprad"
                        :rules="[v => !!v && v > 0 || 'Requerido']"
                        @keyup.prevent="item.precio_unit_compras = roundOut((parseFloat(item.precio_unit_comprad)*tc),4).toFixed(4)"
                        @keyup.native="item.precio_publico = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_publico))/100),0).toFixed(2),
                        item.precio_tiendas = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_tiendas))/100),0).toFixed(2),
                        item.precio_distribuidor = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_distribuidor))/100),0).toFixed(2)"
                        maxlength="10" hide-details :disabled="editedIndex == 1 ? true : false" v-mask-decimal.us="4">
                      </v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_compras="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_compras"
                        :rules="[v => !!v && v > 0 || 'Requerido']"
                        @keyup.prevent="item.precio_unit_comprad = roundOut((parseFloat(item.precio_unit_compras)/tc),4).toFixed(4)"
                        @keyup.native="item.precio_publico = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_publico))/100),0).toFixed(2),
                        item.precio_tiendas = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_tiendas))/100),0).toFixed(2),
                        item.precio_distribuidor = roundOut((parseFloat(item.precio_unit_compras) + ((parseFloat(item.precio_unit_compras)) * parseFloat(item.utilidad_distribuidor))/100),0).toFixed(2)"
                        maxlength="10" hide-details :disabled="editedIndex == 1 ? true : false" v-mask-decimal.us="4">
                      </v-text-field>
                    </template>
                    <!-- Fin Precio compra nuevo -->
                    <template v-slot:item.precio_publico="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_publico"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false" v-mask-decimal.us="2"></v-text-field>
                    </template>
                    <template v-slot:item.precio_tiendas="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_tiendas"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false" v-mask-decimal.us="2"></v-text-field>
                    </template>
                    <template v-slot:item.precio_distribuidor="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_distribuidor"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="editedIndex == 1 ? true : false" v-mask-decimal.us="2"></v-text-field>
                    </template>
                    <!-- FIN NUEVO -->
                    <template v-slot:item.opciones="{ item }">
                      <v-icon color="error" class="mr-2" :disabled="editedIndex == 1 ? true : false"
                        @click="eliminarItemDetallePrecio(detPreciosCarga, item)">delete_forever</v-icon>
                    </template>
                    <!-- Mas detalles-->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <strong>Fecha Modificación Repuesto:</strong>
                        {{ item.fecha_modificacion | formatearFechaHm }}
                        <!-- <strong>Precio Compra S/ :</strong>
                        {{item.precio_unit_compras}}-->
                        <br />
                        <strong>Ganancia Publico Ant.:</strong>
                        {{ item.utilidad_publico_ant.toFixed(4)}}%
                        <strong class="ml-3">Ganancia Tiendas Ant.:</strong>
                        {{ item.utilidad_tiendas_ant.toFixed(4)}}%
                        <strong class="ml-3">Ganancia Distribuidor Ant.:</strong>
                        {{ item.utilidad_distribuidor_ant.toFixed(4)}}%
                      </td>
                    </template>
                    <!-- Fin Opciones de precios -->
                  </v-data-table>
                </v-form>
              </v-dialog>
              <!-- Fin Modal crear y mofificar precios repuesto -->

              <!-- Modal anular cambio precio -->
              <v-dialog v-model="adModal" max-width="310">
                <v-card>
                  <v-card-title class="justify-center" v-if="adAccion == 1">¿Anular Cambio precio?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span v-if="adAccion == 1">anular</span>
                    el cambio precio Nº {{ adId }} con fecha {{ adFecha | formatearFechaHm }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="AnularCerrar" small>Cancelar</v-btn>
                    <v-btn v-if="adAccion == 1" color="error" @click="anular" small>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular cambio precio -->
            </v-toolbar>
          </template>

          <!-- Opciones editar, eliminar -->
          <template v-slot:item.fecha="{ item }">{{ item.fecha | formatearFechaHm }}</template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="editItem(item)">visibility</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado'">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(1, item)">delete</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones editar, eliminar -->
        </v-data-table>
        <!-- Fin Listado de cambio precios-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      detCambios: [],
      detPreciosCarga: [],
      detPreciosDecimal: [],
      dialog: false,

      headers: [
        { text: "CdCp", value: "idcambio_precio", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "MONEDA", value: "nommoneda", sortable: false },
        { text: "T/C", value: "cambio", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      headersPreciosD: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        // ANTERIORES
        { text: "Precio Compra Anterior $", align: "center", value: "precio_unit_comprad_ant", sortable: false },
        { text: "Precio Venta Anterior S/", align: "center", value: "precio_publico_ant", sortable: false },
        { text: "Precio Cliente Anterior S/", align: "center", value: "precio_tiendas_ant", sortable: false },
        { text: "Precio Distrib. Anterior S/", align: "center", value: "precio_distribuidor_ant", sortable: false },
        // NUEVOS
        { text: "NUEVO PRECIO COMPRA $", align: "center", value: "precio_unit_comprad", sortable: false },
        { text: "NUEVO PRECIO VENTA S/", align: "center", value: "precio_publico", sortable: false },
        { text: "NUEVO PRECIO CLIENTE S/", align: "center", value: "precio_tiendas", sortable: false },
        { text: "NUEVO PRECIO DISTRIB. S/", align: "center", value: "precio_distribuidor", sortable: false },
        { text: "OPC", value: "opciones", sortable: false },
      ],

      headersPreciosS: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        // ANTERIORES
        { text: "Precio Compra Anterior S/", align: "center", value: "precio_unit_compras_ant", sortable: false },
        { text: "Precio Publico Anterior S/", align: "center", value: "precio_publico_ant", sortable: false },
        { text: "Precio Tiendas Anterior S/", align: "center", value: "precio_tiendas_ant", sortable: false },
        { text: "Precio Distrib. Anterior S/", align: "center", value: "precio_distribuidor_ant", sortable: false },
        // NUEVOS
        { text: "NUEVO PRECIO COMPRA S/", align: "center", value: "precio_unit_compras", sortable: false },
        { text: "NUEVO PRECIO PUBLICO S/", align: "center", value: "precio_publico", sortable: false },
        { text: "NUEVO PRECIO TIENDAS S/", align: "center", value: "precio_tiendas", sortable: false },
        { text: "NUEVO PRECIO DISTRIB. S/", align: "center", value: "precio_distribuidor", sortable: false },
        { text: "OPC", value: "opciones", sortable: false },
      ],

      buscar: "",
      buscarProducto: "",
      editedIndex: -1,
      loadingPrecios: false,

      idrepuesto: "",
      idcambio_precio: "",
      cambio: "",
      moneda: "",
      fecha: "",

      monedas: [
        { text: "Soles", value: "S" },
        { text: "Dolares", value: "D" },
      ],

      igv: 0,
      tc: 0,

      desabilitar: false,
      isValid: true,

      adModal: 0,
      adAccion: 0,
      adId: "",
      adFecha: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos"]),

    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Cambio de Precios"
        : "Ver Cambio de Precios";
    },

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
  },

  methods: {
    roundOut: function (numero, dec) {
      let entero = Math.round(Number(numero + "e+" + dec));
      let redondear = Number(entero + "e-" + dec);
      return redondear;
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Cambio_Precios/Listar", {
          params: {
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.detCambios = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;

      // Validamos si el detalle esta vacio
      if (me.detPreciosCarga.length <= 0) {
        return me.SnackbarShow(
          "warning",
          "No hay datos disponibles en el detalle ."
        );
      }

      me.desabilitar = true;
      me.detPreciosDecimal = [];
      me.convertirDetPreciosDecimal();

      if (me.editedIndex > -1) {
        // Editar
        axios
          .put("api/Cambio_Precios/Actualizar", {
            idusuario: parseInt(me.usuario.idusuario),
            moneda: me.moneda,
            cambio: me.tc,
            detalle_cambio_precios: me.detPreciosDecimal,
          })

          .then(function (response) {
            me.desabilitar = false;
            me.SnackbarShow("success", response.data);
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.SnackbarShow("error", error.response.data);
          });
      } else {
        // Guardar
        axios
          .post("api/Cambio_Precios/Crear", {
            idusuario: parseInt(me.usuario.idusuario),
            moneda: me.moneda,
            cambio: me.tc,
            detalle_cambio_precios: me.detPreciosDecimal,
          })
          .then(function (response) {
            me.desabilitar = false;
            me.SnackbarShow("success", response.data);
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.SnackbarShow("error", error.response.data);
          });
      }
    },

    convertirDetPreciosDecimal() {
      let me = this;
      for (var i = 0; i < me.detPreciosCarga.length; i++) {
        me.detPreciosDecimal.push({
          idrepuesto: me.detPreciosCarga[i].idrepuesto,
          // Precio compra anterior
          precio_unit_comprad_ant: parseFloat(
            me.detPreciosCarga[i].precio_unit_comprad_ant
          ),
          precio_unit_compras_ant: parseFloat(
            me.detPreciosCarga[i].precio_unit_compras_ant
          ),
          // Precio compra nuevo
          precio_unit_comprad: parseFloat(
            me.detPreciosCarga[i].precio_unit_comprad
          ),
          precio_unit_compras: parseFloat(
            me.detPreciosCarga[i].precio_unit_compras
          ),

          // Utilidad de repuesto anterior
          utilidad_publico_ant: me.detPreciosCarga[i].utilidad_publico_ant,
          utilidad_tiendas_ant: me.detPreciosCarga[i].utilidad_tiendas_ant,
          utilidad_distribuidor_ant:
            me.detPreciosCarga[i].utilidad_distribuidor_ant,
          // Utilidad de repuesto nuevo Ganancia%=(pv-pc/pc)*100
          utilidad_publico: me.roundOut(
            ((parseFloat(me.detPreciosCarga[i].precio_publico) -
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) /
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) *
            100,
            4
          ),
          utilidad_tiendas: me.roundOut(
            ((parseFloat(me.detPreciosCarga[i].precio_tiendas) -
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) /
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) *
            100,
            4
          ),
          utilidad_distribuidor: me.roundOut(
            ((parseFloat(me.detPreciosCarga[i].precio_distribuidor) -
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) /
              parseFloat(me.detPreciosCarga[i].precio_unit_compras)) *
            100,
            4
          ),
          // Precio unitario anterior
          precio_publico_ant: parseFloat(
            me.detPreciosCarga[i].precio_publico_ant
          ),
          precio_tiendas_ant: parseFloat(
            me.detPreciosCarga[i].precio_tiendas_ant
          ),
          precio_distribuidor_ant: parseFloat(
            me.detPreciosCarga[i].precio_distribuidor_ant
          ),
          // Precio unitario nuevo
          precio_publico: parseFloat(me.detPreciosCarga[i].precio_publico),
          precio_tiendas: parseFloat(me.detPreciosCarga[i].precio_tiendas),
          precio_distribuidor: parseFloat(
            me.detPreciosCarga[i].precio_distribuidor
          ),
        });
      }
    },

    /* Modal Anular */
    anular() {
      let me = this;

      axios
        .put("api/Cambio_Precios/AnularCambioPrecio/" + me.adId)
        .then(function (response) {
          me.SnackbarShow("success", response.data);
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.SnackbarShow("error", error.response.data);
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },

    anularMostrar(accion, item) {
      this.adModal = 1;
      this.adId = item.idcambio_precio;
      this.adFecha = item.fecha;
      if (accion == 1) {
        this.adAccion = 1;
      } else {
        this.adModal = 0;
      }
    },

    AnularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular */

    editItem(item) {
      this.idrepuesto = item.idrepuesto;
      this.moneda = item.moneda;
      this.fecha = item.fecha; // Solo para visualizar en el detalle
      this.idcambio_precio = item.idcambio_precio;
      this.listarDetalleCambioPrecio(this.idcambio_precio);
      this.editedIndex = 1;
      this.dialog = true;
    },

    listarDetalleCambioPrecio(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Cambio_Precios/ListarDetallesCambioPrecios/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_publico
          detallesArray.map(function (x) {
            me.detPreciosCarga.push({
              idrepuesto: x.idrepuesto,
              fecha_modificacion: me.fecha, // Jalamos la fecha de tabla cambio_precio
              descripcion: x.descripcion,
              // Precio compra anterior
              precio_unit_comprad_ant: x.precio_unit_comprad_ant.toFixed(4),
              precio_unit_compras_ant: x.precio_unit_compras_ant.toFixed(4),
              // Precio compra nuevo
              precio_unit_comprad: x.precio_unit_comprad.toFixed(4),
              precio_unit_compras: x.precio_unit_compras.toFixed(4),

              // Utilidad de repuesto anterior
              utilidad_publico_ant: x.utilidad_publico_ant,
              utilidad_tiendas_ant: x.utilidad_tiendas_ant,
              utilidad_distribuidor_ant: x.utilidad_distribuidor_ant,
              // Utilidad de repuesto
              utilidad_publico: x.utilidad_publico.toFixed(4),
              utilidad_tiendas: x.utilidad_tiendas.toFixed(4),
              utilidad_distribuidor: x.utilidad_distribuidor.toFixed(4),
              // Precio unitario anterior
              precio_publico_ant: x.precio_publico_ant.toFixed(2),
              precio_tiendas_ant: x.precio_tiendas_ant.toFixed(2),
              precio_distribuidor_ant: x.precio_distribuidor_ant.toFixed(2),
              // Precio unitario nuevo
              precio_publico: x.precio_publico.toFixed(2),
              precio_tiendas: x.precio_tiendas.toFixed(2),
              precio_distribuidor: x.precio_distribuidor.toFixed(2),
            });
          });
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.loadingPrecios = false;
      this.limpiar();
    },

    limpiar() {
      this.idrepuesto = "";
      this.moneda = "";
      this.fecha = "";
      this.buscarProducto = "";

      this.detPreciosCarga = [];
      this.detPreciosDecimal = [];

      this.editedIndex = -1;
    },

    listarPrecios() {
      let me = this;
      me.loadingPrecios = true;
      me.detPreciosCarga = [];
      var detallesArray = [];

      axios
        .get("api/Cambio_Precios/ListarPreciosRepuesto", {
          params: { moneda: me.moneda, cambio: me.tc },
        })
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detPreciosCarga.push({
              idrepuesto: x.idrepuesto,
              fecha_modificacion: x.fecha_modificacion,
              descripcion: x.descripcion,
              // Precio compra anterior
              precio_unit_comprad_ant: x.precio_unit_comprad_ant.toFixed(4),
              precio_unit_compras_ant: x.precio_unit_compras_ant.toFixed(4),
              // Precio compra nuevo
              precio_unit_comprad: x.precio_unit_comprad.toFixed(4),
              precio_unit_compras: x.precio_unit_compras.toFixed(4),

              // Utilidad de repuesto anterior
              utilidad_publico_ant: x.utilidad_publico_ant,
              utilidad_tiendas_ant: x.utilidad_tiendas_ant,
              utilidad_distribuidor_ant: x.utilidad_distribuidor_ant,
              // Utilidad de repuesto
              utilidad_publico: x.utilidad_publico.toFixed(4),
              utilidad_tiendas: x.utilidad_tiendas.toFixed(4),
              utilidad_distribuidor: x.utilidad_distribuidor.toFixed(4),
              // Precio unitario anterior
              precio_publico_ant: x.precio_publico_ant.toFixed(2),
              precio_tiendas_ant: x.precio_tiendas_ant.toFixed(2),
              precio_distribuidor_ant: x.precio_distribuidor_ant.toFixed(2),
              // Precio unitario nuevo
              precio_publico: x.precio_publico.toFixed(2),
              precio_tiendas: x.precio_tiendas.toFixed(2),
              precio_distribuidor: x.precio_distribuidor.toFixed(2),
            });
          });
          me.loadingPrecios = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    eliminarItemDetallePrecio(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>