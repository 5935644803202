<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="cabeceraCompraDetallada" :items="comprasDetalladas"
        :items-per-page="15" :search="buscar" dense class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">COMPRAS DETALLADAS</v-toolbar-title>
              <v-divider class="mx-3 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-spacer />
              <v-autocomplete outlined dense v-model="idsucursal" :items="selectSucursales"
                :search-input.sync="buscar_sucursal" label="Sucursales" :rules="[v => !!v || 'Requerido']"
                background-color="blue lighten-5" class="mr-1" @change="comprasDetalladas=[]" hide-details>
              </v-autocomplete>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true :  false">
                <template v-slot:activator="{ on }">
                  <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                    background-color="blue lighten-5" hide-details class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length==2 ? menuFecha=false : menuFecha=true" range>
                </v-date-picker>
              </v-menu>
              <v-spacer />
              <v-text-field v-model.trim="buscar" outlined dense label="Búsqueda" background-color="blue lighten-5"
                single-line hide-details class="mr-3" :disabled="desabilitar || !isValid"></v-text-field>
              <v-spacer />
              <v-switch v-model="todoCompra" inset hide-details label="Todo" class="mr-2 hidden-sm-and-down"></v-switch>
              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                <v-icon>description</v-icon>
              </v-btn>

              <!-- Fin Formulario Busqueda -->
            </v-toolbar>
          </v-form>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>

        <template v-slot:item.fecha_comprobante="{ item }">
          <span>{{ item.fecha_comprobante | formatearFecha }}</span>
        </template>
        <template v-slot:item.descripcion="{ item }">
          <div class="text-right">
            <span>{{ item.codigo_repuesto +" "+ item.categoria}}</span>
          </div>
        </template>
        <template v-slot:item.valor_unit_compra="{ item }">
          <div class="text-right">
            <span :class="item.moneda=='S' ? 'blue--text':'green--text'">{{ item.valor_unit_compra | formatearNumero
            }}</span>
          </div>
        </template>
        <template v-slot:item.descuento="{ item }">
          <div class="text-right">
            <span :class="item.moneda=='S' ? 'blue--text':'green--text'">{{ item.descuento | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.valor_venta="{ item }">
          <div class="text-right">
            <strong :class="item.moneda=='S' ? 'blue--text':'green--text'">{{
            redondear(item.stock_ingreso*item.valor_unit_compra) | formatearNumero }}</strong>
          </div>
        </template>
        <template v-slot:item.igv="{ item }">
          <div class="text-right">
            <strong :class="item.moneda=='S' ? 'blue--text':'green--text'">{{
            redondear(item.stock_ingreso*item.valor_unit_compra*item.igv) | formatearNumero }}</strong>
          </div>
        </template>
        <template v-slot:item.precio_total="{ item }">
          <div class="text-right">
            <strong :class="item.moneda=='S' ? 'blue--text':'green--text'">{{
            item.stock_ingreso*item.valor_unit_compra+redondear(item.stock_ingreso*item.valor_unit_compra*item.igv) |
            formatearNumero }}</strong>
          </div>
        </template>

        <template v-slot:item.precio_publico="{ item }">
          <div class="text-right">
            <span class="blue--text">{{ item.precio_publico | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.precio_tiendas="{ item }">
          <div class="text-right">
            <span class="blue--text">{{ item.precio_tiendas | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.precio_distribuidor="{ item }">
          <div class="text-right">
            <span class="blue--text">{{ item.precio_distribuidor | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.stock_actual="{ item }">
          <div class="text-center">
            <strong>{{ item.stock_actual }}</strong>
          </div>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      comprasDetalladas: [],
      dialog: false,
      cabeceraCompraDetallada: [
        { text: "Fecha", value: "fecha", sortable: false  },
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        // text: "CdMv", value: "idmovimiento" },
        { text: "Proveedor", value: "proveedor", sortable: false  },
        { text: "Fecha Comprob.", value: "fecha_comprobante", sortable: false  },
        { text: "Comprobante", value: "comprobante", sortable: false  },
        { text: "Descripción", value: "descripcion" , sortable: false },
        { text: "Stock Ingreso", value: "stock_ingreso", align: "center" , sortable: false },
        { text: "Valor Unitario", value: "valor_unit_compra", sortable: false  },
        { text: "Dscto", value: "descuento", sortable: false  },
        { text: "Valor Compra", value: "valor_venta", sortable: false  },
        { text: "IGV", value: "igv", sortable: false  },
        { text: "Precio Compra", value: "precio_total", sortable: false  },
        { text: "P/U/Vta Publico", value: "precio_publico", align: "center", sortable: false  },
        { text: "P/U/Vta Tiendas", value: "precio_tiendas", align: "center", sortable: false  },
        { text: "P/U/Vta Distrib", value: "precio_distribuidor", align: "center", sortable: false  },
        { text: "Stock Actual", value: "stock_actual", align: "center", sortable: false  },
      ],
      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",
      buscar: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      todoCompra: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true, // desabilitado buscar persona
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    formatFecha(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;

      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Movimientos/ReporteCompraDetallada", {
          params: {
            idsucursal: parseInt(me.idsucursal),
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
            todo: me.todoCompra, // No incluimos si stock actual es menor a cero
          },
        })
        .then(function (response) {
          me.comprasDetalladas = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.comprasDetalladas);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },

    /* Seleccionar tipos */
    select() {
      let su = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.comprasDetalladas.length; i++) {
        rows.push({
          Fecha: moment(this.comprasDetalladas[i].fecha).format("DD/MM/YYYY"),
          CdSc: this.comprasDetalladas[i].idsucursal,
          CdUs: this.comprasDetalladas[i].idusuario,
          CdMv: this.comprasDetalladas[i].idmovimiento,
          Proveedor: this.comprasDetalladas[i].proveedor,
          "Fecha Comprobante": moment(
            this.comprasDetalladas[i].fecha_comprobante
          ).format("DD/MM/YYYY"),
          Comprobante: this.comprasDetalladas[i].comprobante,
          "Cod. Repuesto": this.comprasDetalladas[i].codigo_repuesto,
          Categoria: this.comprasDetalladas[i].categoria,
          "Stock Ingreo": this.comprasDetalladas[i].stock_ingreso,
          "Stock Actual": this.comprasDetalladas[i].stock_actual,
          Moneda: this.comprasDetalladas[i].moneda == "S" ? "Soles" : "Dolares",
          "Valor Unitario": this.comprasDetalladas[i].valor_unit_compra,
          Descuento: this.comprasDetalladas[i].descuento,
          "Valor Compra":
            this.comprasDetalladas[i].stock_ingreso *
            this.comprasDetalladas[i].valor_unit_compra,
          IGV: this.redondear(
            this.comprasDetalladas[i].stock_ingreso *
            this.comprasDetalladas[i].valor_unit_compra *
            this.comprasDetalladas[i].igv
          ),
          "Precio Compra": this.redondear(
            this.comprasDetalladas[i].stock_ingreso *
            this.comprasDetalladas[i].valor_unit_compra +
            this.comprasDetalladas[i].stock_ingreso *
            this.comprasDetalladas[i].valor_unit_compra *
            this.comprasDetalladas[i].igv
          ),
          "P/U Vta Publico S/": this.comprasDetalladas[i].precio_publico,
          "P/U Vta Tiendas S/": this.comprasDetalladas[i].precio_tiendas,
          "P/U Vta Distribuidor S/":
            this.comprasDetalladas[i].precio_distribuidor,
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Compras Detalladas");

      XLSX.writeFile(
        wb,
        "ComprasDetalladas " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

