<template>
  <v-layout alig-start>
    <v-flex>
      <v-data-table :loading="loading" :headers="headers" :items="roles" :search="buscar" class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">ROLES</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" outlined dense
              label="Búsqueda" background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <div class="flex-grow-1"></div>
          </v-toolbar>
        </template>
        <template v-slot:item.condicion="{ item }">
          <template v-if="item.condicion">
            <span class="blue--text">Activo</span>
          </template>
          <template v-else>
            <span class="red--text">Inactivo</span>
          </template>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      roles: [],
      dialog: false,
      headers: [
        { text: "NOMBRE", value: "nombre", sortable: false },
        { text: "DESCRPCIÓN", value: "descripcion", sortable: false },
        { text: "ESTADO", value: "condicion", sortable: false },
      ],
      buscar: "",
    };
  },
  computed: {},

  watch: {},

  created() {
    this.listar();
  },
  methods: {
    listar() {
      let me = this;

      me.loading = true;
      axios
        .get("api/Roles/Listar")
        .then(function (response) {
          //console.log(response);
          me.roles = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
};
</script>