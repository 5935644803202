<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Buscar y seleccionar repuesto -->
      <v-dialog v-model="verRepuestos" max-width="1600px">
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Repuestos</v-toolbar-title>
            <v-spacer />
            <v-btn color="white" icon dark @click="ocultarRepuestos()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row no-gutters>
              <v-spacer />
              <v-col cols="12" sm="6">
                <v-text-field outlined dense v-model.trim="brDescripcion" label="Descripción"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>

            <!-- Mostrar imagen de repuesto -->
            <v-dialog v-model="selectedZoom" max-width="350px">
              <v-avatar tile size="350">
                <img :src="'data:image/png;base64,' + imagen_zoom" />
              </v-avatar>
            </v-dialog>
            <!-- Fin Mostrar imagen de repuesto -->

            <v-data-table :headers="cabeceraBusquedaRepuestos" :items="repuestos" item-key="iddetalle_compra"
              show-expand class="elevation-3" dense>
              <template v-slot:item.seleccionar="{ item }">
                <v-icon @click="agregarDetalle(item)" color="blue darken-3">add_circle</v-icon>
              </template>

              <template v-slot:item.imagen="{ item }">
                <template>
                  <v-avatar tile size="27">
                    <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                  </v-avatar>
                </template>
              </template>

              <template v-slot:item.precio_compra="{ item }">
                <div class="text-right">
                  <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}{{ item.precio_unit_compra | formatearNumero }}</span>
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>UM:</strong>
                  {{ item.unidad_medida }}
                  <strong>VEHICULO:</strong>
                  {{ item.marca_vehiculo }}
                  <strong>MODELO:</strong>
                  {{ item.compatibilidad }}
                  <strong>PROCEDENCIA:</strong>
                  {{ item.procedencia }}
                  <!-- No hay necesidad de mostrar -->
                  <!-- <strong>CdPd:</strong>
                  {{ item.idpedido }}-->
                  <strong>DESCRIPCIÓN.:</strong>
                  {{ item.descripcion }}
                  <br />
                  <strong>PROVEEDOR:</strong>
                  {{ item.proveedor.substring(0, 30) }}
                  <strong>FEC.INGRESO.:</strong>
                  {{ item.fecha | formatearFecha }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Buscar y seleccionar repuesto -->

      <!-- Listado de Traslado -->
      <v-data-table :loading="loading" :headers="cabeceraListarTraslado" :items="pedidos" :items-per-page="15"
        item-key="idmovimiento" show-expand dense class="elevation-3" v-if="verNuevoPedido == 0">
        <template v-slot:top>
          <!-- Titulo y busqueda pedido persona -->
          <v-toolbar flat color="white" v-if="verNuevoPedido == 0">
            <v-toolbar-title class="hidden-sm-and-down">TRASLADOS</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de pedido -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar_traslado" background-color="blue lighten-5"
              label="Búsqueda" @keyup="listar" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
              offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates"
                @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()" range>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de pedido -->

            <v-btn @click="mostrarNuevoTraslado" color="primary" class="ma-2" small>Nuevo</v-btn>
          </v-toolbar>
          <!-- Fin Titulo y busqueda pedido persona -->

          <!-- Modal anular traslado -->
          <v-dialog v-model="adModal" max-width="310">
            <v-card>
              <v-card-title class="headline">Anular: {{ serie_comprobante }}-{{ num_comprobante }}</v-card-title>
              <v-card-actions class="py-0 pb-4 justify-center">
                <v-btn color="green darken-1" @click="AnularCerrar" small>Cancelar</v-btn>
                <v-btn color="orange darken-4" @click="anular" small>Anular</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Fin Modal anular traslado -->
        </template>
        <!-- Opciones editar, eliminar pedido persona y realizar venta -->
        <template v-slot:item.numero="{ item }">{{ item.serie_comprobante + "-" + item.num_comprobante }}</template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>

        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado != 'Anulado'">
            <span>{{ item.estado }}</span>
          </template>
          <template v-else>
            <span class="red--text">{{ item.estado }}</span>
          </template>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <template v-if="item.estado != 'Anulado' && item.idusuario == usuario.idusuario">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="anularMostrar(item)">delete</v-icon>
            </v-btn>
          </template>
          <!-- Menu para mas opciones de FE -->
          <template v-if="item.estado == 'Creado'">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small icon>
                  <v-icon v-on="on" color="blue darken-2">more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <!-- Guia Remision -->
                <v-list-item link>
                  <v-list-item-content>
                    <v-list-item-title class="blue--text" @click="irGuiaRemision(item)">Generar GUÍA REMISIÓN
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Guia Remision -->
              </v-list>
            </v-menu>
          </template>
          <!-- Fin Menu para mas opciones de FE -->
        </template>
        <!-- Fin Opciones editar, eliminar pedido persona y realizar venta -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Registrado por:</strong>
            {{ item.usuario }}
          </td>
        </template>
      </v-data-table>
      <!--Fin Listado de Traslado -->

      <!-- Ventana crear traslado  -->
      <v-card v-if="verNuevoPedido == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>Nuevo Traslado</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevoPedido">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" md="4" sm="5">
                <v-select outlined dense v-model="idreferencia" :items="selectSucursales" label="Sucursal Destino"
                  :rules="[v => !!v || 'La sucursal Destino es requerido']" background-color="blue lighten-5"
                  class="mr-1">
                </v-select>
              </v-col>

              <v-col cols="10" md="3" sm="3">
                <v-text-field outlined dense @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
                  label="Código barra" background-color="blue lighten-5" maxlength="30"></v-text-field>
              </v-col>
              <v-col cols="2" md="2" sm="1">
                <div class="text-center">
                  <v-btn @click="mostrarRepuestos()" x-small fab dark color="blue darken-3" class="ma-1">
                    <v-icon dark>list</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesPedidos" :items="detalles" disable-pagination
                  hide-default-footer dense class="elevation-3">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="red darken-2" class="mr-2" @click="eliminarItemDetallePedido(detalles, item)">
                      delete_forever</v-icon>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <span class="font-weight-bold">{{ item.stock_actual }}</span>
                  </template>

                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                      v-model.number="item.cantidad"
                      :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                      maxlength="6" hide-details class="center-input" v-mask-number></v-text-field>
                  </template>
                  <template v-slot:item.precio_unit_compra="{ item }">
                    <v-text-field dense type="text" v-model="item.precio_unit_compra"
                      :rules="[v => !!v && v >= item.precio_unit_compra2 || 'Requerido']" maxlength="9" hide-details
                      v-mask-decimal.us="4">
                    </v-text-field>
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{ roundOut(item.cantidad * item.precio_unit_compra, 2) |
                      formatearNumero }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <v-col cols="10" align="end">
                      <strong>Importe Total $</strong>
                    </v-col>
                    <v-col cols="2" align="end">
                      <strong>{{ importe_total = roundOut(parseFloat(calcularTotal), 2) | formatearNumero }}</strong>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="error" @click="ocultarNuevo()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarTraslado()" :loading="desabilitar" :disabled="desabilitar || !isValid"
            small>
            <v-icon left>save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear traslado  -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],
      detalles: [],
      detalle_traslados: [],

      cabeceraListarTraslado: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "ORIGEN", value: "sucursal", sortable: false },
        { text: "DESTINO", value: "sucursal_destino", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraDetallesPedidos: [
        { text: "#", value: "num", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
        { text: "CÓDIGO", value: "codigo_repuesto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "CANTIDAD", value: "cantidad", sortable: false, align: "center" },
        { text: "P.COMPRA $", value: "precio_unit_compra", sortable: false },
        { text: "TOTAL $", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaRepuestos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", sortable: false, align: "center" },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "TP", value: "forma", sortable: false },
        { text: "MT", value: "material", sortable: false },
        { text: "GR", value: "giro", sortable: false },
        { text: "INT", align: "center", value: "interior", sortable: false },
        { text: "EXT", align: "center", value: "exterior", sortable: false },
        { text: "ALT", align: "center", value: "altura", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "P.COMPRA", value: "precio_compra", sortable: false },
      ],

      buscar_traslado: "",
      codigo_barra: "",
      repuestos: [],

      brDescripcion: "",

      idpedido: "",
      idpersona: "",

      igv: 0,
      importe_total: 0,

      selectedZoom: false,
      imagen_zoom: "",
      tc: 0,

      verNuevoPedido: 0,
      verRepuestos: 0,

      idreferencia: "", // Destino
      selectSucursales: [],

      sucursal: "",
      persona: "",
      fecha: "",
      num_documento: "",

      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",

      comprobanteModal: 0,

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // Validamos que todos lo campos sean correctos

      adModal: 0,
      adId: "",

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
      // Persona

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      idtipo_documento: "", // dni=1 ó ruc=6

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.roundOut(parseInt(me.detalles[i].cantidad == "" ? 0.0 : me.detalles[i].cantidad) * parseFloat(me.detalles[i].precio_unit_compra == "" ? 0.0 : me.detalles[i].precio_unit_compra), 2
        );
      }
      return resultado;
    },

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },

    verRepuestos(val) {
      val || this.ocultarRepuestos();
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
    this.select();
  },

  methods: {
    roundOut: function (numero, dec) {
      let entero = Math.round(Number(numero + "e+" + dec));
      let redondear = Number(entero + "e-" + dec);
      return redondear;
    },

    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName =
        "AjusteStock-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    select() {
      let me = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/SelectDestino", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal)
          },
        })
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            me.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    //#region LISTAR TRASLADOS
    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Movimientos/ListarTrasladoFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar_traslado == "" ? "-" : me.buscar_traslado,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    irGuiaRemision(item) {
      this.$router.push({
        name: "guias_remision",
        params: {
          venta: true,
          idmovimientoventa: item.idmovimiento,
          idpersonaventa: item.idpersona,
          idpedidoventa: item.idpedido,
          numero: item.serie_comprobante + "-" + item.num_comprobante,
          idmotivotraslado: "04",
        },
      });
    },
    //#endregion

    //#region TRASLADO
    guardarTraslado() {
      let me = this;
      // Validamos 
      if (me.detalles.length <= 0) {
        return me.SnackbarShow(
          "warning",
          "Ingrese al menos un repuesto al detalle."
        );
      }

      me.desabilitar = true;
      me.detalle_traslados = [];
      me.convertirDetalleTraslado();
      axios
        .post("api/Movimientos/CrearTraslado", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          idpersona: 1,
          importe_total: parseFloat(me.importe_total),
          motivo: "Traslado",
          idreferencia: me.idreferencia, // Sucursal destino
          detalle_traslados: me.detalle_traslados,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta);
          me.ocultarNuevo();
          me.listar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    mostrarNuevoTraslado() {
      this.verNuevoPedido = 1;
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    convertirDetalleTraslado() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_traslados.push({
          idrepuesto: me.detalles[i].idrepuesto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          cantidad: parseInt(me.detalles[i].cantidad),
          valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
          precio_unit_compra: parseFloat(me.detalles[i].precio_unit_compra)
        });
      }
    },
    //#endregion

    //#region MOSTRAR COMPROBANTE PARA IMPRESIÓN
    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.sucursal_destino = item.sucursal_destino;
      this.persona = item.persona;
      this.num_documento = item.num_documento;

      this.importe_total = item.importe_total;

      this.motivo = item.motivo;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.fecha = item.fecha;
      this.idpedido = item.idpedido;

      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion

    //#region VER DETALLES DE PEDIDOS Y VENTAS A CLIENTES

    // Detalles de pedido y venta
    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              codigo_repuesto: x.codigo_repuesto, // FE
              //categoria: x.categoria,
              descripcion:
                x.categoria +
                " " +
                x.marca_repuesto +
                " " +
                x.descripcion.substr(0, 40),
              //marca_repuesto: x.marca_repuesto,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              unidad_medida: x.unidad_medida,
              cantidad: x.cantidad,
              ref_idpedido: x.ref_idpedido,
              //valor_unit_venta: x.valor_unit_venta.toFixed(2),
              //descto_item: x.descto_item.toFixed(2),
              //valor_venta: x.valor_venta.toFixed(2),
              //precio_venta: x.precio_venta.toFixed(2),
              //idprod_sunat: x.idprod_sunat,
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion  FIN VER DETALLES DE PEDIDOS Y VENTAS A CLIENTES

    //#region BUSCAR POR CODIGO DE BARRAS, LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE VENTA
    buscarCodigoBarra() {
      let me = this;

      axios
        .get(
          "api/Pedidos/BuscarCodigoBarraVenta/" +
          parseInt(me.usuario.idsucursal) +
          "/" +
          me.codigo_barra
        )
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
        })
        .catch(function (error) {
          //console.log(error);
          me.SnackbarShow(
            "warning",
            "No hay registro de compra para el repuesto"
          );
        });
    },
    // listar busqueda de repuesto
    listarRepuestoBuscar() {
      let me = this;

      axios
        .get("api/Pedidos/ListarRepuestoVenta", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
            interior: "-",
            exterior: "-",
            categoria: "-",
            marca: "-",
            vehiculo: "-",
          },
        })
        .then(function (response) {
          me.repuestos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    }, // Fin lista busqueda de repuesto

    // Agregar a detalle de pedido
    agregarDetalle(data = []) {
      let me = this;

      if (me.encuentra(data["iddetalle_compra"])) {
        me.SnackbarShow("warning", "El repuesto ya fue agregado.");
      } else {
        me.detalles.push({
          idrepuesto: data["idrepuesto"],
          iddetalle_compra: data["iddetalle_compra"],
          codigo_repuesto: data["codigo_repuesto"], // FE
          //categoria: data["categoria"],
          //marca_repuesto: data["marca_repuesto"],
          descripcion:
            data["categoria"] +
            " " +
            data["marca_repuesto"] +
            " " +
            data["descripcion"],
          stock_actual: data["stock_actual"],
          cantidad: 1,
          //valor_unit_compra: me.roundOut((data["idtipo_servicio"] == 3 ? data["valor_unit_compra"] : data["valor_unit_compra"] * me.tc), 4),
          //precio_unit_compra: me.roundOut(data["idtipo_servicio"] == 3 ? (data["valor_unit_compra"] + (data["valor_unit_compra"] * data["igv"])) : ((data["valor_unit_compra"] + data["valor_unit_compra"] * data["igv"]) * me.tc), 4).toFixed(4),

          valor_unit_compra: me.roundOut((data["idtipo_servicio"] == 4 ? data["valor_unit_compra"] : data["valor_unit_compra"] / me.tc), 4),
          precio_unit_compra: me.roundOut(data["idtipo_servicio"] == 4 ? data["precio_unit_compra"] : (data["precio_unit_compra"] / me.tc), 4).toFixed(4),
          precio_unit_compra2: me.roundOut(data["idtipo_servicio"] == 4 ? data["precio_unit_compra"] : (data["precio_unit_compra"] / me.tc), 4).toFixed(4), // > compra
        });
      }
    },

    // Busca en detalle de pedido si existe el repuesto
    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].iddetalle_compra == id) {
          sw = 1;
        }
      }
      return sw;
    },

    // Mostrar ventana repuesto
    mostrarRepuestos() {
      this.verRepuestos = 1;
    },
    // Ocultar ventana repuesto
    ocultarRepuestos() {
      this.verRepuestos = 0;
      this.repuestos = [];
      this.brDescripcion = "";
      this.imagen_zoom = ""; // Limpiamos imagen_zoom
    },
    //#endregion FIN BUSCAR POR CODIGO DE BARRAS, LISTAR REPUESTO DE BUSQUEDA Y AGREGAR REPUESTO AL DETALLE DE VENTA

    //#region OCULTAR VENTANA PEDIDO Y VENTA
    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false; // Habilitamos boton guardar pedido y venta
      this.limpiar();
    },
    //#endregion FIN OCULTAR VENTANA PEDIDO Y VENTA

    /* Modal Anular, cancelar traslado */
    anular() {
      let me = this;

      axios
        .put("api/Movimientos/AnularTraslado/" + me.adId)
        .then(function (response) {
          me.SnackbarShow("success", response.data);
          me.adModal = 0;
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.SnackbarShow("error", error.response.data);
          me.adModal = 0;
          me.adId = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.adId = item.idmovimiento;
    },

    AnularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular, cancelar traslado */

    //#region LIMPIAR PEDIDO ITEM
    limpiar() {
      this.igv = parseFloat(this.datos.igv); // El igv siempre tiene que tener valor y formateado

      this.idpedido = ""; // Pedido y venta
      this.idpersona = ""; // Pedido y venta
      this.num_documento = "";
      this.tipo_pedido = "";

      this.idreferencia = "";
      this.importe_total = 0;

      this.motivo = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.detalles = []; // Array detalle pedidos
      this.detalle_traslados = []; // Array Convertido de Array detalles

      this.codigo_barra = ""; // Buscar codigo de barras
      this.repuestos = [];
      this.brDescripcion = "";

      this.idtipo_documento = ""; // dni=1 ó ruc=6
      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";
    },
    //#endregion FIN LIMPIAR PEDIDO ITEM

    crearPDF() {
      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U.M.", dataKey: "unidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "RPC", dataKey: "ref_idpedido" },
      ];
      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          unidad_medida: this.detalles[i].unidad_medida,
          descripcion:
            this.detalles[i].codigo_repuesto +
            " " +
            this.detalles[i].descripcion,
          ref_idpedido: this.detalles[i].ref_idpedido,
        });
      }

      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos persona
          doc.text("Razón Social: " + this.persona, 40, 75);
          doc.text("Doc. N°: " + this.num_documento, 40, 90);
          doc.text("Motivo: " + this.motivo, 150, 90);

          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            420,
            75
          );

          doc.text("Suc. Origen: " + this.sucursal, 260, 90);
          doc.text("Suc. Destino: " + this.sucursal_destino, 420, 90);
          // Fin Datos persona

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            "TRASLADO",
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "striped",
        columns,
        body,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 32, halign: "center" },
          unidad_medida: { cellWidth: 40, halign: "center" },
          descripcion: { cellWidth: "auto" },
          ref_idpedido: { cellWidth: 30, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: this.serie_comprobante + "-" + this.num_comprobante + ".pdf",
      });
      addHeaders(doc);
      addFooters(doc);

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },

};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>