var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"alig-start":""}},[_c('v-flex',[_c('v-snackbar',{attrs:{"color":_vm.alerta.color,"transition":"slide-y-reverse-transition","right":""},on:{"click":function($event){_vm.alerta.snackbar = false}},model:{value:(_vm.alerta.snackbar),callback:function ($$v) {_vm.$set(_vm.alerta, "snackbar", $$v)},expression:"alerta.snackbar"}},[_vm._v(_vm._s(_vm.alerta.text))]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"loading":_vm.loading,"headers":_vm.cabeceraCompraDetallada,"items":_vm.comprasDetalladas,"items-per-page":15,"search":_vm.buscar,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down"},[_vm._v("COMPRAS DETALLADAS")]),_c('v-divider',{staticClass:"mx-3 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-1",attrs:{"outlined":"","dense":"","items":_vm.selectSucursales,"search-input":_vm.buscar_sucursal,"label":"Sucursales","rules":[function (v) { return !!v || 'Requerido'; }],"background-color":"blue lighten-5","hide-details":""},on:{"update:searchInput":function($event){_vm.buscar_sucursal=$event},"update:search-input":function($event){_vm.buscar_sucursal=$event},"change":function($event){_vm.comprasDetalladas=[]}},model:{value:(_vm.idsucursal),callback:function ($$v) {_vm.idsucursal=$$v},expression:"idsucursal"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":!_vm.menuFecha,"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-click":_vm.dates.length == 2 ? true :  false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-1",attrs:{"outlined":"","dense":"","readonly":"","background-color":"blue lighten-5","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.dates.length==2 ? _vm.menuFecha=false : _vm.menuFecha=true}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"outlined":"","dense":"","label":"Búsqueda","background-color":"blue lighten-5","single-line":"","hide-details":"","disabled":_vm.desabilitar || !_vm.isValid},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"buscar"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"inset":"","hide-details":"","label":"Todo"},model:{value:(_vm.todoCompra),callback:function ($$v) {_vm.todoCompra=$$v},expression:"todoCompra"}}),_c('v-spacer'),_c('v-btn',{staticClass:"white--text mr-1",attrs:{"x-small":"","fab":"","color":"blue darken-3","loading":_vm.desabilitar,"disabled":_vm.desabilitar || !_vm.isValid},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("search")])],1),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"success","disabled":_vm.desabilitar || !_vm.isValid},on:{"click":function($event){return _vm.crearXLSX()}}},[_c('v-icon',[_vm._v("description")])],1)],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha)))])]}},{key:"item.fecha_comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha_comprobante)))])]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(item.codigo_repuesto +" "+ item.categoria))])])]}},{key:"item.valor_unit_compra",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{class:item.moneda=='S' ? 'blue--text':'green--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.valor_unit_compra)))])])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{class:item.moneda=='S' ? 'blue--text':'green--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.descuento)))])])]}},{key:"item.valor_venta",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda=='S' ? 'blue--text':'green--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.redondear(item.stock_ingreso*item.valor_unit_compra))))])])]}},{key:"item.igv",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda=='S' ? 'blue--text':'green--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.redondear(item.stock_ingreso*item.valor_unit_compra*item.igv))))])])]}},{key:"item.precio_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda=='S' ? 'blue--text':'green--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.stock_ingreso*item.valor_unit_compra+_vm.redondear(item.stock_ingreso*item.valor_unit_compra*item.igv))))])])]}},{key:"item.precio_publico",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.precio_publico)))])])]}},{key:"item.precio_tiendas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.precio_tiendas)))])])]}},{key:"item.precio_distribuidor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.precio_distribuidor)))])])]}},{key:"item.stock_actual",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(item.stock_actual))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }