<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Listado de empresa y opciones de titulo, busqueda, nuevo, etc -->
      <v-data-table :loading="loading" :headers="headers" :items="empresa" disable-pagination hide-default-footer
        class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>EMPRESA</v-toolbar-title>
            <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Modificar empresa -->
            <v-dialog v-model="dialog" max-width="500px" persistent>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Actualizar Empresa</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters>
                      <v-col cols="12" align="center">
                        <template color="teal" v-if="file">
                          <v-avatar tile size="80" class="mt-6 mb-6">
                            <v-img :src="urlTemporal" @click="$refs.boton.click()" />
                          </v-avatar>
                        </template>
                        <template color="teal" v-else>
                          <v-avatar tile size="80" class="mt-6 mb-6">
                            <img :src="'data:image/png;base64,' + logo" @click="$refs.boton.click()" />
                          </v-avatar>
                        </template>
                        <input type="file" id="file" ref="boton" accept=".png, .jpg, .jpeg"
                          @change="buscarImagen($event)" class="d-none" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="nombre" label="Nombre"
                          :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 70) || 'Nombre debe tener entre 3 a 70 caracteres', Rules.unespacio]"
                          maxlength="70" background-color="blue lighten-5" prepend-inner-icon="business" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field outlined dense v-model.trim="igv" label="IGV" :rules="[v => !!v || 'Requerido']"
                          maxlength="5" background-color="blue lighten-5" v-mask-decimal.us="2" class="pr-1">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field outlined dense v-model.trim="cambio" label="Tipo Cambio"
                          :rules="[v => !!v || 'Requerido']" maxlength="5" background-color="blue lighten-5"
                          v-mask-decimal.us="2"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="ruc" label="RUC"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length == 11) || 'El RUC debe tener 11 caracteres']"
                          maxlength="11" background-color="blue lighten-5" v-mask-number class="pl-1"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined dense v-model.trim="direccion" label="Dirección"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 60) || 'Dirección debe tener entre 3 a 60 caracteres', Rules.unespacio]"
                          maxlength="60" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                          v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                          background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="password_email" label="Password Email"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password de Email debe tener min. 3 y max. 20 caracteres']"
                          maxlength="20" :append-icon="showPasswordEmail ? 'visibility' : 'visibility_off'"
                          :type="showPasswordEmail ? 'text' : 'password'"
                          @click:append="showPasswordEmail = !showPasswordEmail" background-color="blue lighten-5"
                          class="pr-1"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="password_certificado" label="Password Certificado"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password Certificado debe tener min. 3 y max. 20 caracteres']"
                          maxlength="20" :append-icon="showPasswordCertificado ? 'visibility' : 'visibility_off'"
                          :type="showPasswordCertificado ? 'text' : 'password'"
                          @click:append="showPasswordCertificado = !showPasswordCertificado"
                          background-color="blue lighten-5"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="usuario_sol" label="Usuario SOL"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Usuario SOL debe tener min. 3 y max. 20 caracteres']"
                          maxlength="20" background-color="blue lighten-5" class="pr-1"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense v-model.trim="password_sol" label="Password SOL"
                          :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Password SOL debe tener min. 3 y max. 20 caracteres']"
                          maxlength="20" :append-icon="showPasswordSol ? 'visibility' : 'visibility_off'"
                          :type="showPasswordSol ? 'text' : 'password'"
                          @click:append="showPasswordSol = !showPasswordSol" background-color="blue lighten-5">
                        </v-text-field>
                      </v-col>
                      <!-- Mensaje error empresa -->
                      <v-col cols="12" v-if="mensajeEmpresa != ''">
                        <v-alert text dense type="error">{{ mensajeEmpresa }}</v-alert>
                      </v-col>
                      <!-- Mensaje error empresa -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modificar empresa -->
          </v-toolbar>
        </template>

        <!-- Opción editar y mostrar logo empresa -->
        <template v-slot:item.logo="{ item }">
          <template>
            <img height="30px" :src="'data:image/png;base64,' + item.logo" />
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="editItem(item)">edit</v-icon>
          </v-btn>
        </template>
        <!-- Fin Opción editar y mostrar logo empresa -->
      </v-data-table>
      <!-- Fin Listado de empresa y opciones de titulo, busqueda, nuevo, etc -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      empresa: [],
      dialog: false,
      headers: [
        { text: "Logo", value: "logo", sortable: false },
        { text: "RAZÓN SOCIAL", value: "nombre", sortable: false },
        { text: "IGV", value: "igv", sortable: false },
        { text: "T/C", value: "cambio", sortable: false },
        { text: "RUC", value: "ruc", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        { text: "EMAIL", value: "email", sortable: false },
        { text: "OPC", value: "opciones", sortable: false },
      ],
      idempresa: "",
      buscar_ubigeo: "",

      idubigeo: "", // select ubigeos
      ubigeos: [],

      nombre: "",
      igv: 0,
      cambio: 0,
      ruc: "",
      direccion: "",
      email: "",
      logo: "",
      password_email: "",
      usuario_sol: "",
      password_sol: "",
      password_certificado: "",

      showPasswordEmail: false,
      showPasswordCertificado: false,
      showPasswordSol: false,

      file: null,
      urlTemporal: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      mensajeEmpresa: "",

      desabilitar: false,

      isValid: true,
      alerta: { snackbar: false, color: "", text: "" },
      /* Validaciones */
      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["cargarSettings"]),

    listar() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Empresas/Listar")
        .then(function (response) {
          //console.log(response);
          me.empresa = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      axios
        .put("api/Empresas/Actualizar", {
          idempresa: me.idempresa,
          idubigeo: me.idubigeo,
          nombre: me.nombre,
          igv: me.igv,
          cambio: me.cambio,
          ruc: me.ruc,
          direccion: me.direccion,
          email: me.email,
          logo: me.logo,
          // FE
          password_email: me.password_email,
          password_certificado: me.password_certificado,
          usuario_sol: me.usuario_sol,
          password_sol: me.password_sol,
        })
        .then(function (response) {
          me.desabilitar = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.cargarSettings();
          me.close();
          me.listar();
          me.limpiar();
        })

        .catch(function (error) {
          me.desabilitar = false;
          me.mensajeEmpresa = error.response.data;
          me.limpiarAlertaError();
        });
    },

    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeEmpresa = "";
      }, 3000);
    },


    editItem(item) {
      this.idempresa = item.idempresa;
      this.idubigeo = item.idubigeo;
      this.selectUbigeo(item.idubigeo);
      this.nombre = item.nombre;
      this.ruc = item.ruc;
      this.igv = item.igv.toFixed(2);
      this.cambio = item.cambio.toFixed(2);
      this.direccion = item.direccion;
      this.email = item.email;
      this.logo = item.logo;
      this.password_email = item.password_email;
      this.usuario_sol = item.usuario_sol;
      this.password_sol = item.password_sol;
      this.password_certificado = item.password_certificado;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.idempresa = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.igv = 0;
      this.cambio = 0;
      this.ruc = "";
      this.direccion = "";
      this.email = "";
      this.logo = "";
      this.file = null;
      this.urlTemporal = "";
      this.showPasswordEmail = false;
      this.showPasswordCertificado = false;
      this.showPasswordSol = false;
      this.mensajeEmpresa = "";

      // Limpiamos input carga de imagen
      document.getElementById("file").value = [];
    },

    buscarImagen(event) {
      //console.log(event.target.files[0]);
      const fileCargado = event.target.files[0];
      // Verificamos si tenemos imagen en fileCargado
      if (fileCargado) {
        const reader = new FileReader();
        reader.readAsDataURL(fileCargado);
        reader.onload = (e) => {
          this.urlTemporal = e.target.result; // Visualizamos en Base64 al usuario despues de cargar imagen
        };

        // Reducimos imagen con un ancho de 200px
        var resizeImage = function (settings) {
          var file = settings.file;
          var maxSize = settings.maxSize;
          var reader = new FileReader();
          var image = new Image();
          var canvas = document.createElement("canvas");
          var dataURItoBlob = function (dataURI) {
            var bytes =
              dataURI.split(",")[0].indexOf("base64") >= 0
                ? atob(dataURI.split(",")[1])
                : unescape(dataURI.split(",")[1]);
            var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
          };
          var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            var dataUrl = canvas.toDataURL("image/jpeg");
            //return dataURItoBlob(dataUrl);
            return dataUrl;
          };
          return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
              no(new Error("Not an image"));
              return;
            }
            reader.onload = function (readerEvent) {
              image.onload = function () {
                return ok(resize());
              };
              image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
          });
        };
        // llamamos a funcion para reducir imagen a imagen Blob
        resizeImage({
          file: fileCargado,
          maxSize: 200,
        })
          .then((resizedImage) => {
            this.logo = resizedImage.replace("data:image/jpeg;base64,", ""); // Cargamos imagen reducida para guardar y reemplazamos data... x un valor vacio
          })
          .catch((err) => {
            console.error(err);
          });
        // Fin Reducimos imagen con un ancho de 700px
      }
    },
  },
};
</script>