<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="cabeceraStockRepuesto" :items="stockRepuestos" :search="buscar"
        :items-per-page="15" dense class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">STOCK DE REPUESTOS</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-spacer />
              <v-autocomplete outlined dense v-model="idsucursal" :items="selectSucursales"
                :search-input.sync="buscar_sucursal" label="Sucursales" :rules="[v => !!v || 'Requerido']"
                background-color="blue lighten-5" @change="stockRepuestos = []" class="mr-1" hide-details>
              </v-autocomplete>
              <v-spacer />
              <v-select outlined dense v-model="filtro" :items="filtros" label="Filtro" background-color="blue lighten-5"
                class="mr-1" hide-details></v-select>
              <v-text-field v-model.trim="buscar" outlined dense label="Búsqueda" background-color="blue lighten-5"
                single-line hide-details class="mr-1" :disabled="desabilitar || !isValid"></v-text-field>

              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="info" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                <v-icon>description</v-icon>
              </v-btn>

              <!-- Fin Formulario Busqueda -->
            </v-toolbar>
          </v-form>
        </template>

        <template v-slot:item.stockactual="{ item }">
          <template v-if="item.stockactual > item.stockminimo && item.stockactual <= item.stockminimo + 10">
            <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
          </template>
          <template v-else-if="item.stockactual > (item.stockminimo + 10)">
            <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
          </template>
          <template v-else>
            <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
          </template>
        </template>

        <template v-slot:item.precio_stockingreso="{ item }">
          <div class="text-right">
            <span>{{ redondear(item.precio_stockingreso) | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.precio_stockactual="{ item }">
          <div class="text-right">
            <span>{{ redondear(item.precio_stockactual) | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.precio_stockvendido="{ item }">
          <div class="text-right">
            <span>{{ redondear(item.precio_stockvendido) | formatearNumero }}</span>
          </div>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex"; //Importamos para llamar a los elementos
import moment from "moment";
import numeral from "numeral";
import XLSX from "xlsx";

export default {
  data() {
    return {
      stockRepuestos: [],
      dialog: false,
      cabeceraStockRepuesto: [
        { text: "Cod. Repuesto", value: "codigo_repuesto", sortable: false },
        { text: "Descripción", value: "descripcion", sortable: false },
        { text: "Categoría", value: "categoria", sortable: false },
        { text: "M. Repuesto", value: "marca_repuesto", sortable: false },
        { text: "M. Vehículo", value: "marca_vehiculo", sortable: false },
        { text: "Tipo", value: "forma", sortable: false },
        { text: "Giro", value: "giro", sortable: false },
        { text: "Material", value: "material", sortable: false },
        { text: "Stock Ingresado", value: "stockingreso", align: "center", sortable: false },
        { text: "Importe Stock Ingresado", value: "precio_stockingreso", sortable: false },
        { text: "Stock Vendido", value: "stockvendido", align: "center", sortable: false },
        { text: "Importe Stock Vendido S/", value: "precio_stockvendido", sortable: false },
        { text: "Stock Actual", value: "stockactual", align: "center", sortable: false },
        { text: "Importe Stock Actual", value: "precio_stockactual", sortable: false },
      ],

      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",

      filtro: "",
      filtros: [
        { text: "-", value: "" },
        { text: "Verde", value: "V" },
        { text: "Amarillo", value: "A" },
        { text: "Rojo", value: "R" },
      ],

      buscar: "",

      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    /* Listar */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Movimientos/ReporteStockRepuesto" + me.filtro, {
          params: {
            idsucursal: parseInt(me.idsucursal),
          },
        })
        .then(function (response) {
          me.stockRepuestos = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.stockRepuestos);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },
    /* Fin Listar */

    /* Seleccionar tipos */
    select() {
      let su = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "Cod.Rpto", dataKey: "codigo_repuesto" },
        { header: "Descripción", dataKey: "descripcion" },
        { header: "Categoría", dataKey: "categoria" },
        { header: "M. Repuesto", dataKey: "marca_repuesto" },
        { header: "M. Vehículo", dataKey: "marca_vehiculo" },
        { header: "Tipo", dataKey: "forma" },
        { header: "Giro", dataKey: "giro" },
        { header: "Material", dataKey: "material" },

        { header: "Stock Ing.", dataKey: "stockingreso" },
        { header: "Imp. Stock Ing.", dataKey: "precio_stockingreso" },
        { header: "Stock Vta", dataKey: "stockvendido" },
        { header: "Imp. Stock Vta", dataKey: "precio_stockvendido" },
        { header: "Stock Act.", dataKey: "stockactual" },
        { header: "Imp. Stock Act.", dataKey: "precio_stockactual" },
      ];
      var body = [];
      var foot = [];

      var totalstockingreso = 0;
      var totalprecio_stockingreso = 0;
      var totalstockvendido = 0;
      var totalprecio_stockvendido = 0;
      var totalstockactual = 0;
      var totalprecio_stockactual = 0;

      for (var i = 0; i < this.stockRepuestos.length; i++) {
        totalstockingreso =
          totalstockingreso + parseFloat(this.stockRepuestos[i].stockingreso);
        totalprecio_stockingreso =
          totalprecio_stockingreso +
          parseFloat(this.stockRepuestos[i].precio_stockingreso);

        totalstockvendido =
          totalstockvendido + parseFloat(this.stockRepuestos[i].stockvendido);
        totalprecio_stockvendido =
          totalprecio_stockvendido +
          parseFloat(this.stockRepuestos[i].precio_stockvendido);

        totalstockactual =
          totalstockactual + parseFloat(this.stockRepuestos[i].stockactual);
        totalprecio_stockactual =
          totalprecio_stockactual +
          parseFloat(this.stockRepuestos[i].precio_stockactual);
        body.push({
          codigo_repuesto: this.stockRepuestos[i].codigo_repuesto,
          descripcion: this.stockRepuestos[i].descripcion,
          categoria: this.stockRepuestos[i].categoria,
          marca_repuesto: this.stockRepuestos[i].marca_repuesto,
          marca_vehiculo: this.stockRepuestos[i].marca_vehiculo,
          forma: this.stockRepuestos[i].forma,
          giro: this.stockRepuestos[i].giro,
          material: this.stockRepuestos[i].material,

          stockminimo: this.stockRepuestos[i].stockminimo,

          stockingreso: this.stockRepuestos[i].stockingreso,
          precio_stockingreso: numeral(
            this.redondear(this.stockRepuestos[i].precio_stockingreso)
          ).format("###,##0.00"),

          stockvendido: this.stockRepuestos[i].stockvendido,
          precio_stockvendido: numeral(
            this.redondear(this.stockRepuestos[i].precio_stockvendido)
          ).format("###,##0.00"),

          stockactual: this.stockRepuestos[i].stockactual,
          precio_stockactual: numeral(
            this.redondear(this.stockRepuestos[i].precio_stockactual)
          ).format("###,##0.00"),

        });

        if (i === this.stockRepuestos.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 8,
            },
            totalstockingreso,
            numeral(totalprecio_stockingreso).format("###,##0.00"),
            totalstockvendido,
            numeral(totalprecio_stockvendido).format("###,##0.00"),
            totalstockactual,
            numeral(totalprecio_stockactual).format("###,##0.00"),
          ]);
        }
      }
      //console.log(body);

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("STOCK DE REPUESTOS", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.setFontSize(8);
          doc.text("SUCURSAL: " + this.buscar_sucursal.substr(0, 69), 40, 72);
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("l", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 80 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          codigo_repuesto: { cellWidth: 50 },
          descripcion: { cellWidth: "auto" },
          categoria: { cellWidth: 63 },
          marca_repuesto: { cellWidth: 50 },
          marca_vehiculo: { cellWidth: 50 },
          forma: { cellWidth: 50 },
          giro: { cellWidth: 20 },
          material: { cellWidth: 40 },
          stockingreso: { cellWidth: 45, halign: "right" },
          precio_stockingreso: { cellWidth: 60, halign: "right" },
          stockvendido: { cellWidth: 45, halign: "right" },
          precio_stockvendido: { cellWidth: 60, halign: "right" },
          stockactual: { cellWidth: 45, halign: "right" },
          precio_stockactual: { cellWidth: 60, halign: "right" },
        },
        // En la columna stockactual ponemos condicion para poner colores segun condicion de stockminimo
        didParseCell: function (cell, data) {
          //console.log("Data = ", data);
          //console.log("cell = ", cell);
          var sMinimo;
          var sActual;
          sMinimo = cell.row.raw.stockminimo;
          sActual = cell.row.raw.stockactual;
          if (
            sActual > sMinimo &&
            sActual <= sMinimo + 10 &&
            cell.column.raw.dataKey == "stockactual"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 192, 67]; // Amarillo
          } else if (
            sActual > sMinimo + 10 &&
            cell.column.raw.dataKey == "stockactual"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [66, 175, 93]; // Verde
          } else if (
            sActual <= sMinimo &&
            cell.column.raw.dataKey == "stockactual"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 80, 86]; // Rojo
          }
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("StockRepuetos.pdf");
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.stockRepuestos.length; i++) {
        rows.push({
          idrepuesto: this.stockRepuestos[i].idrepuesto,
          "COD. REPUESTO": this.stockRepuestos[i].codigo_repuesto,
          DESCRIPCION: this.stockRepuestos[i].descripcion,
          CATEGORIA: this.stockRepuestos[i].categoria,
          "MARCA REPUESTO": this.stockRepuestos[i].marca_repuesto,
          "MARCA VEHICULO": this.stockRepuestos[i].marca_vehiculo,
          TIPO: this.stockRepuestos[i].forma,
          GIRO: this.stockRepuestos[i].giro,
          MATERIAL: this.stockRepuestos[i].material,

          "STOCK INGRESADO": this.stockRepuestos[i].stockingreso,
          "IMPORTE STOCK INGRESADO S/": this.redondear(
            this.stockRepuestos[i].precio_stockingreso
          ),
          "STOCK VENDIDO": this.stockRepuestos[i].stockvendido,
          "IMPORTE STOCK VENDIDO S/": this.redondear(
            this.stockRepuestos[i].precio_stockvendido
          ),
          "STOCK ACTUAL": this.stockRepuestos[i].stockactual,
          "IMPORTE STOCK ACTUAL S/": this.redondear(
            this.stockRepuestos[i].precio_stockactual
          ),
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Stock Repuestos");

      XLSX.writeFile(wb, "StockRepuestos.xlsx");
    },
  },
};
</script>

