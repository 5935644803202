<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary">
            <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row class="mt-2">
                <v-col cols="6" sm="3">
                  <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                    :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                  </v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    v-mask-number hide-details></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="primary" class="white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)" hide-details>
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                    :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Busqueda y listado de cuentas pagadas -->
      <v-data-table :loading="loading" :headers="cabeceraCuentasPagadas" :items="cuentasPagadas" :items-per-page="15"
        dense class="elevation-3" v-if="verMovimiento == 0 && verDetalleCuenta == 0">
        <template v-slot:top>
          <v-toolbar flat color="white" v-if="verMovimiento == 0 && verDetalleCuenta == 0">
            <v-toolbar-title class="hidden-sm-and-down">PAGADAS</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de cuenta pagada -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar_cuenta_pagada" label="Búsqueda"
              @keyup="listarCuentaPagada()" background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
              offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update"
                  @click:append="listarCuentaPagada()"></v-text-field>
              </template>
              <v-date-picker v-model="dates"
                @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true"
                @change="listarCuentaPagada()" range></v-date-picker>
            </v-menu>
            <v-spacer />
            <!-- Fin Formulario busqueda de cuenta pagada -->

            <!-- Modal anular cuenta pagada -->
            <v-dialog v-model="adModal" max-width="290">
              <v-card>
                <v-card-title class="headline" v-if="adAccion == 1">¿Anular Pago?</v-card-title>
                <v-card-text>
                  Estás a punto de
                  <span v-if="adAccion == 1">Anular</span>
                  el pago Nº {{ adId }}
                </v-card-text>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="green darken-1" @click="AnularCerrar" small>Cancelar</v-btn>
                  <v-btn v-if="adAccion == 1" color="orange darken-4" @click="anular" small>Anular</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular cuenta pagada -->

            <template>
              <v-btn color="primary" dark class="ma-2" @click="listarDetalleCuenta()" small>Por Pagar</v-btn>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:item.numero="{ item }">
          <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
        </template>

        <template v-slot:item.pago="{ item }">
          <div class="text-right">
            <span>{{ item.moneda == "S" ? "S/" : "US$" }} {{ item.pago | formatearNumero }}</span>
          </div>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado != 'Anulado'">
            <span>{{ item.estado }}</span>
          </template>
          <template v-else>
            <span class="red--text">{{ item.estado }}</span>
          </template>
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <template v-if="item.estado != 'Anulado'">
            <v-btn small icon>
              <v-icon color="red darken-2" @click="anularMostrar(1, item)">delete</v-icon>
            </v-btn>
          </template>

        </template>
      </v-data-table>
      <!--Fin Busqueda y listado de cuentas pagadas  -->

      <!-- Mostrar impresion Modal Comprobante -->
      <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobante()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar impresion Modal Comprobante -->

      <!-- Busqueda y listado detalle cuenta y ventana cuenta por pagar -->
      <v-data-table :loading="loading" :headers="cabeceraDetalleCuenta" :items="detalleCuenta" :items-per-page="15"
        dense class="elevation-3" v-if="verDetalleCuenta == 1">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>POR PAGAR</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <!-- Modal Caledario-->
            <v-btn fab x-small dark color="info" class="mr-1" @click="mostrarCalendario()">
              <v-icon>calendar_today</v-icon>
            </v-btn>
            <v-spacer />
            <!-- Formulario busqueda detalle cuenta -->
            <v-text-field outlined dense class="text-xs-center" v-model.trim="buscar_detalle_cuenta" label="Búsqueda"
              @keyup="listarDetalleCuenta()" background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <!-- Fin Formulario busqueda detalle cuenta -->
            <template>
              <v-btn color="primary" class="ma-2" @click="ocultarDetalleCuenta(), listarCuentaPagada()" small>Pagadas
              </v-btn>
            </template>

            <!-- Modal crear cuenta pagada -->
            <v-dialog v-model="pagarCuentaModal" max-width="610px" persistent>
              <v-card>
                <v-toolbar dark dense class="primary" flat>
                  <v-toolbar-title>Pagar Cuenta</v-toolbar-title>
                  <v-spacer />
                  {{ numero }} VCTO: {{ fecha_vencimiento | formatearFecha}}
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-row no-gutters class="mt-4">
                      <v-col cols="12">
                        <v-chip class="mb-4" color="primary" label outlined>
                          <v-icon left>business</v-icon>
                          <strong>{{ proveedor }}</strong>
                        </v-chip>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <v-text-field outlined dense v-model.trim="importe_total" label="Importe"
                          :rules="[v => (!!v && v > 0) || 'Requerido', v => parseFloat(v) >= importev || 'Deben ser iguales']"
                          maxlength="7" background-color="blue lighten-5" v-mask-decimal.us="2" class="mr-1">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="7">
                        <v-text-field outlined dense v-model.trim="descripcion" label="Descripción"
                          :rules="[v => (v.length >= 3 && v.length <= 100) || 'La descripción debe tener de 3 a 100 caracteres', Rules.unespacio]"
                          maxlength="100" background-color="blue lighten-5" prepend-inner-icon="notes" class="mr-1"
                          v-uppercase></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field outlined dense v-model="fecha_comprobante_formateada" readonly v-on="on"
                              background-color="blue lighten-5" prepend-inner-icon="event"></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                            @change="fechaSeleccionada(fecha_comprobante)"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete outlined dense @keyup="selectPersona()" :search-input.sync="buscar_cliente"
                          v-model="idpersona" :items="personas" label="Razón Social"
                          :rules="[v => !!v || 'La razón social es requerido']" background-color="blue lighten-5"
                          clearable class="mr-2" append-outer-icon="person_add" @click:append-outer="mostrarPersona">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-select outlined dense v-model="comprobantes" :items="tipo_comprobantes"
                          label="Tipo Comprobante" :rules="[v => !!v || 'Comprobante requerido']"
                          @change="comprobanteSeleccionado(comprobantes)" background-color="blue lighten-5"
                          class="mr-1"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field outlined dense v-model="serie_comprobante" label="Serie Comprobante"
                          :rules="[v => v.length == 4 || 'Requerido', Rules.sinespacio]"
                          background-color="blue lighten-5" :disabled="disabled" maxlength="4" v-uppercase class="mr-1">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field outlined dense v-model="num_comprobante" label="Núm. Comprobante"
                          :rules="[v => !!v || 'Numero requerido']"
                          @blur.prevent="llenarCeroNumComprobante(num_comprobante)" background-color="blue lighten-5"
                          :disabled="disabled" v-mask-number></v-text-field>
                      </v-col>

                      <!-- Mensaje error detalle cuenta -->
                      <v-col cols="12" v-if="mensajeDetalleCuenta != ''">
                        <v-alert text dense type="error">{{ mensajeDetalleCuenta }}</v-alert>
                      </v-col>
                      <!-- Mensaje error detalle cuenta -->
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 px-4 justify-center">
                  <v-btn color="error" @click="close()" small>
                    <v-icon left>cancel</v-icon>Cancelar
                  </v-btn>
                  <v-btn color="primary" @click="guardar()" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    small>
                    <v-icon left>save</v-icon>Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal crear cuenta pagada -->

            <!-- Modal ver calendario -->
            <v-dialog v-model="calendarioModal" max-width="750px">
              <v-card>
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat color="white">
                      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Hoy</v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small>keyboard_arrow_left</v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" class="mr-4" @click="next">
                        <v-icon small>keyboard_arrow_right</v-icon>
                      </v-btn>
                      <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Dia</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Semana</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Mes</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 Dias</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600">
                    <v-calendar ref="calendar" v-model="focus" color="primary" :events="eventos_calendario"
                      :event-color="getEventColor" :type="type" :short-months="shortMonths"
                      :short-weekdays="shortWeekdays" @click:event="showEvent" @click:more="viewDay"
                      @click:date="viewDay"></v-calendar>
                    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"
                      offset-x>
                      <v-card color="grey lighten-4" min-width="350px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon>
                            <v-icon @click="selectedOpen = false">close</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-card-text>
                          Cuenta por pagar Nº
                          <span v-html="selectedEvent.details"></span>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-col>
              </v-card>
            </v-dialog>
            <!-- Fin Modal ver calendario-->
          </v-toolbar>
        </template>
        <!-- Opciones formatear fecha, moneda cuentas x pagar -->
        <template v-slot:item.fecha_vencimiento="{ item }">
          <template>
            <v-chip
              :color="(item.fecha_vencimiento.substr(0, 10) < fecha_actual ? 'error' : item.fecha_vencimiento.substr(0, 10) == fecha_actual ? 'warning' : 'success')">
              {{ item.fecha_vencimiento | formatearFecha }}</v-chip>
          </template>
        </template>
        <template v-slot:item.moneda="{ item }">
          <template v-if="item.moneda == 'S'">
            <span>Soles</span>
          </template>
          <template v-else>
            <span>Dolares</span>
          </template>
        </template>
        <template v-slot:item.importe="{ item }">
          <div class="text-right">
            <strong>
              <span>{{ item.moneda == "S" ? "S/" : "US$" }} {{ item.importe | formatearNumero }}</span>
            </strong>
          </div>
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="blue darken-2" @click="mostrarDetalleCuenta(item)">payments</v-icon>
          </v-btn>
        </template>
        <!-- Fin Opciones formatear fecha, moneda cuentas x pagar -->
      </v-data-table>

      <!--Fin Busqueda y listado detalle cuenta y ventana cuenta por pagar -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      cuentasPagadas: [],
      detalleCuenta: [],
      pagarCuentaModal: false,
      calendarioModal: false,

      cabeceraCuentasPagadas: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "MOVIMIENTO", value: "movimiento", sortable: false },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "IMPORTE", value: "pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraDetalleCuenta: [
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "NÚMERO. REF.", align: "center", value: "numero_comp_ref", sortable: false },
        { text: "CdPd", align: "center", value: "idpedido", sortable: false },
        { text: "CxP", align: "center", value: "numero", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
        { text: "OPC", value: "opciones", sortable: false },
      ],
      buscar_cuenta_pagada: "",
      buscar_detalle_cuenta: "",
      buscar_ubigeo: "",
      buscar_cliente: "",

      id: "",
      proveedor: "",

      idpedido: "",

      // Movimientos
      // Detalle cuenta guardar
      importe_total: "",
      idtipo_comprobante: "",
      comprobante: "",
      comprobantes: "", // Solo para visualizar en el select
      tipo_comprobantes: [],
      serie_comprobante: "",
      num_comprobante: "",
      ruc_comprobante: "",
      denom_comprobante: "",
      //Fin Detalle cuenta guardar
      fecha: "",
      movimiento: "",
      descripcion_caja: "",
      moneda: "",
      pago: "",

      // Detalle cuenta
      numero: "",
      fecha_vencimiento: "",
      importe: "",
      importev: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,

      idpersona: "",
      personas: [],

      idtipo_servicio: "", // Al guardar
      descripcion: "", // Al guardar
      fecha_actual: moment().format("YYYY-MM-DD"),

      fecha_comprobante_formateada: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),

      menuFecha: false,
      desabilitar: false,
      isValid: true,
      disabled: true, // Serie y numero comprobantes desabilitados

      mensajeDetalleCuenta: "",

      verMovimiento: 0,
      verDetalleCuenta: 0,

      comprobanteModal: 0,
      adModal: 0,
      adAccion: 0,
      adId: "",
      adFecha: "",

      alerta: { snackbar: false, color: "", text: "" },

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },

      // Calendario
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      eventos_calendario: [],
      shortMonths: false,
      shortWeekdays: false,
      name: "",
      details: "",
      colors: [
        "pink lighten-2",
        "blue",
        "indigo",
        "deep-purple",
        "teal",
        "cyan",
        "green",
        "light-blue",
        "orange",
        "grey",
        "brown",
        "deep-purple accent-2",
        "light-green",
      ],
      // Fin Calendario

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    pagarCuentaModal(val) {
      val || this.close();
    },
    calendarioModal(val) {
      val || this.close();
    },
  },

  created() {
    this.fechaSeleccionada(this.fecha_comprobante);
    this.listarCuentaPagada();
    this.select();
  },

  methods: {
    ...mapActions(["cargarNotificaciones"]),

    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName =
        "CuentaPagada-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    // Calendario
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    listarEventos() {
      let me = this;
      var detalleCxPArray = [];
      const eventos_calendario = [];

      axios
        .get(
          "api/Detalle_Cuentas/ListarDetalleCxP/" +
          parseInt(me.usuario.idsucursal)
        )

        .then(function (response) {
          detalleCxPArray = response.data;

          detalleCxPArray.map(function (x) {
            eventos_calendario.push({
              name: x.proveedor.substr(0, 25),
              details:
                x.numero +
                ": " +
                (x.moneda == "S" ? "S/ " : "US$ ") +
                numeral(x.importe).format("###,##0.00"),
              start: x.fecha_vencimiento.substr(0, 10) + " " + "10:00:00",
              end: x.fecha_vencimiento.substr(0, 10) + " " + "12:00:00",

              color:
                x.fecha_vencimiento.substr(0, 10) < me.fecha_actual
                  ? "error"
                  : x.fecha_vencimiento.substr(0, 10) == me.fecha_actual
                    ? "warning"
                    : me.colors[
                    Math.floor(
                      (me.colors.length - 1 - 0 + 1) * Math.random()
                    ) + 0
                    ], // Generamos un colors aleatorio
            });
          });
          me.eventos_calendario = eventos_calendario;
          // console.log(me.eventos_calendario);
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },
    // Fin Calendario

    listarCuentaPagada() {
      let me = this;
      me.loading = true;

      let deNotificacion = me.$route.params.cuentaxpagar; // Recibimos parametro cuentaxpagar=true de App notificaciones
      if (deNotificacion === true) {
        me.listarDetalleCuenta();
        me.verDetalleCuenta = 1;
        me.$route.params.cuentaxpagar = false;
      }

      axios
        .get("api/Movimientos/ListarCuentaPagadaFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar:
              me.buscar_cuenta_pagada == "" ? "-" : me.buscar_cuenta_pagada,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.cuentasPagadas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    selectPersona(id) {
      let me = this;

      var personasArray = []; // Limpiamos

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_cliente == ""
                  ? "-"
                  : me.buscar_cliente == null
                    ? "-"
                    : me.buscar_cliente,
              tipo_doc: "-",
            },
          })
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;
      var tipo_comprobantesArray = [];

      axios
        .get("api/Tipo_Comprobantes/SelectCompraCxP")
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            me.tipo_comprobantes.push({
              text: x.descripcion,
              value: x.idtipo_comprobante + "-" + x.descripcion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.id = item.idmovimiento;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;

      this.ruc_comprobante = item.ruc_comprobante;
      this.denom_comprobante = item.denom_comprobante;
      this.fecha = item.fecha;
      this.moneda = item.moneda;
      this.pago = item.pago;
      this.movimiento = item.movimiento;
      this.descripcion_caja = item.descripcion_caja;
      this.idpedido = item.idpedido; // Guardar

      this.proveedor = item.proveedor;
      this.importe_total = item.importe_total;
      this.crearPDF();
      this.comprobanteModal = 1;
    },

    // Modal Anular, cancelar cuenta pagada
    anular() {
      let me = this;

      axios
        .put(
          "api/Movimientos/AnularCuentaPagada/" +
          me.adId +
          "/" +
          me.adFecha +
          "/" +
          me.usuario.idsucursal +
          "/" +
          me.usuario.idusuario
        )
        .then(function (response) {
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
          me.listarCuentaPagada();
          me.cargarNotificaciones(); // Llamamos a actions para actualizar los notificaciones
        })
        .catch(function (error) {
          //console.log(error);
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
          me.adModal = 0;
          me.adAccion = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },
    anularMostrar(accion, item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
      if (accion == 1) {
        this.adAccion = 1;
      } else {
        this.adModal = 0;
      }
    },
    AnularCerrar() {
      this.adModal = 0;
    },
    // Fin Anular, cancelar cuenta pagada

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },

    /* PERSONA */
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "error";
            me.alerta.text = "Error en el Nº DNI.";
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text =
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion;
            } else {
              me.nombre = "";
              me.direccion = "";
              me.alerta.snackbar = true;
              me.alerta.color = "info";
              me.alerta.text = data.message; // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.alerta.snackbar = true;
            me.alerta.color = "erro";
            me.alerta.text = "Error en el Nº RUC.";
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      //Código para guardar
      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Proveedor",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectPersona(me.idpersona);
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data.mensaje;

          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = error.response.data;
        });
    },

    // Mostrar ventana persona
    mostrarPersona() {
      this.verPersona = true;
    },
    // Cerrar ventana persona
    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },
    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.nombre = "";
      this.idubigeo = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    /* FIN PERSONA */


    listarDetalleCuenta() {
      let me = this;
      me.loading = true;

      axios
        .get("api/Detalle_Cuentas/ListarDetalleCxPFiltro", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar:
              me.buscar_detalle_cuenta == "" ? "-" : me.buscar_detalle_cuenta,
          },
        })
        .then(function (response) {
          me.detalleCuenta = response.data;
          me.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.verDetalleCuenta = 1;
    },

    close() {
      this.pagarCuentaModal = false;
      this.calendarioModal = false;
      this.desabilitar = false; // Habilitamos boton guardar
      this.limpiar();
    },

    ocultarDetalleCuenta() {
      this.verDetalleCuenta = 0;
    },

    mostrarCalendario() {
      this.listarEventos();
      this.calendarioModal = true;
    },

    mostrarDetalleCuenta(item) {
      this.id = item.iddetalle_cuenta;
      this.proveedor = item.proveedor;
      this.numero = item.numero;
      this.fecha_vencimiento = item.fecha_vencimiento;
      this.importe_total = item.importe.toFixed(2);
      this.importev = parseFloat(item.importe); // Para validar el importe no sea >= a importev
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpedido = item.idpedido;
      this.pagarCuentaModal = true;
    },

    // Guardar cuenta pagada
    guardar() {
      let me = this;
      me.desabilitar = true;

      axios
        .post("api/Movimientos/CrearCuentaPagada", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          idpedido: me.idpedido,
          idtipo_servicio: me.idtipo_servicio, // Si la compra es soles tipo_servicio=3, cambiamos el tipo_servicio=7 -> Cta Pagada S/
          idtipo_comprobante: me.idtipo_comprobante,
          serie_comprobante: me.serie_comprobante,
          num_comprobante: me.num_comprobante,
          importe_total: parseFloat(me.importe_total),
          // detalle_credito
          iddetalle_cuenta: me.id,
          //Detalle Caja
          descripcion: me.descripcion,
          fecha_comprobante: me.fecha_comprobante,
          idpersona: me.idpersona,
        })
        .then(function (response) {
          me.alerta.snackbar = true;
          me.alerta.color = "success";
          me.alerta.text = response.data;
          me.close();
          me.limpiar();
          me.listarCuentaPagada();
          me.verDetalleCuenta = 0;
          me.cargarNotificaciones(); // Llamamos a actions para actualizar los notificaciones
        })
        .catch(function (error) {
          me.mensajeDetalleCuenta = error.response.data;
          me.limpiarAlertaError();
        });
    },

    // Llenar de cero el numero de comprobantes
    llenarCeroNumComprobante(num_comprobante) {
      this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
    },

    // Seleccionar comprobantes, activa y desactiva serie y numero de cromprobante automaticamente
    comprobanteSeleccionado(comprobantes) {
      let arrayComprobante = comprobantes.split(["-"]);
      this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
      this.comprobante = arrayComprobante[1]; // nombre documento

      if (this.idtipo_comprobante == "00") {
        // Si es Recibo interno
        this.disabled = true;
        this.serie_comprobante = "0000";
        this.num_comprobante = "00000000";
      } else {
        // Si es factura, boleta, guia
        this.disabled = false;
        this.serie_comprobante = "";
        this.num_comprobante = "";
      }
    },

    fechaSeleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    // Limpiar mensaje detalle cuenta
    limpiarAlertaError() {
      setTimeout(() => {
        this.mensajeDetalleCuenta = "";
      }, 3000);
      this.desabilitar = false; // Habilitamos boton guardar detalle cuenta
    },

    limpiar() {
      this.id = ""; // idmovimiento - Cuentas pagadas
      this.mensajeDetalleCuenta = ""; // Cuentas pagadas
      this.pago = 0; // De movimientos
      this.movimiento = ""; // cuenta pagada Proveedor - Cuentas pagadas
      this.descripcion_caja = "";
      this.comprobantes = "";
      this.idtipo_comprobante = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.fecha = "";
      this.moneda = "";

      this.idpersona = "";

      this.importe_total = "";
      this.importe = "";

      this.idtipo_servicio = "";

      // Detalle Caja
      this.descripcion = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaSeleccionada(this.fecha_comprobante);

      this.ruc_comprobante = "";
      this.denom_comprobante = "";

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";

      //this.eventos_calendario = []; // Limpiamos eventos array cargados al calendario
    },

    crearPDF() {
      var columns = [
        {
          header: "DESCRIPCIÓN",
          dataKey: "descripcion",
        },
        {
          header: "IMPORTE",
          dataKey: "pago",
        },
      ];
      var body = [
        {
          descripcion: this.movimiento + " - " + this.descripcion_caja,
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];
      var foot = [
        {
          descripcion: "Total " + (this.moneda == "S" ? "S/" : "US$"),
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Razón Social: " + this.denom_comprobante, 40, 75);
          doc.text("Ruc N°: " + this.ruc_comprobante, 40, 90);
          doc.text("Sucursal: " + this.datos.sucursal, 420, 75);
          doc.text(
            "Fecha: " + moment(this.fecha).format("DD/MM/YYYY"),
            420,
            90
          );

          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(
            this.comprobante,
            doc.internal.pageSize.width / 2 + 140,
            42,
            "center"
          );
          doc.text(
            this.serie_comprobante + "-" + this.num_comprobante,
            doc.internal.pageSize.width / 2 + 140,
            54,
            "center"
          );
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          descripcion: { cellWidth: "auto" },
          pago: { cellWidth: 50, halign: "right" },
        },
      });
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },
};
</script>
