import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// Opciones variables globales
import numeral from "numeral";
import moment from "moment";
import printJS from "print-js"; // Para impresion directa de pdf base64 -> Necesario
import autoTable from "jspdf-autotable"; // Generar Tabla de pdf de la par con jsPDF -> Necesario
import VueMask from "di-vue-mask"; //  Formatear numero de v-card-text a 1000.00 para evitar errores en el proyecto
Vue.use(VueMask); // di-vue-mask - Activate vue.js plugins
// Fin Opciones variables globales

Vue.config.productionTip = false
axios.defaults.baseURL = 'https://sis.biglacasadelreten.pe/'


axios.interceptors.request.use(
  function (config) {
    const auth_token = localStorage.getItem('token');
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    //console.log(config)
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// Para multiples requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {  // Ver si se tiene error con async fue sugerencia del editor vsc
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {

    if (isRefreshing) {
      try {
        const token = await new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        return axios(originalRequest)
      } catch (err) {
        return Promise.reject(err)
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;
    // En const tokens enviamos el token y refreshtoken para validar si son originales para luego actualizarlos
    const tokens = {
      token: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshtoken"),
    };
    return new Promise(function (resolve, reject) {
      axios.post("api/Usuarios/Refresh", tokens)
        .then(({ data }) => {

          const token = data.token;
          const refreshtoken = data.refreshtoken;
          const setting = data.setting;
          const avatar = data.avatar; // Para imagen de avatar usuario
          const logo = data.logo;
          store.dispatch("guardarToken", token);
          store.dispatch("guardarRefreshToken", refreshtoken);
          store.dispatch("guardarSetting", setting);
          store.dispatch("guardarAvatar", avatar);
          store.dispatch("guardarLogo", logo);
          //store.dispatch("cargarNotificaciones"); // Llamamos a cargarNotificaciones

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
          processQueue(null, data.token);
          resolve(axios(originalRequest));
          console.log("Token Actualizado");
        })
        .catch((err) => {
          console.log("Token Erroneo");
          processQueue(err, null);
          reject(err);
          store.dispatch("salir");
        })
        .then(() => { isRefreshing = false })
    })
  }
  return Promise.reject(error);
});

// Opciones variables globales
// Formatear Moneda
Vue.filter("formatearNumero", function (value) {
  return numeral(value).format("###,##0.00");
});
// Formatear Fecha
Vue.filter("formatearFecha", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
// Formatear Fecha
Vue.filter("formatearFechaHm", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

// Permite solo numeros y espacio se aplica en compras varios num_comprobante 
Vue.directive('numspace',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
        vnode.componentInstance.$emit('input', e.target.value.replace(/[^0-9 ]/g, ""))
      })
    }
  })
// Capitalizar primera letra string uso en descipciones caja y cuentas
/* Vue.directive('capitalize',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.replace(/^\w/, (c) => c.toUpperCase())
        vnode.componentInstance.$emit('input', e.target.value.replace(/^\w/, (c) => c.toUpperCase()))
      })
    }
  }) */
// Convertir string a minusculas se usa en email
Vue.directive('lowercase',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.toLowerCase()
        vnode.componentInstance.$emit('input', e.target.value.toLowerCase())
      })
    }
  })
// Convertir string a mayusculas en la mayoria de campos de repuesto y sus caracteristicas
Vue.directive('uppercase',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.toUpperCase()
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  })
// Fin Opciones variables globales

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
