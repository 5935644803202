<template>
  <v-layout align-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout"
        transition="slide-y-reverse-transition" right>
        <v-layout align-center pr-4>
          <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <!-- Modal crear persona -->
      <v-dialog v-model="verPersona" max-width="800px" persistent>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-form v-model="isValidPer">
              <v-row no-gutters class="mt-4">
                <v-col cols="6" sm="3">
                  <v-select outlined dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli"
                    label="Tipo Documento" :rules="[v => !!v || 'Tipo documento requerido']"
                    @change="num_documento = ''" background-color="blue lighten-5" class="pr-1"></v-select>
                </v-col>
                <v-col cols="10" sm="5">
                  <v-text-field outlined dense v-model.trim="num_documento" label="Numero Documento"
                    :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                    :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                    :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                    background-color="blue lighten-5" v-mask-number></v-text-field>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn x-small fab color="blue darken-3" class="ma-1 white--text" :loading="desabilitarBuscar"
                      :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                      @click="extraerDocumento(num_documento)">
                      <v-icon dark>search</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="nombre"
                    :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                    :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" background-color="blue lighten-5" prepend-inner-icon="person" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete outlined dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo"
                    v-model="idubigeo" :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                    background-color="blue lighten-5" prepend-inner-icon="pin_drop" clearable class="mr-1">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense v-model.trim="direccion" label="Direccion"
                    :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                    maxlength="200" background-color="blue lighten-5" prepend-inner-icon="place" v-uppercase>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="email" label="Email"
                    :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                    maxlength="60" background-color="blue lighten-5" prepend-inner-icon="mail" v-lowercase class="pr-1">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined dense v-model.trim="telefono" label="Telefono"
                    :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                    maxlength="30" background-color="blue lighten-5" prepend-inner-icon="phone" class="mr-1"
                    v-uppercase></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="closePersona" small>
              <v-icon left>cancel</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPersona" :loading="desabilitarPer"
              :disabled="desabilitarPer || !isValidPer" small>
              <v-icon left>save</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Fin Modal crear persona -->

      <!-- Buscar y seleccionar repuesto -->
      <v-dialog v-model="verRepuestos" max-width="1600px">
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Repuestos</v-toolbar-title>
            <v-spacer />
            <v-btn color="white" icon dark @click="ocultarRepuestos()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6" md="3" sm="8">
                <v-text-field outlined dense v-model.trim="brDescripcion" label="Descripción"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="1" class="hidden-sm-and-down">
                <v-text-field outlined dense v-model.trim="brInterior" label="Interior"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="1" class="hidden-sm-and-down">
                <v-text-field outlined dense v-model.trim="brExterior" label="Exterior"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @keyup="listarRepuestoBuscar"
                  v-uppercase></v-text-field>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="cat.nombre" :items="cat.categorias" label="Categoria"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="marc.nombre" :items="marc.marca_repuestos" label="Marca"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="hidden-sm-and-down">
                <v-autocomplete outlined dense v-model="veh.nombre" :items="veh.marca_vehiculos" label="Vehiculo"
                  background-color="blue lighten-5" hide-details class="my-3 mr-1" @change="listarRepuestoBuscar">
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="1" sm="4">
                <v-select outlined dense v-model="vts.nombre" :items="vts.ventas" label="Venta"
                  background-color="blue lighten-5" hide-details class="my-3"></v-select>
              </v-col>
            </v-row>

            <!-- Mostrar imagen de repuesto -->
            <v-dialog v-model="selectedZoom" max-width="350px">
              <v-avatar tile size="350">
                <img :src="'data:image/png;base64,' + imagen_zoom" />
              </v-avatar>
            </v-dialog>
            <!-- Fin Mostrar imagen de repuesto -->

            <v-data-table :headers="esAdministrador ? cabeceraBusquedaRepuestosAdm : cabeceraBusquedaRepuestos"
              :items="repuestos" item-key="iddetalle_compra" show-expand class="elevation-3" dense>
              <template v-slot:item.seleccionar="{ item }">
                <v-icon @click="agregarDetalle(item)" color="blue darken-3">add_circle</v-icon>
              </template>

              <template v-slot:item.imagen="{ item }">
                <template>
                  <v-avatar tile size="27">
                    <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                  </v-avatar>
                </template>
              </template>

              <template v-slot:item.precio_compra="{ item }">
                <div class="text-right">
                  <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}{{
                    item.precio_unit_compra | formatearNumero
                  }}</span>
                </div>
              </template>

              <template v-slot:item.precio_cambio="{ item }">
                <div class="text-right">
                  <span>S/{{
                    item.idtipo_servicio == 3 ? item.precio_unit_compra.toFixed(2) : ((item.precio_unit_compra)
                      * tc).toFixed(2)
                  }}</span>
                </div>
              </template>

              <template v-slot:item.precios_ventas="{ item }">
                <div class="text-right">
                  <template v-if="vts.nombre == 'precio_publico'">
                    <strong>S/{{ item.precio_publico | formatearNumero }}</strong>
                  </template>
                  <template v-if="vts.nombre == 'precio_tiendas'">
                    <strong>S/{{ item.precio_tiendas | formatearNumero }}</strong>
                  </template>
                  <template v-if="vts.nombre == 'precio_distribuidor'">
                    <strong>S/{{ item.precio_distribuidor | formatearNumero }}</strong>
                  </template>
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>UM:</strong>
                  {{ item.unidad_medida }}
                  <strong>VEHICULO:</strong>
                  {{ item.marca_vehiculo }}
                  <strong>MODELO:</strong>
                  {{ item.compatibilidad }}
                  <strong>PROCEDENCIA:</strong>
                  {{ item.procedencia }}
                  <strong>DESCRIPCIÓN:</strong>
                  {{ item.descripcion }}
                  <br />
                  <strong>PROVEEDOR:</strong>
                  {{ item.proveedor.substring(0, 30) }}
                  <strong>FEC.INGRESO:</strong>
                  {{ item.fecha | formatearFecha }}
                  <strong>Pedido:</strong>
                  {{ item.idpedido }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Buscar y seleccionar repuesto -->

      <!-- Listado de ventas -->
      <v-data-table :loading="loading" :headers="cabeceraListarVenta" :items="ventas" :items-per-page="15"
        item-key="idmovimiento" show-expand dense class="elevation-3"
        v-if="verNuevaVenta == 0 && verNuevaNotaCredito == 0">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="hidden-sm-and-down">VENTAS</v-toolbar-title>
            <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
            <v-spacer />
            <!-- Formulario busqueda de venta -->
            <v-text-field outlined dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar()"
              background-color="blue lighten-5" single-line hide-details></v-text-field>
            <v-spacer />
            <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
              min-width="290px" :close-on-click="dates.length == 2 ? true : false">
              <template v-slot:activator="{ on }">
                <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                  background-color="blue lighten-5" hide-details append-icon="update" @click:append="listar()">
                </v-text-field>
              </template>
              <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                @change="listar()" range></v-date-picker>
            </v-menu>
            <v-spacer />
            <div id="container" style="position: absolute; visibility: hidden;" class="qrcode" ref="qrCodeUrl"></div>
            <!-- Fin Formulario busqueda de venta -->
            <v-btn @click="mostrarNuevaVenta()" color="primary" class="ma-2" small>Nuevo</v-btn>

            <!-- Modal anular Venta -->
            <v-dialog v-model="adModal" max-width="300">
              <v-card>
                <v-card-title class="justify-center">Anular: {{ adComprobante }}</v-card-title>
                <v-card-text v-if="idtipo_comprobante != '00'">
                  <v-form v-model="isValid">
                    <v-text-field outlined dense class="text-xs-center" v-model.trim="motivo"
                      label="Motivo de anulación"
                      :rules="[v => !!v || 'El motivo es requerido', v => (v && v.length >= 5 && v.length <= 30) || 'El motivo debe tener de 5 a 30 caracteres', Rules.unespacio]"
                      background-color="blue lighten-5" maxlength="30" v-uppercase hide-details></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="blue darken-4" class="white--text" @click="anularCerrar" small>Cancelar</v-btn>
                  <v-btn color="red darken-1" class="white--text" :loading="desabilitar"
                    :disabled="desabilitar || !isValid" @click="anular()" small>Anular</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular Venta -->

            <!-- Modal enviar email Venta -->
            <v-dialog v-model="adModalEmailCliente" max-width="500" persistent>
              <v-card>
                <v-card-title class="text-h6">
                  {{ adComprobante }}
                  <v-spacer />
                  <v-btn icon @click="emailCerrar" small>
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle class="justify-center">{{ cliente }}</v-card-subtitle>
                <v-card-text>
                  <v-form v-model="isValid">
                    <v-text-field outlined dense class="text-xs-center" v-model.trim="email" label="Email"
                      :rules="[v => /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      background-color="blue lighten-5" maxlength="60" v-lowercase hide-details></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="green" class="white--text" :loading="desabilitar" :disabled="desabilitar || !isValid"
                    @click="enviarEmail()" small>
                    <v-icon left>send</v-icon>Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal enviar email Venta -->
          </v-toolbar>
        </template>
        <!-- Opciones para la lista de ventas -->
        <template v-slot:item.numero="{ item }">
          <template v-if="item.idtipo_comprobante != '00'">
            <strong>{{ item.serie_comprobante + "-" + item.num_comprobante }}</strong>
          </template>
          <template v-else>
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>
        </template>
        <template v-slot:item.cliente="{ item }">
          <span>{{ (item.cliente).substr(0, 20) }}</span>
        </template>
        <template v-slot:item.fecha="{ item }">
          <span>{{ item.fecha | formatearFecha }}</span>
        </template>
        <template v-slot:item.importe_total="{ item }">
          <div class="text-right">
            <span>{{ item.importe_total | formatearNumero }}</span>
          </div>
        </template>
        <!-- Estado de los comprobantes -->
        <template v-slot:item.estado="{ item }">
          <template v-if="item.estado == 'Aceptado'">
            <strong class="green--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Enviado'">
            <span class="blue--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por enviar'">
            <span class="brown--text">{{ item.estado }}</span>
          </template>
          <template v-else-if="item.estado == 'Por anular'">
            <strong class="orange--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Rechazado'">
            <strong class="red--text">{{ item.estado }}</strong>
          </template>
          <template v-else-if="item.estado == 'Anulado'">
            <strong class="red--text">{{ item.estado }}</strong>
          </template>
          <template v-else>
            <span>{{ item.estado }}</span>
          </template>
        </template>
        <!-- Fin Estado de los comprobantes -->
        <!-- Descarga de XML y CDR -->
        <template v-slot:item.descargas="{ item }">
          <template
            v-if="item.idtipo_comprobante == '01' || item.idtipo_comprobante == '03' || item.idtipo_comprobante == '07'">
            <template
              v-if="item.idtipo_comprobante == '01' || item.idtipo_comprobante == '03' || item.idtipo_comprobante == '07'">
              <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
            </template>
            <template
              v-if="item.idtipo_comprobante == '01' && item.estado == 'Aceptado' | item.estado == 'Anulado'
              || item.ref_comprobante.substring(0, 2) != '03' && item.idtipo_comprobante == '07' && item.estado == 'Aceptado' | item.estado == 'Anulado'">
              <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
            </template>
          </template>
        </template>
        <!-- Fin Descarga de XML y CDR -->
        <!-- Opciones de impresion, anular y cpe-->
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon>
            <v-icon color="purple darken-2" @click="mostrarComprobante(item)">print</v-icon>
          </v-btn>
          <!-- Anular Venta -->
          <template>
            <v-btn small icon v-if="item.idtipo_comprobante == '00' && item.estado == 'Creado' && item.idusuario == usuario.idusuario ||
            item.idtipo_comprobante == '01' && item.estado == 'Aceptado' && item.idusuario == usuario.idusuario ||
            item.idtipo_comprobante == '03' && item.estado == 'Aceptado' && item.idusuario == usuario.idusuario ||
            item.idtipo_comprobante == '07' && item.estado == 'Aceptado' && item.idusuario == usuario.idusuario">
              <v-icon color="red darken-2" @click="anularMostrar(item), isValid = true">delete</v-icon>
            </v-btn>
          </template>
          <!-- Anular Venta -->

          <!-- Menu para mas opciones de FE -->
          <template v-if="item.idtipo_comprobante == '01' && item.estado == 'Aceptado' | item.estado == 'Creado'
          || item.idtipo_comprobante == '03' && item.estado == 'Aceptado' | item.estado == 'Creado'
          || item.idtipo_comprobante == '07' && item.estado == 'Aceptado' | item.estado == 'Creado'">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small icon>
                  <v-icon v-on="on" color="blue darken-2">more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <!-- Reenviar Sunat -->
                <v-list-item link
                  v-if="item.idusuario == usuario.idusuario && item.estado == 'Creado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '07' & item.ref_comprobante.substring(0, 2) != '03'">
                  <v-list-item-content>
                    <v-list-item-title class="blue--text" @click="reenviarSunat(item)">Enviar a la SUNAT
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Reenviar Sunat -->

                <!-- Nota de Credito -->
                <v-list-item link
                  v-if="item.estado == 'Aceptado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '03' && item.ref_comprobante.substring(0, 2) != '07'">
                  <v-list-item-content>
                    <v-list-item-title class="blue--text" @click="verDetallesNotaCredito(item)">Generar NOTA DE CRÉDITO
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Nota de Credito -->

                <!-- Guia Remision -->
                <v-list-item link
                  v-if="item.estado == 'Aceptado' | item.estado == 'Creado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '03'">
                  <v-list-item-content>
                    <v-list-item-title class="blue--text" @click="irGuiaRemision(item)">Generar GUÍA REMISIÓN
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Guia Remision -->

                <!-- Referencia -->
                <v-list-item v-if="item.ref_comprobante != ''">
                  <v-list-item-content>
                    <v-list-item-title class="brown--text">Referencia: {{ item.ref_comprobante.substring(3, 16) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- Fin Referencia -->
              </v-list>
            </v-menu>
          </template>
          <!-- Fin Menu para mas opciones de FE -->
        </template>
        <!-- Fin Opciones de impresion, anular y cpe-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Registrado por:</strong>
            {{ item.usuario }}
            <br />
            <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
            {{ item.msj_cdr }}
          </td>
        </template>
        <!-- Fin Opciones para la lista de ventas -->
      </v-data-table>
      <!--Fin Listado de ventas -->

      <!-- Ventana crear nueva nota de credito -->
      <v-card v-if="verNuevaNotaCredito == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>Emitir Nota de Crédito</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevaNotaCredito">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-select outlined dense v-model="idtipo_nota_cred" :items="tipo_nota_creds"
                  @change="descripcionEmision(idtipo_nota_cred)" label="Motivo de Emisión"
                  :rules="[v => !!v || 'Motivo requerido']" background-color="blue lighten-5" class="mr-1"></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field outlined dense v-model.trim="doc_ref" label="Ref. Comprobante" maxlength="20"
                  background-color="blue lighten-5" v-uppercase disabled class="mr-1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select outlined dense v-model="idpersona" :items="clientes" label="Cliente"
                  :rules="[v => !!v || 'El cliente es requerido']" background-color="blue lighten-5" class="mr-1">
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesNC" :items="detalles" disable-pagination hide-default-footer
                  dense class="elevation-1">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="red darken-2" class="mr-2" @click="eliminarItemDetallePedido(detalles, item)"
                      :disabled="idtipo_nota_cred == '' ? true : idtipo_nota_cred != '07' ? true : false">delete_forever
                    </v-icon>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <span class="font-weight-bold">{{ item.stock_actual }}</span>
                  </template>

                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                      v-model.number="item.cantidad"
                      :rules="[v => (!!v && v > 0) || 'Requerido', item.cantidad <= item.cant_tope || 'Cantidad no puede ser > a stock']"
                      maxlength="6" hide-details class="center-input"
                      :disabled="idtipo_nota_cred == '' ? true : idtipo_nota_cred != '07' ? true : false" v-mask-number>
                    </v-text-field>
                  </template>
                  <template v-slot:item.precio_unit_venta="{ item }">
                    <v-text-field dense type="text" background-color="blue lighten-5" v-model="item.precio_unit_venta"
                      :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="7" hide-details class="right-input"
                      :disabled="idtipo_nota_cred == '' ? true : idtipo_nota_cred != '07' ? true : false"
                      v-mask-decimal.us="2"></v-text-field>
                  </template>

                  <!-- <template v-slot:item.descto_item="{ item }">
					          <span class="font-weight-bold">{{item.descto_item | formatearNumero }}</span>
                  </template>-->

                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{ redondear(item.cantidad * item.precio_unit_venta) |
                        formatearNumero
                      }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        v-mask-decimal.us="2"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>-->
                    <!-- <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        disabled
                        v-model="descto_item_total=redondear(parseFloat(calcularDescuentoItem)).toFixed(2)"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>-->
                    <v-col cols="2">
                      <v-text-field dense label="Placa" v-model="placa" v-uppercase disabled hide-details>
                      </v-text-field>
                    </v-col>
                    <v-col cols="8" align="end">
                      <strong>Gravada S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="subtotal_gravado = redondear(parseFloat(importe_total) / (1 + igv)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>IGV S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="igv_gravado = redondear(parseFloat(importe_total) - parseFloat(subtotal_gravado)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Importe Total S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="importe_total = redondear(parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global)).toFixed(2)"
                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="accent" @click="enviarNotaCreditoSunatBeta()" :loading="desabilitar"
            :disabled="desabilitar || !isValid" small v-if="datos.cpe == 'BETA'">
            <v-icon left>check</v-icon>Comprobar
          </v-btn>

          <v-btn color="error" @click="ocultarComprobanteNC()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarNotaCredito()" :loading="desabilitar"
            :disabled="desabilitar || !isValid" small>
            <v-icon left>save</v-icon>Crear N/C
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear nueva nota de credito -->

      <!-- Ventana crear nueva venta -->
      <v-card v-if="verNuevaVenta == 1" class="elevation-3">
        <v-toolbar dark dense class="primary" flat>
          <v-toolbar-title>Nueva Venta</v-toolbar-title>
        </v-toolbar>
        <v-card-text grid-list-sm v-if="verNuevaVenta">
          <v-form v-model="isValid">
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <v-select outlined dense v-model="idtipo_comprobante" :items="tipo_comprobantes"
                  label="Tipo Comprobante" :rules="[v => !!v || 'Comprobante requerido']"
                  @change="comprobanteSeleccionado(idtipo_comprobante)" background-color="blue lighten-5" class="mr-1">
                </v-select>
              </v-col>
              <v-col cols="12" sm="9">
                <v-autocomplete outlined dense @keyup="selectCliente()" @change="changeCliente"
                  :search-input.sync="buscar_cliente" v-model="idpersona" :items="clientes" label="Cliente"
                  :rules="[v => !!v || 'El cliente es requerido']" background-color="blue lighten-5" clearable
                  class="mr-2" append-outer-icon="person_add" @click:append-outer="mostrarPersona"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select outlined dense v-model="tipo_pago" :items="pagos" label="Tipo Pago"
                  :rules="[v => !!v || 'Tipo pago requerido']"
                  @change="tipo_pago == 'Contado' ? (total_pago = '') : (total_pago = calcularTotal.toFixed(2))"
                  background-color="blue lighten-5" v-mask-decimal.us="2" class="mr-1"></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <template>
                  <v-text-field outlined dense type="text" v-model="total_pago" label="Importe" prefix="S/"
                    maxlength="9"
                    :rules="[v => (!!v && parseFloat(v) >= parseFloat(importe_total).toFixed(2)) || 'El Importe no coincide']"
                    :disabled="tipo_pago == 'Contado' ? false : true" background-color="blue lighten-5"
                    v-mask-decimal.us="2" class="mr-1"></v-text-field>
                </template>
              </v-col>
              <v-col cols="10" md="3" sm="3" v-if="tipo_pago == 'Contado'">
                <v-chip class="ma-1" color="orange" label outlined>
                  <strong class="font-italic font-weight-bold">VUELTO: S/ {{ redondear(parseFloat(total_pago) -
                  parseFloat(importe_total)).toFixed(2) | formatearNumero }}</strong>
                </v-chip>
              </v-col>
              <v-col cols="10" md="3" sm="3"
                v-if="tipo_pago == 'Crédito' || tipo_pago == 'Tarjeta Visa' || tipo_pago == 'Tarjeta MC'">
                <template v-if="tipo_pago == 'Crédito'">
                  <v-menu v-model="menuCalendario" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined dense v-model="fecha_vencimiento_formateada" label="Fecha Vencimiento"
                        readonly v-on="on" background-color="blue lighten-5" prepend-inner-icon="event" class="mr-1">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="fecha_vencimiento" @input="menuCalendario = false"
                      @change="fechaseleccionada(fecha_vencimiento)"></v-date-picker>
                  </v-menu>
                </template>
                <template v-if="tipo_pago == 'Tarjeta MC' || tipo_pago == 'Tarjeta Visa'">
                  <v-text-field outlined dense v-model="num_operacion" label="Num. Operación"
                    :rules="[v => !!v || 'Numero Operación requerido']"
                    @blur.prevent="llenarCeroNumOperacion(num_operacion)" maxlength="10"
                    background-color="blue lighten-5" class="mr-1"></v-text-field>
                </template>
              </v-col>
              <v-spacer />
              <v-col cols="2" md="2" sm="2" class="hidden-sm-and-down">
                <v-text-field outlined dense @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
                  label="Código barra" background-color="blue lighten-5" maxlength="30"></v-text-field>
              </v-col>
              <v-col cols="2" sm="1">
                <div class="text-center">
                  <v-btn @click="mostrarRepuestos()" x-small fab dark color="blue darken-3" class="ma-1">
                    <v-icon dark>list</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12">
                <v-data-table :headers="cabeceraDetallesVenta" :items="detalles" disable-pagination hide-default-footer
                  dense class="elevation-1">
                  <template v-slot:item.num="{ item }">
                    <td>{{ detalles.indexOf(item) + 1 }}</td>
                  </template>
                  <template v-slot:item.borrar="{ item }">
                    <v-icon color="error" @click="eliminarItemDetallePedido(detalles, item)">delete_forever</v-icon>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <div class="text-center">
                      <span class="font-weight-bold">{{ item.stock_actual }}</span>
                    </div>
                  </template>
                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                      v-model.number="item.cantidad"
                      :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                      maxlength="6" hide-details class="center-input" v-mask-number></v-text-field>
                  </template>
                  <template v-slot:item.precio_unit_venta="{ item }">
                    <v-text-field dense type="text" v-model="item.precio_unit_venta"
                      :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details v-mask-decimal.us="2">
                    </v-text-field>
                  </template>
                  <!-- <template v-slot:item.descto_item="{ item }">
					          <span class="font-weight-bold">{{item.descto_item | formatearNumero }}</span>
                  </template>-->
                  <template v-slot:item.subtotal="{ item }">
                    <div class="text-right">
                      <span class="font-weight-bold">{{
                      redondear(item.cantidad * item.precio_unit_venta -
                        item.descto_item) |
                      formatearNumero }}</span>
                    </div>
                  </template>
                </v-data-table>
                <v-container>
                  <v-row no-gutters align="end" justify="end">
                    <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        v-mask-decimal.us="2"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>-->
                    <!-- <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        disabled
                        v-model="descto_item_total=redondear(parseFloat(calcularDescuentoItem)).toFixed(2)"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>-->
                    <v-col cols="2">
                      <v-text-field dense label="Placa" v-model="placa" maxlength="15" v-uppercase hide-details>
                      </v-text-field>
                    </v-col>
                    <v-col cols="8" align="end">
                      <strong>Gravada S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="subtotal_gravado = redondear(parseFloat(importe_total) / (1 + igv)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>IGV S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense disabled
                        v-model="igv_gravado = redondear(parseFloat(importe_total) - parseFloat(subtotal_gravado)).toFixed(2)"
                        class="right-input" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Importe Total S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field dense readonly @click="total_pago = parseFloat(importe_total).toFixed(2)"
                        v-model="importe_total = redondear(parseFloat(calcularTotal) - parseFloat(descto_global == '' ? 0 : descto_global)).toFixed(2)"
                        :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4 px-4 justify-center">
          <v-btn color="accent" @click="enviarDocumentoSunatBeta()" :loading="desabilitar"
            :disabled="desabilitar || !isValid || idtipo_comprobante == '00'" small v-if="datos.cpe == 'BETA'">
            <v-icon left>check</v-icon>Comprobar
          </v-btn>

          <v-btn color="error" @click="ocultarNuevaVenta()" small>
            <v-icon left>cancel</v-icon>Cancelar
          </v-btn>
          <v-btn color="primary" @click="guardarVenta()" :loading="desabilitar" :disabled="desabilitar || !isValid"
            small>
            <v-icon left>save</v-icon>Crear Venta
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Fin Ventana crear nueva venta -->

      <!-- Mostrar Modal Comprobante impresión -->
      <v-dialog v-model="comprobanteModal" max-width="400px" scrollable>
        <v-card>
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon color="white" @click="printExternal()">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="emailMostrar()" v-if="idtipo_comprobante != '00'">
              <v-icon>attach_email</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="savePdf()">
              <v-icon>download</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="crearPDFA4()">
              <v-icon>feed</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="ocultarComprobanteImp()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text style="height: 400px;" class="px-0">
            <pdf :src="stringPdf"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Fin Mostrar Modal Comprobante impresión -->
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";
import pdf from "vue-pdf";
import { mapGetters, mapState } from "vuex";
import QRCode from "qrcodejs2";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      ventas: [],
      cabeceraListarVenta: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false, width: 140 },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "IMPORTE S/", value: "importe_total", sortable: false },
        { text: "PAGO", value: "tipo_pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPCIÓNES", value: "opciones", sortable: false, width: 120 },
      ],

      cabeceraDetallesVenta: [
        { text: "#", value: "num", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
        { text: "UM", align: "center", value: "unidad_medida", sortable: false },
        { text: "CÓDIGO", value: "codigo_repuesto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "STOCK", value: "stock_actual", sortable: false },
        { text: "CANT", value: "cantidad", align: "center", sortable: false },
        { text: "PRECIO UNIT.", value: "precio_unit_venta", sortable: false },
        //{ text: "DESCUENTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaRepuestosAdm: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", sortable: false, align: "center" },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "TP", value: "forma", sortable: false },
        { text: "MT", value: "material", sortable: false },
        { text: "GR", value: "giro", sortable: false },
        { text: "INT", align: "center", value: "interior", sortable: false },
        { text: "EXT", align: "center", value: "exterior", sortable: false },
        { text: "ALT", align: "center", value: "altura", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "P.COMPRA", value: "precio_compra", sortable: false },
        { text: "CAMBIO", value: "precio_cambio", sortable: false },
        { text: "VENTA", value: "precios_ventas", sortable: false },
      ],

      cabeceraBusquedaRepuestos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", sortable: false, align: "center" },
        { text: "IMG", value: "imagen", sortable: false, align: "center" },
        { text: "COD.REPUESTO", value: "codigo_repuesto", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca_repuesto", sortable: false },
        { text: "TP", value: "forma", sortable: false },
        { text: "MT", value: "material", sortable: false },
        { text: "GR", value: "giro", sortable: false },
        { text: "INT", align: "center", value: "interior", sortable: false },
        { text: "EXT", align: "center", value: "exterior", sortable: false },
        { text: "ALT", align: "center", value: "altura", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "VENTA", value: "precios_ventas", sortable: false },
      ],

      cabeceraDetallesNC: [
        { text: "#", value: "num", sortable: false },
        { text: "OPC", value: "borrar", sortable: false },
        { text: "CÓDIGO", value: "codigo_repuesto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "CANTIDAD", value: "cantidad", sortable: false },
        { text: "PRECIO", value: "precio_unit_venta", sortable: false },
        //{ text: "DESCTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      detalles: [],
      detalle_pedidos: [],
      buscar: "",
      buscar_cliente: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      repuestos: [],

      brInterior: "",
      brExterior: "",
      brDescripcion: "",
      cat: { nombre: "", categorias: [{ text: "", value: "" }] },
      marc: { nombre: "", marca_repuestos: [{ text: "", value: "" }] },
      veh: { nombre: "", marca_vehiculos: [{ text: "", value: "" }] },

      vts: {
        nombre: "precio_publico",
        ventas: [
          { text: "Publico", value: "precio_publico" },
          { text: "Tienda", value: "precio_tiendas" },
          { text: "Distribuidor", value: "precio_distribuidor" },
        ],
      },

      idmovimiento: "",
      idpedido: 0,
      sucursal: "",
      cliente: "",
      fecha: "",
      num_documento: "",
      importe_letras: "",
      direccion: "",
      telefono: "",
      email: "",
      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",
      moneda: "",
      igv: 0,

      placa: "",
      descto_global: 0,
      descto_item_total: 0,
      subtotal_gravado: 0,
      igv_gravado: 0,
      importe_total: 0,

      ref_comprobante: "",

      idtipo_documento: "",
      idpersona: "",
      clientes: [],

      total_pago: 0,
      num_operacion: "",
      // Ventas
      verProductos: 0,
      brDescripcion: "",
      selectedZoom: false,
      imagen_zoom: "",
      tc: 0,

      verRepuestos: 0, // Para mostrar dialog buscar repuesto

      verNuevaVenta: 0,
      tipo_pago: "",
      pagos: ["Contado", "Crédito", "Tarjeta MC", "Tarjeta Visa"],
      idtipo_comprobante: "", // 00=Recibo Interno, 01=Factura, 03=Boleta de Venta
      tipo_comprobantes: [],
      iddetalle_comprobante_sucursal: "",

      fecha_vencimiento_formateada: "",
      fecha_vencimiento: moment().format("YYYY-MM-DD"),
      fecha_actual: moment().format("YYYY-MM-DD"),

      menuCalendario: false,

      comprobanteModal: 0,
      verNuevaNotaCredito: 0, // Para mostrar ventana nota de credito

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos
      disabled: true, // Serie y numero comprobante desabilitados

      adModal: 0,
      adComprobante: 0,
      motivo: "",

      adModalEmailCliente: 0,

      stringPdf: "",
      blobPdf: "",

      doc_ref: "", // Numero de comprobante FE referencia
      cod_doc_ref: "", // Codigo de tipo documento FE referencia

      idtipo_nota_cred: "",
      tipo_nota_creds: [],

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona

      snackbar: {
        visible: false,
        color: "",
        text: "",
        icon: "",
        timeout: 2000,
      },

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado =
          resultado +
          me.redondear(
            parseInt(
              me.detalles[i].cantidad == "" ? 0.0 : me.detalles[i].cantidad
            ) *
            parseFloat(
              me.detalles[i].precio_unit_venta == ""
                ? 0.0
                : me.detalles[i].precio_unit_venta
            ) -
            parseFloat(
              me.detalles[i].descto_item == ""
                ? 0.0
                : me.detalles[i].descto_item
            )
          );
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobanteImp();
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.fechaseleccionada(this.fecha_vencimiento);
    this.listar();
    this.select();
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    redondear: function (numero) {
      let entero = Math.round(Number(numero + "e+" + 2));
      let redondeado = Number(entero + "e-" + 2);
      return redondeado;
    },

    //#region LISTAR, REENVIAR Y DESCARGAR VENTAS
    listar() {
      let me = this;
      me.loading = true;

      let adVenta = this.$route.params.adventa;
      let dePedido = this.$route.params.depedido; // true
      let deidpersona = this.$route.params.deidpersona;
      let deidpedido = this.$route.params.deidpedido;

      if (adVenta === true) {
        me.verNuevaVenta = 1;
        me.$route.params.adventa = false;
      }

      if (dePedido == true) {
        me.idpersona = deidpersona;
        me.selectCliente(deidpersona);
        me.idpedido = deidpedido;
        me.listarDetallePedidos(deidpedido);
        me.verNuevaVenta = 1;
        me.$route.params.depedido = false;
      }

      if (me.buscar == "") {
        axios
          .get("api/Movimientos/ListarVenta", {
            params: {
              idsucursal: parseInt(me.usuario.idsucursal),
              idusuario: parseInt(me.usuario.idusuario),
              buscar: me.buscar == "" ? "-" : me.buscar,
              fechaInicio: me.dates[0],
              fechaFin:
                me.dates[1] == undefined
                  ? me.dates[0] + " 23:59:59"
                  : me.dates[1] + " 23:59:59",
            },
          })
          .then(function (response) {
            me.ventas = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Movimientos/ListarVentaTotal", {
            params: {
              idsucursal: parseInt(me.usuario.idsucursal),
              buscar: me.buscar == "" ? "-" : me.buscar,
              fechaInicio: me.dates[0],
              fechaFin:
                me.dates[1] == undefined
                  ? me.dates[0] + " 23:59:59"
                  : me.dates[1] + " 23:59:59",
            },
          })
          .then(function (response) {
            me.ventas = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    // Seleccionar comprobantes
    comprobanteSeleccionado(idtipo_comprobante) {
      if (idtipo_comprobante != null) {
        const selectId = this.tipo_comprobantes.find(
          (elemento) => elemento.value === idtipo_comprobante
        );
        this.iddetalle_comprobante_sucursal =
          selectId.iddetalle_comprobante_sucursal;
      }
      if (this.idtipo_comprobante == "01") {
        if (this.idtipo_documento == 6) {
          //console.log("comprobante valido para factura");
        } else {
          this.clientes = [];
          this.idpersona = "";
        }
      }
      // Si es Recibo Interno igv = 0 de lo contrario igv=0.18
      this.idtipo_comprobante == "00" // Recibo Interno
        ? (this.igv = 0)
        : (this.igv = parseFloat(this.datos.igv));
    },

    irGuiaRemision(item) {
      this.$router.push({
        name: "guias_remision",
        params: {
          venta: true,
          idmovimientoventa: item.idmovimiento,
          idpersonaventa: item.idpersona,
          idpedidoventa: item.idpedido,
          numero: item.serie_comprobante + "-" + item.num_comprobante,
          idmotivotraslado: "01",
        },
      });
    },

    reenviarSunat(item) {
      let me = this;
      if (item.idtipo_comprobante == "01") {
        axios
          .put("api/Movimientos/ReenviarVentaSunat/" + item.idmovimiento)

          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      } else if (item.idtipo_comprobante == "07") {
        axios
          .put("api/Movimientos/ReenviarNotaCreditoSunat/" + item.idmovimiento)

          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      } else {
        // console.log("error"); // No es Factura ni Nota de credito
      }
    },

    enviarDocumentoSunatBeta() {
      let me = this;
      if (me.idtipo_comprobante == "00") {
        // 0='Recibo Interno'
        return me.SnackbarShow(
          "warning",
          "El Comprobante no es valido para enviar a Sunat Beta."
        );
      }

      me.detalle_pedidos = []; // Limpiamos array para llenarlo
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para venta

      axios
        .post("api/Movimientos/EnviarSunatBeta", {
          // Enviamos a SERVICIO_CPE="BETA"

          // Para actualizar detalle_documento_sucursal
          iddetalle_comprobante_sucursal: me.iddetalle_comprobante_sucursal,
          subtotal_gravado: parseFloat(me.subtotal_gravado), ///
          igv_gravado: parseFloat(me.igv_gravado), ///
          importe_total: parseFloat(me.importe_total), ///
          placa: me.placa,

          idtipo_comprobante: me.idtipo_comprobante, // boleta = 01 o factura = 03

          // Datos del Cliente Receptor
          IDPERSONA: me.idpersona, // NRO_DOCUMENTO_CLIENTE, RAZON_SOCIAL_CLIENTE, DIRECCION_CLIENTE
          idtipo_documento: me.idtipo_documento, /// 1 = dni, 6 = ruc

          // Detalle de nuestra FE
          detalle_pedidos: me.detalle_pedidos,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data); // Error de Pc
        });
    },

    guardarVenta() {
      let me = this;

      // Validamos si el detalle de pedido esta vacio
      if (me.detalles.length <= 0) {
        return me.SnackbarShow(
          "warning",
          "Ingrese al menos un producto al detalle."
        );
      }
      if (me.detalles.length > 30) {
        return me.SnackbarShow(
          "warning",
          "El detalle solo acepta hasta 30 items."
        );
      }
      // Validamos que la fecha de vencimiento sea mayor a la fecha actual
      if (
        me.tipo_pago == "Crédito" &&
        Date.parse(me.fecha_vencimiento) <= Date.parse(me.fecha_actual)
      ) {
        return me.SnackbarShow("warning", "Modificar la fecha de vencimiento.");
      }

      // Si total_pago es menor al importe_total rechazamos
      if (parseFloat(me.total_pago) - parseFloat(me.importe_total) < 0) {
        return me.SnackbarShow("warning", "Verifique el importe.");
      }

      me.desabilitar = true; // Desabilitamos boton guardar
      me.detalle_pedidos = []; // Limpiamos array para llenarlo
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para venta

      axios
        .post("api/Movimientos/CrearVenta", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona, //FE
          idusuario: parseInt(me.usuario.idusuario),
          idpedido: me.idpedido,
          tipo_pago: me.tipo_pago, // Para tabla Movimientos
          num_operacion:
            me.tipo_pago == "Tarjeta MC"
              ? me.num_operacion
              : me.tipo_pago == "Tarjeta Visa"
                ? me.num_operacion
                : "",
          idtipo_comprobante: me.idtipo_comprobante, // boleta = 01 o factura = 03
          igv: parseFloat(me.igv),
          descto_global: 0,
          descto_item_total: 0,
          seguro: 0,
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          efectivo: me.tipo_pago == "Contado" ? parseFloat(me.total_pago) : 0, // Para campo en db efectivo
          placa: me.placa,

          detalle_pedidos: me.detalle_pedidos,
          fecha_vencimiento: me.fecha_vencimiento, // Fecha pago de credito

          // Para actualizar detalle_documento_sucursal -> comprobantes ultima_serie, ultimo_numero
          iddetalle_comprobante_sucursal: me.iddetalle_comprobante_sucursal,
        })
        .then(function (response) {
          me.ocultarNuevaVenta();
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta);
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
          me.listar(); // Si sale error se actualiza pedido deberia ir a ventas
        });
    },

    convertirDetalleCPE() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_pedidos.push({
          idrepuesto: me.detalles[i].idrepuesto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          unidad_medida: me.detalles[i].unidad_medida,
          cantidad: parseInt(me.detalles[i].cantidad),
          valor_unit_venta: me.redondear(
            parseFloat(me.detalles[i].precio_unit_venta) / (1 + me.igv)
          ),
          precio_unit_venta: parseFloat(me.detalles[i].precio_unit_venta),
          descto_item: parseFloat(
            me.detalles[i].descto_item == "" ? 0 : me.detalles[i].descto_item
          ),
          valor_venta: me.redondear(
            (parseInt(me.detalles[i].cantidad) *
              parseFloat(me.detalles[i].precio_unit_venta)) /
            (1 + me.igv)
          ),
          precio_venta: me.redondear(
            parseInt(me.detalles[i].cantidad) *
            parseFloat(me.detalles[i].precio_unit_venta)
          ),
          igv: me.igv * 100, // FE 18%
          codigo_repuesto: me.detalles[i].codigo_repuesto, // FE
          descripcion: me.detalles[i].descripcion,
          idprod_sunat: me.detalles[i].idprod_sunat, // FE
        });
      }
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_vencimiento_formateada = ddmmyy;
    },

    // Llenar de cero el numero de operación tarjeta
    llenarCeroNumOperacion(num_operacion) {
      this.num_operacion = ("0000000000" + num_operacion).slice(-10);
    },

    mostrarNuevaVenta() {
      this.verNuevaVenta = 1;
    },

    ocultarNuevaVenta() {
      this.verNuevaVenta = 0;
      this.desabilitar = false; // Habilitamos boton guardar venta
      this.limpiar();
    },

    //#region REPUESTO
    buscarCodigoBarra() {
      let me = this;

      axios
        .get(
          "api/Pedidos/BuscarCodigoBarraVenta/" +
          parseInt(me.usuario.idsucursal) +
          "/" +
          me.codigo_barra
        )
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
        })
        .catch(function (error) {
          //console.log(error);
          me.SnackbarShow(
            "warning",
            "No hay registro de compra para el repuesto"
          );
        });
    },
    // listar busqueda de repuesto
    listarRepuestoBuscar() {
      let me = this;

      axios
        .get("api/Pedidos/ListarRepuestoVenta", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
            interior: me.brInterior == "" ? "-" : me.brInterior,
            exterior: me.brExterior == "" ? "-" : me.brExterior,
            categoria:
              me.cat.nombre == ""
                ? "-"
                : me.cat.nombre == null
                  ? "-"
                  : me.cat.nombre,
            marca:
              me.marc.nombre == ""
                ? "-"
                : me.marc.nombre == null
                  ? "-"
                  : me.marc.nombre,
            vehiculo:
              me.veh.nombre == ""
                ? "-"
                : me.veh.nombre == null
                  ? "-"
                  : me.veh.nombre,
          },
        })
        .then(function (response) {
          me.repuestos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    }, // Fin lista busqueda de repuesto

    // Agregar a detalle de pedido
    agregarDetalle(data = []) {
      let me = this;

      if (me.encuentra(data["iddetalle_compra"])) {
        me.SnackbarShow("warning", "El repuesto ya fue agregado.");
      } else {
        me.detalles.push({
          idrepuesto: data["idrepuesto"],
          iddetalle_compra: data["iddetalle_compra"],
          codigo_repuesto: data["codigo_repuesto"], // FE
          //categoria: data["categoria"],
          //marca_repuesto: data["marca_repuesto"],
          descripcion:
            data["categoria"] +
            " " +
            data["marca_repuesto"] +
            " " +
            data["descripcion"],
          stock_actual: data["stock_actual"],
          unidad_medida: data["unidad_medida"],
          cantidad: 1,
          valor_unit_venta: 0,
          precio_unit_venta: data[me.vts.nombre].toFixed(2),
          descto_item: (0).toFixed(2),
          valor_venta: 0,
          precio_venta: 0,
          idprod_sunat: data["idprod_sunat"],
          // Repuesto
          precio_publico: data["precio_publico"].toFixed(2),
          precio_tiendas: data["precio_tiendas"].toFixed(2),
          precio_distribuidor: data["precio_distribuidor"].toFixed(2),
        });
      }
    },

    // Busca en detalle de pedido si existe el repuesto
    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].iddetalle_compra == id) {
          sw = 1;
        }
      }
      return sw;
    },

    // Mostrar ventana repuesto
    mostrarRepuestos() {
      this.verRepuestos = 1;
    },
    // Ocultar ventana repuesto
    ocultarRepuestos() {
      this.verRepuestos = 0;
      this.imagen_zoom = "";

      this.repuestos = [];
      this.brInterior = "";
      this.brExterior = "";
      this.brDescripcion = "";
      this.cat.nombre = "";
      this.marc.nombre = "";
      this.veh.nombre = "";
      this.vts.nombre = "precio_publico";
    },
    //#endregion FIN REPUESTO

    descargarXml(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarXml/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            me.datos.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    descargarCdr(item) {
      let me = this;

      axios
        .get("api/Movimientos/DescargarCdr/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "R-" +
            me.datos.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    /* Modal Anular, cancelar venta */
    anular() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton guardar

      if (me.idtipo_comprobante == "00") {
        axios
          .put("api/Movimientos/AnularVenta/" + me.idmovimiento + "/" + me.usuario.idsucursal + "/" + me.usuario.idusuario)
          .then(function (response) {
            me.SnackbarShow("success", response.data);
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            //console.log(error);
            me.SnackbarShow("error", error.response.data);
          });
      } else if (me.idtipo_comprobante == "03" || me.idtipo_comprobante == "07" && me.adComprobante.substring(0, 1) == "B") {
        axios
          .put("api/Movimientos/CrearResumenBaja", {
            idmovimiento: me.idmovimiento,
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            motivo: me.motivo,
          })
          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      } else if (me.idtipo_comprobante == "01" || me.idtipo_comprobante == "07" && me.adComprobante.substring(0, 1) == "F") {
        axios
          .put("api/Movimientos/CrearComunicacionBaja", {
            idmovimiento: me.idmovimiento,
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            motivo: me.motivo,
          })
          .then(function (response) {
            me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            me.SnackbarShow("error", error.response.data);
          });
      }
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.idmovimiento = item.idmovimiento;
      this.adComprobante = item.serie_comprobante + "-" + item.num_comprobante;
      this.idtipo_comprobante = item.idtipo_comprobante;
    },

    anularCerrar() {
      this.adModal = 0;
      this.idmovimiento = "";
      this.idtipo_comprobante = "";
      this.desabilitar = false;
      this.motivo = "";
    },
    //#endregion LISTAR, REENVIAR Y DESCARGAR VENTAS

    //#region TODO SELECT
    descripcionEmision(valor) {
      if (valor != null) {
        const selectId = this.tipo_nota_creds.find(
          (elemento) => elemento.value === valor
        );
        this.motivo = selectId.text;
      }
    },

    changeCliente(valor) {
      if (valor != null) {
        const selectId = this.clientes.find(
          (elemento) => elemento.value === valor
        );
        this.idtipo_documento = selectId.tipo_documento;
        //console.log(this.idtipo_documento) // mostrar que tipo documento de cliente 1 ó 6
      }
    },

    selectCliente(id) {
      let me = this;

      var clientesArray = []; // Limpiamos

      if (id) {
        axios
          .get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id);
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios
          .get("api/Personas/SelectPersonasFiltro", {
            params: {
              buscar:
                me.buscar_cliente == ""
                  ? "-"
                  : me.buscar_cliente == null
                    ? "-"
                    : me.buscar_cliente,
              tipo_doc:
                me.idtipo_comprobante == ""
                  ? "-"
                  : me.idtipo_comprobante == null
                    ? "-"
                    : me.idtipo_comprobante == "01"
                      ? "RUC"
                      : "-",
            },
          })
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id); // En observacion
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios
          .get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios
          .get("api/Ubigeos/SelectUbigeosFiltro", {
            params: {
              buscar:
                me.buscar_ubigeo == ""
                  ? "-"
                  : me.buscar_ubigeo == null
                    ? "-"
                    : me.buscar_ubigeo,
            },
          })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;

      var tipo_comprobantesArray = [];
      axios
        .get(
          "api/Detalle_Comprobante_Sucursales/SelectDetalleComprobanteSucursales/" +
          parseInt(me.usuario.idsucursal)
        )
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            me.tipo_comprobantes.push({
              text: x.comprobante,
              value: x.idtipo_comprobante,
              iddetalle_comprobante_sucursal: x.iddetalle_comprobante_sucursal,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_nota_credsArray = [];
      axios
        .get("api/Tipo_Nota_Creds/Select")
        .then(function (response) {
          tipo_nota_credsArray = response.data;
          tipo_nota_credsArray.map(function (x) {
            me.tipo_nota_creds.push({
              text: x.descripcion,
              value: x.idtipo_nota_cred,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var categoriasArray = [];
      axios
        .get("api/Categorias/Select")
        .then(function (response) {
          categoriasArray = response.data;
          categoriasArray.map(function (x) {
            me.cat.categorias.push({ text: x.nombre, value: x.nombre });
          });
        })
        .catch(function (error) { });

      var marca_repuestosArray = [];
      axios
        .get("api/Marca_Repuestos/Select")
        .then(function (response) {
          marca_repuestosArray = response.data;
          marca_repuestosArray.map(function (x) {
            me.marc.marca_repuestos.push({
              text: x.nombre,
              value: x.nombre,
            });
          });
        })
        .catch(function (error) { });

      var marca_vehiculosArray = [];
      axios
        .get("api/Marca_Vehiculos/Select")
        .then(function (response) {
          marca_vehiculosArray = response.data;
          marca_vehiculosArray.map(function (x) {
            me.veh.marca_vehiculos.push({
              text: x.nombre,
              value: x.nombre,
            });
          });
        })
        .catch(function (error) { });

      var tipo_documentosArray = [];
      axios
        .get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion TODO SELECT

    //#region NOTA CREDITO
    enviarNotaCreditoSunatBeta() {
      let me = this;

      me.detalle_pedidos = []; // Limpiamos array para llenarlo
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para venta

      axios
        .post("api/Movimientos/EnviarNotaCreditoBeta", {
          // Enviamos a probar
          idsucursal: parseInt(me.usuario.idsucursal),
          subtotal_gravado: parseFloat(me.subtotal_gravado), ///
          igv_gravado: parseFloat(me.igv_gravado), ///
          importe_total: parseFloat(me.importe_total), ///
          placa: me.placa,
          //NRO_COMPROBANTE: "FF01-" + "1", /// FF01-1 Nota de credito

          // Datos comprobante de referencia para nota de credito
          NRO_OTR_COMPROBANTE: me.doc_ref,
          COD_OTR_COMPROBANTE: me.cod_doc_ref,

          COD_TIPO_MOTIVO: me.idtipo_nota_cred,
          DESCRIPCION_MOTIVO: me.removeAccents(me.motivo.toUpperCase()),

          // Datos del Cliente Receptor
          IDPERSONA: me.idpersona, // NRO_DOCUMENTO_CLIENTE, RAZON_SOCIAL_CLIENTE, DIRECCION_CLIENTE
          idtipo_documento: me.idtipo_documento, /// 1 = dni, 6 = ruc

          // Detalle de nuestra FE
          detalle_pedidos: me.detalle_pedidos,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    guardarNotaCredito() {
      let me = this;

      me.desabilitar = true; // Desabilitamos boton guardar
      me.detalle_pedidos = []; // Limpiamos
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para NC

      axios
        .post("api/Movimientos/CrearNotaCredito", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona, //FE
          idusuario: parseInt(me.usuario.idusuario),

          igv: parseFloat(me.igv),
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          placa: me.placa,
          idreferencia: me.idmovimiento,
          ref_comprobante: me.cod_doc_ref + "-" + me.doc_ref,
          idtipo_nota_cred: me.idtipo_nota_cred,
          motivo: me.removeAccents(me.motivo.toUpperCase()),
          detalle_pedidos: me.detalle_pedidos,
        })
        .then(function (response) {
          me.ocultarComprobanteNC();
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta);
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    verDetallesNotaCredito(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.igv = parseFloat(item.igv);
      this.idmovimiento = item.idmovimiento; // Venta
      this.doc_ref = item.serie_comprobante + "-" + item.num_comprobante;
      this.cod_doc_ref = item.idtipo_comprobante; // boleta = 03 o factura = 01
      this.idpersona = item.idpersona;
      this.placa = item.placa;
      this.selectCliente(item.idpersona);
      this.listarDetallePedidos(item.idpedido);
      this.verNuevaNotaCredito = 1;
    },

    ocultarComprobanteNC() {
      this.verNuevaNotaCredito = 0;
      this.desabilitar = false; // Habilitamos boton guardar NC
      this.limpiar();
    },
    //#endregion NOTA CREDITO

    //#region MOSTRAR COMPROBANTE IMPRESION
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.sucursal = item.sucursal;
      this.idtipo_comprobante = item.idtipo_comprobante;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.subtotal_gravado = item.subtotal_gravado;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;
      this.importe_letras = item.importe_letras;
      this.placa = item.placa;
      this.hash_cpe = item.hash_cpe;
      this.ref_comprobante = item.ref_comprobante;
      this.motivo = item.motivo;

      this.idpedido = item.idpedido;
      this.listarDetallePedidos(item.idpedido);
      // Cliente
      this.idtipo_documento = item.idtipo_documento;
      this.cliente = item.cliente;
      this.num_documento = item.num_documento;
      this.direccion = item.direccion;
      this.nom_ubigeo = item.nom_ubigeo;
      this.telefono = item.telefono;
      this.email = item.email;
      this.fecha = item.fecha;

      this.igv = item.igv;
      this.moneda = item.moneda;

      this.comprobanteModal = 1;
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },

    ocultarComprobanteImp() {
      this.comprobanteModal = 0;
      // Limpiamos div carga de qr
      document.getElementById("container").innerHTML = "";
      this.limpiar();
    },
    //#endregion MOSTRAR COMPROBANTE IMPRESION

    //#region DETALLES PEDIDO VENTA, NOTA DE CREDITO, IMPRESION
    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios
        .get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_unit_venta
          detallesArray.map(function (x) {
            me.detalles.push({
              idrepuesto: x.idrepuesto,
              unidad_medida: x.unidad_medida,
              codigo_repuesto: x.codigo_repuesto,
              descripcion:
                x.categoria + " " + x.marca_repuesto + " " + x.descripcion,
              repuesto: x.repuesto,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              cantidad: x.cantidad,
              cant_tope: x.cantidad,
              precio_unit_venta: x.precio_unit_venta.toFixed(2),
              descto_item: x.descto_item,
              precio_venta: x.precio_venta,
              idprod_sunat: x.idprod_sunat,
            });
          });
          me.crearPDF();
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion DETALLES PEDIDO VENTA, NOTA DE CREDITO, IMPRESION

    //#region ENVIAR EMAIL
    enviarEmail() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton enviar

      axios
        .put("api/Movimientos/EnviarEmail", {
          idmovimiento: me.idmovimiento,
          cliente: me.cliente,
          email: me.email,
        })
        .then(function (response) {
          me.SnackbarShow(response.data.rptcolor, response.data.respuesta); // Respuesta de sunat
          me.emailCerrar();
        })
        .catch(function (error) {
          me.SnackbarShow("error", error.response.data);
        });
    },

    emailMostrar() {
      this.adModalEmailCliente = 1;
      this.adComprobante = this.serie_comprobante + "-" + this.num_comprobante;
    },

    emailCerrar() {
      this.adModalEmailCliente = 0;
      this.desabilitar = false;
    },
    //#endregion ENVIAR EMAIL

    /* PERSONA */
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;
      // El token se genero con el correo jfrain@hotmail.es de https://apisperu.com
      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de 000000
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº DNI.");
          });
      } else if (me.idtipo_documento_pers == 6) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/ruc/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = data.ubigeo;
              me.selectUbigeo(me.idubigeo); // Buscamos ubigeo de la empresa en la DB
              me.nombre = data.razonSocial;
              me.direccion = data.direccion == null ? "" : data.direccion;
              me.SnackbarShow(
                "info",
                "Contribuyente con estado " +
                data.estado +
                " y condición " +
                data.condicion
              );
            } else {
              me.nombre = "";
              me.direccion = "";
              me.SnackbarShow("info", data.message); // No se encontraron resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.SnackbarShow("error", "Error en el Nº RUC.");
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;
      axios
        .post("api/Personas/Crear", {
          tipo_persona: "Cliente",
          nombre: me.nombre,
          idtipo_documento: me.idtipo_documento_pers,
          num_documento: me.num_documento,
          idubigeo: me.idubigeo,
          direccion: me.direccion,
          telefono: me.telefono,
          email: me.email,
        })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectCliente(me.idpersona);
          me.desabilitarPer = false;
          me.SnackbarShow("success", response.data.mensaje);
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.SnackbarShow("error", error.response.data);
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    /* FIN PERSONA */

    // Limpiar venta item modal impresion
    limpiar() {
      this.igv = parseFloat(this.datos.igv); // requerido
      this.idmovimiento = "";

      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.importe_letras = "";
      this.placa = "";
      this.hash_cpe = "";

      this.idtipo_nota_cred = "";
      this.doc_ref = "";
      this.cod_doc_ref = "";
      this.clientes = [];
      this.importe_total = 0;
      this.igv_gravado = 0;
      this.subtotal_gravado = 0;

      this.detalles = []; // Array detalle pedidos
      this.detalle_pedidos = []; // Array Convertido de Array detalles
      this.idpedido = 0;
      // Cliente
      this.cliente = "";
      this.num_documento = "";
      this.direccion = "";
      this.nom_ubigeo = "";
      this.telefono = "";
      this.email = "";
      this.fecha = "";

      this.moneda = "";
      this.ref_comprobante = "";
      this.motivo = "";

      this.desabilitar = false;

      // Venta
      this.codigo_barra = "";
      this.idtipo_comprobante = "";
      this.iddetalle_comprobante_sucursal = "";
      this.idpersona = "";
      this.idtipo_documento = "";
      this.tipo_pago = "";
      this.total_pago = 0;
      this.num_operacion = "";
      this.fecha_vencimiento = moment().format("YYYY-MM-DD"); // Cronograma de pagos
      this.fechaseleccionada(this.fecha_vencimiento); // Cargamos fecha actual a fecha_vencimiento y fecha_vencimiento_formateada

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";
    },
    // Fin Limpiar venta item modal impresion

    crearPDF() {
      var doc = new jsPDF("p", "mm", [78, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;
      var subir = "";

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
        subir = false;
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text:
            this.datos.ruc +
            "|" +
            this.idtipo_comprobante +
            "|" +
            this.serie_comprobante +
            "|" +
            this.num_comprobante +
            "|" +
            this.igv_gravado +
            "|" +
            this.importe_total +
            "|" +
            moment(this.fecha).format("DD/MM/YYYY") +
            "|" +
            this.idtipo_documento +
            "|" +
            this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        subir = true;
        // Fin QR
      }

      var columns = [
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "CANT.", dataKey: "cantidad" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad.toFixed(2),
          descripcion:
            this.detalles[i].codigo_repuesto +
            " " +
            this.detalles[i].descripcion,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format(
            "###,##0.00"
          ),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "GRAVADA S/ ",
                colSpan: 3,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IGV S/ ",
                colSpan: 3,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],

            [
              {
                content: "TOTAL S/ ",
                colSpan: 3,
                styles: { fontStyle: "bold" },
              },
              {
                content: numeral(this.importe_total).format("###,##0.00"),
                styles: { fontStyle: "bold" },
              },
            ]
          );
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", centro / 2, 4, 40, 20);
      // Datos empresa
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.text(this.datos.empresa.substring(0, 22), centro, 27, "center");
      doc.text(this.datos.empresa.substring(22, 80), centro, 31, "center");

      doc.text(this.datos.ruc, centro, 35, "center");
      doc.setFont("helvetica", "normal");
      doc.text(this.datos.dirsucursal, centro, 39, "center");
      doc.text(this.datos.telsucursal, centro, 43, "center");
      doc.text(
        this.datos.dptosucursal +
        " - " +
        this.datos.provsucursal +
        " - " +
        this.datos.dttosucursal,
        centro,
        47,
        "center"
      );
      doc.setFont("helvetica", "bold");
      doc.text(nomcomprob, centro, 51, "center");
      doc.text(
        this.serie_comprobante + "-" + this.num_comprobante,
        centro,
        55,
        "center"
      );
      doc.setFont("helvetica", "normal");
      doc.text("CAJERO: " + this.usuario.idusuario, 5, 59);
      doc.text(
        "FECHA: " + moment(this.fecha).format("DD/MM/YYYY HH:mm:ss"),
        5,
        63
      );
      // Datos cliente
      doc.text("RUC/DNI: " + this.num_documento, 5, 67);
      doc.text("CLIENTE: " + this.cliente.substring(0, 35), 5, 71);
      doc.text("DIRECION: " + this.direccion.substring(0, 35), 5, 75);
      doc.text("PLACA: " + this.placa, 5, 79);

      doc.autoTable({
        theme: "plain",
        columns,
        body,
        foot,
        margin: { left: 5, right: 5, top: 82 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.3,
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { cellPadding: 0.5, textColor: 0, fontSize: 7 },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          descripcion: { cellWidth: "auto" },
          cantidad: { cellWidth: 10, halign: "center" },
          precio_unit_venta: { cellWidth: 12, halign: "right" },
          subtotal: { cellWidth: 14, halign: "right" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 5, finalY + 3);
      // Factura y/o Boleta
      if (this.idtipo_comprobante == "01" || this.idtipo_comprobante == "03") {
        doc.text("FORMA DE PAGO: CONTADO", 5, finalY + 7);
      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 5, finalY + 7);
        doc.text(
          "DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16),
          5,
          finalY + 11
        );
      }
      if (imageQR) {
        doc.text(
          "Representacion Impresa de la " + this.comprobante + " Electronica",
          centro,
          finalY + 15,
          "center"
        );
        doc.text(
          "consulte su comprobante en SUNAT",
          centro,
          finalY + 19,
          "center"
        );
        doc.addImage(imageQR, "JPEG", 26, finalY + 22, 25, 25);
        doc.text("GRACIAS POR SU COMPRA", centro, finalY + 50, "center");
        //doc.text("RESUMEN", centro, finalY + 42, "center");
        //doc.text(this.hash_cpe, centro, finalY + 45, "center");
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      subir ? this.subirPdf() : null; // Subimos pdf blob a nuetra api
      /* Crear PDF venta */
    },

    crearPDFA4() {
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;
      var subir = "";

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
        subir = false;
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text:
            this.datos.ruc +
            "|" +
            this.idtipo_comprobante +
            "|" +
            this.serie_comprobante +
            "|" +
            this.num_comprobante +
            "|" +
            this.igv_gravado +
            "|" +
            this.importe_total +
            "|" +
            moment(this.fecha).format("DD/MM/YYYY") +
            "|" +
            this.idtipo_documento +
            "|" +
            this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        subir = true;
        // Fin QR
      }

      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U.M.", dataKey: "unidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad.toFixed(2),
          unidad_medida: this.detalles[i].unidad_medida,
          descripcion: this.detalles[i].descripcion,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format(
            "###,##0.00"
          ),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "GRAVADA S/ ",
                colSpan: 4,
              },
              numeral(this.subtotal_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IGV S/ ",
                colSpan: 4,
              },
              numeral(this.igv_gravado).format("###,##0.00"),
            ],
            [
              {
                content: "IMPORTE TOTAL S/ ",
                colSpan: 4,
                styles: { fontStyle: "bold" },
              },
              {
                content: numeral(this.importe_total).format("###,##0.00"),
                styles: { fontStyle: "bold" },
              },
            ]
          );
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 36, 18);

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 50, right: 72, top: 17 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 9,
          textColor: 0,
          halign: "center",
        },
        body: [
          {
            columna0:
              this.datos.dirsucursal +
              " " +
              this.datos.dptosucursal +
              " - " +
              this.datos.provsucursal +
              " - " +
              this.datos.dttosucursal,
          },
          { columna0: "Email: " + this.datos.mailsucursal },
          { columna0: "Atención Cliente: " + this.datos.telsucursal },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
      });

      // COMPROBANTE DE PAGO
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 18, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.text("RUC " + this.datos.ruc, 168, 22, "center");
      doc.text(nomcomprob, 168, 27, "center");
      doc.text(
        this.serie_comprobante + "-" + this.num_comprobante,
        168,
        32,
        "center"
      );

      // Mostramos QR para el CPE
      if (imageQR) {
        doc.addImage(
          imageQR,
          "JPEG",
          170,
          doc.lastAutoTable.finalY + 2,
          25,
          25
        );
      }

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 5,
        theme: "plain",
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        margin: { left: 15, right: 45 },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        body: [
          {
            columna0: "CAJERO: " + this.usuario.idusuario,
          },
          {
            columna0:
              "FECHA DE EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"),
          },
          { columna0: "CLIENTE: " + this.cliente },
          { columna0: "RUC/DNI: " + this.num_documento },
          {
            columna0: "DIRECCIÓN: " + this.direccion + " - " + this.nom_ubigeo,
          },
          { columna0: "PLACA: " + this.placa },
        ],
      });

      // DETALLE DE VENTA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 4,
        theme: "grid",
        columns,
        body,
        foot,
        margin: { left: 15, right: 15 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          halign: "center",
          fillColor: [227, 228, 229],
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: 255,
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 15, halign: "center" },
          unidad_medida: { cellWidth: 15, halign: "center" },
          descripcion: { cellWidth: "auto" },
          precio_unit_venta: { cellWidth: 20, halign: "right" },
          subtotal: { cellWidth: 20, halign: "right" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 15, finalY + 4);
      // Factura y/o Boleta
      if (
        this.idtipo_comprobante == "00" ||
        this.idtipo_comprobante == "01" ||
        this.idtipo_comprobante == "03"
      ) {
        doc.text("FORMA DE PAGO: CONTADO", 15, finalY + 8);
      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 15, finalY + 8);
        doc.text(
          "DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16),
          15,
          finalY + 12
        );
      }

      // Referencia de comprobante convertido
      if (this.idtipo_comprobante == "00" && this.ref_comprobante != "") {
        doc.text(
          "REFERENCIA: " + this.ref_comprobante.substring(3, 16),
          15,
          finalY + 16
        );
      }

      // Si existe QR mostramos mensaje de CPE
      if (imageQR) {
        doc.setFont("helvetica", "normal");
        doc.text(
          "Representacion Impresa de la " +
          this.comprobante +
          " Electronica. " +
          "Resumen " +
          this.hash_cpe,
          centro,
          finalY + 18,
          "center"
        );

        doc.text(
          "consulte su comprobante en SUNAT",
          centro,
          finalY + 22,
          "center"
        );
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      subir ? this.subirPdf() : null; // Subimos pdf blob a nuetra api
      /* Crear PDF venta */
    },

    subirPdf() {
      let me = this;

      // Código para editar usuario
      let formData = new FormData();
      formData.append("file", me.blobPdf);
      formData.append(
        "nomcomprobante",
        this.datos.ruc +
        "-" +
        this.idtipo_comprobante +
        "-" +
        this.serie_comprobante +
        "-" +
        this.num_comprobante
      );
      axios
        .post("api/Movimientos/SubirFile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    SnackbarShow(type, msj) {
      if (!type) return;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "error",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "info",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "check_circle",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "warning",
            timeout: 8000,
            text: msj,
            visible: true,
          };
          break;
      }
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>