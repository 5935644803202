<template>
  <HolaHome />
</template>

<script>
import HolaHome from "../components/HolaHome";

export default {
  components: {
    HolaHome,
  },
};
</script>
