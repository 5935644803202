<template>
  <v-layout alig-start>
    <v-flex>
      <!-- Inicio de barra de alerta -->
      <v-snackbar v-model="alerta.snackbar" :color="alerta.color" @click="alerta.snackbar = false"
        transition="slide-y-reverse-transition" right>{{ alerta.text }}</v-snackbar>
      <!-- Fin inicio de barra de alerta -->

      <v-data-table :loading="loading" :headers="tipo_cuenta == 'Pagar' ? cabeceraCuentaPagar : cabeceraCuentaCobrar"
        :items="cuentasPC" :items-per-page="15" dense class="elevation-3">
        <template v-slot:top>
          <v-form v-model="isValid">
            <v-toolbar flat color="white">
              <v-toolbar-title class="hidden-sm-and-down">DETALLE DE CUENTAS</v-toolbar-title>
              <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-spacer />
              <v-autocomplete outlined dense v-model="idsucursal" :items="selectSucursales"
                :search-input.sync="buscar_sucursal" label="Sucursales" :rules="[v => !!v || 'Requerido']"
                background-color="blue lighten-5" @change="cuentasPC = []" class="mr-1" hide-details></v-autocomplete>
              <v-spacer />
              <v-select outlined dense v-model="tipo_cuenta" :items="tipo_cuentas" label="Cuentas x"
                :rules="[v => !!v || 'Requerido']" background-color="blue lighten-5" @change="cuentasPC = []" class="mr-1"
                hide-details></v-select>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field outlined dense v-model="dateRangeText" readonly v-on="on"
                    background-color="blue lighten-5" hide-details class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true" range>
                </v-date-picker>
              </v-menu>
              <v-spacer />
              <v-btn x-small fab color="blue darken-3" @click="listar" :loading="desabilitar"
                :disabled="desabilitar || !isValid" class="white--text mr-1">
                <v-icon>search</v-icon>
              </v-btn>
              <v-btn fab x-small color="info" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn fab x-small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid">
                <v-icon>description</v-icon>
              </v-btn>

              <!-- Fin Formulario Busqueda -->
            </v-toolbar>
          </v-form>
        </template>
        <!-- Opciones formatear fecha, moneda cuentas x cobrar y pagar -->
        <template v-slot:item.fecha_vencimiento="{ item }">
          <template>
            <v-chip
              :color="(item.fecha_vencimiento.substr(0, 10) < fecha_actual ? 'error' : item.fecha_vencimiento.substr(0, 10) == fecha_actual ? 'warning' : 'success')">
              {{ item.fecha_vencimiento | formatearFecha }}</v-chip>
          </template>
        </template>
        <template v-slot:item.moneda="{ item }">
          <template v-if="item.moneda == 'S'">
            <span>Soles</span>
          </template>
          <template v-else>
            <span>Dolares</span>
          </template>
        </template>
        <template v-slot:item.importe="{ item }">
          <div class="text-right">
            <strong>
              <span>{{ item.moneda == "S" ? "S/" : "US$" }} {{ item.importe | formatearNumero }}</span>
            </strong>
          </div>
        </template>
        <!-- Fin Opciones formatear fecha, moneda cuentas x pagar -->
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState } from "vuex";
import moment from "moment";
import numeral from "numeral";
import XLSX from "xlsx";

export default {
  data() {
    return {
      cuentasPC: [],
      dialog: false,
      cabeceraCuentaPagar: [
        { text: "PROVEEDOR", value: "persona", sortable: false },
        { text: "COMPROB.REF.", align: "center", value: "numero_comp_ref", sortable: false },
        { text: "CdPd", align: "center", value: "idpedido", sortable: false },
        { text: "CxP", align: "center", value: "numero", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
      ],
      cabeceraCuentaCobrar: [
        { text: "CLIENTE", value: "persona", sortable: false },
        { text: "COMPROB.REF.", align: "center", value: "numero_comp_ref", sortable: false },
        { text: "CdPd", align: "center", value: "idpedido", sortable: false },
        { text: "CxC", align: "center", value: "numero", sortable: false },
        { text: "FECHA VCTO", align: "center", value: "fecha_vencimiento", sortable: false },
        { text: "IMPORTE", align: "center", value: "importe", sortable: false },
        { text: "ESTADO", align: "center", value: "estado", sortable: false },
      ],
      tipo_cuenta: "",
      tipo_cuentas: ["Pagar", "Cobrar"],
      idsucursal: "",
      selectSucursales: [],

      buscar_sucursal: "",
      buscar_movimiento: "",

      fecha_actual: moment().format("YYYY-MM-DD"),

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      alerta: { snackbar: false, color: "", text: "" },
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.formatFecha(this.dates[0]);
        fechaFin = this.formatFecha(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    formatFecha(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios
        .get("api/Detalle_Cuentas/ReporteListarDetalleCxCP", {
          params: {
            idsucursal: me.idsucursal,
            idtipo_movimiento: me.tipo_cuenta == "Pagar" ? 1 : 2,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
        .then(function (response) {
          me.cuentasPC = response.data;
          me.desabilitar = false;
          me.loading = false;
          //console.log(me.cuentasPC);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.alerta.snackbar = true;
          me.alerta.color = "error";
          me.alerta.text = "Ocurrio un error";
          //console.log(err.response);
        });
    },

    select() {
      let su = this;
      var sucursalesArray = [];
      axios
        .get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [];
      if (this.tipo_cuenta == "Pagar") {
        var columns = [
          { header: "Proveedor", dataKey: "persona" },
          { header: "Comprob. Ref.", dataKey: "numero_comp_ref" },
          { header: "CdPd", dataKey: "idpedido" },
          { header: "CxP", dataKey: "numero" },
          { header: "Fecha Vcto", dataKey: "fecha_vencimiento" },
          { header: "Moneda", dataKey: "moneda" },
          { header: "Importe", dataKey: "importe" },
          { header: "Estado", dataKey: "estado" },
        ];
      } else {
        var columns = [
          { header: "Cliente", dataKey: "persona" },
          { header: "Comprob. Ref.", dataKey: "numero_comp_ref" },
          { header: "CdPd", dataKey: "idpedido" },
          { header: "CxC", dataKey: "numero" },
          { header: "Fecha Vcto", dataKey: "fecha_vencimiento" },
          { header: "Moneda", dataKey: "moneda" },
          { header: "Importe", dataKey: "importe" },
          { header: "Estado", dataKey: "estado" },
        ];
      }

      var body = [];
      var foot = [];

      var totalimporte = 0.0;

      for (var i = 0; i < this.cuentasPC.length; i++) {
        totalimporte = totalimporte + parseFloat(this.cuentasPC[i].importe);
        body.push({
          persona: this.cuentasPC[i].persona,
          numero_comp_ref: this.cuentasPC[i].numero_comp_ref,
          idpedido: this.cuentasPC[i].idpedido,
          numero: this.cuentasPC[i].numero,
          fecha_vencimiento: moment(this.cuentasPC[i].fecha_vencimiento).format(
            "DD/MM/YYYY"
          ),
          moneda: this.cuentasPC[i].moneda == "S" ? "Soles" : "Dolares",
          importe: numeral(this.cuentasPC[i].importe).format("###,##0.00"),
          estado: this.cuentasPC[i].estado,
        });

        if (i === this.cuentasPC.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 6,
            },
            numeral(totalimporte).format("###,##0.00"),
          ]);
        }
      }
      //console.log(body);

      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(
            "CUENTAS X " + (this.tipo_cuenta == "Pagar" ? "PAGAR" : "COBRAR"),
            doc.internal.pageSize.width / 2,
            45,
            {
              align: "center",
            }
          );
          doc.setFontSize(8);
          doc.text("SUCURSAL: " + this.buscar_sucursal.substr(0, 69), 40, 72);
          doc.text(
            "DESDE: " + moment(this.dates[0]).format("DD/MM/YYYY"),
            380,
            72
          );
          doc.text(
            "HASTA: " + moment(this.dates[1]).format("DD/MM/YYYY"),
            480,
            72
          );
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 80 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          persona: { cellWidth: 180 },
          numero_comp_ref: { cellWidth: 70 },
          idpedido: { cellWidth: 35.28, halign: "right" },
          numero: { cellWidth: 30, halign: "center" },
          fecha_vencimiento: { cellWidth: 50, halign: "center" },
          moneda: { cellWidth: 40 },
          importe: { cellWidth: 60, halign: "right" },
          estado: { cellWidth: 50, halign: "center" },
        },

        didParseCell: function (cell, data) {
          //console.log("Data = ", data);
          //console.log("cell = ", cell);
          var fVencimiento;
          var fActual;

          fVencimiento = cell.row.raw.fecha_vencimiento;
          var aFecha = moment().format("YYYY-MM-DD");
          let arrayFecha = aFecha.split(["-"]);
          let ddmmyy =
            arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
          fActual = ddmmyy;

          if (
            fVencimiento == fActual &&
            cell.column.raw.dataKey == "fecha_vencimiento"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 192, 67]; //amarillo
          } else if (
            fVencimiento > fActual &&
            fVencimiento < "31/12/2099" &&
            cell.column.raw.dataKey == "fecha_vencimiento"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [66, 175, 93]; //verde
          } else if (
            "31/12/2000" > fActual &&
            fVencimiento < fActual &&
            cell.column.raw.dataKey == "fecha_vencimiento"
          ) {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 80, 86]; //rojo
          }
        },
      });
      // 515.28 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Cuentas x " + this.tipo_cuenta + ".pdf");
    },

    crearXLSX() {
      var rows = [];

      if (this.tipo_cuenta == "Pagar") {
        this.cuentasPC.map(function (x) {
          rows.push({
            Proveedor: x.persona,
            "Comprob. Ref.": x.numero_comp_ref,
            CdPd: x.idpedido,
            CxP: x.numero,
            "Fecha Vcto": moment(x.fecha_vencimiento).format("DD/MM/YYYY"),
            Moneda: x.moneda == "S" ? "Soles" : "Dolares",
            Importe: x.importe,
            Estado: x.estado,
          });
        });
      } else {
        this.cuentasPC.map(function (x) {
          rows.push({
            Cliente: x.persona,
            "Comprob. Ref.": x.numero_comp_ref,
            CdPd: x.idpedido,
            CxC: x.numero,
            "Fecha Vcto": moment(x.fecha_vencimiento).format("DD/MM/YYYY"),
            Moneda: x.moneda == "S" ? "Soles" : "Dolares",
            Importe: x.importe,
            Estado: x.estado,
          });
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);

      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, Detalle, "Cuenta x " + this.tipo_cuenta);

      XLSX.writeFile(
        wb,
        "Cuentasx" +
        this.tipo_cuenta +
        " " +
        moment(this.dates[0]).format("DD-MM-YYYY") +
        " al " +
        moment(this.dates[1]).format("DD-MM-YYYY") +
        ".xlsx"
      );
    },
  },
};
</script>

